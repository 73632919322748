/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.alerts', [])
	.service('AlertService', function (AccountService, LoadingOverlayService, SbUser, $filter, $rootScope, $sce, $sessionStorage, $state, $translate, $timeout, socket) {

		const self = this;

		const ALERT_DURATION = 3000;              // How long the alerts should be displayed
		const ERROR   = 'danger';
		const SUCCESS = 'success';
		const WARNING = 'warning';
		const SAVED   = 'saved';

		let basicError  = 'There was an error.';
		const alerts = {
			loading: 'Loading'
		};

		$rootScope.processing = $sessionStorage.processing;

		const successMessages = {
			activate: 'ALERT_MESSAGES.SUCCESS.ACTIVATED',
			addListing: 'ALERT_MESSAGES.SUCCESS.ADDED_LISTING',
			approvedShared: 'ALERT_MESSAGES.SUCCESS.APPROVED_SHARED',
			cancelShared: 'ALERT_MESSAGES.SUCCESS.CANCELLED_SHARED',
			cancel: 'ALERT_MESSAGES.SUCCESS.CANCELLED',
			changedStatus: 'ALERT_MESSAGES.SUCCESS.CHANGED_STATUS',
			connect: 'ALERT_MESSAGES.SUCCESS.DOCUSIGN.CONNECTED',
			convert: 'ALERT_MESSAGES.SUCCESS.CONVERTED_LISTING',
			copyLink: 'ALERT_MESSAGES.SUCCESS.COPIED_LINK',
			create: 'ALERT_MESSAGES.SUCCESS.CREATED',
			deactivate: 'ALERT_MESSAGES.SUCCESS.DEACTIVATED',
			delete: 'ALERT_MESSAGES.SUCCESS.DELETED',
			download: 'ALERT_MESSAGES.SUCCESS.DOWNLOADED',
			disconnect: 'ALERT_MESSAGES.SUCCESS.DOCUSIGN.DISCONNECTED',
			disconnectGoogle: 'ALERT_MESSAGES.SUCCESS.GOOGLE.DISCONNECTED',
			docusign: 'ALERT_MESSAGES.SUCCESS.DOCUSIGN.CONNECTED',
			envelope: 'ALERT_MESSAGES.SUCCESS.DOCUSIGN.ENVELOPE',
			generate: 'ALERT_MESSAGES.SUCCESS.GENERATED',
			rejectShared: 'ALERT_MESSAGES.SUCCESS.REJECTED_SHARED',
			save: 'ALERT_MESSAGES.SUCCESS.WAS_SAVED',
			send: 'ALERT_MESSAGES.SUCCESS.SENT',
			shared: 'ALERT_MESSAGES.SUCCESS.SHARED',
			sharedListing: 'ALERT_MESSAGES.SUCCESS.SHARED_LISTING',
			sharedListings: 'ALERT_MESSAGES.SUCCESS.SHARED_LISTINGS',
			sell: 'ALERT_MESSAGES.SUCCESS.SOLD_LISTING',
			setPassword: 'ALERT_MESSAGES.SUCCESS.SET_PASSWORD',
			transfer: 'ALERT_MESSAGES.SUCCESS.TRANSFERRED',
			upload: 'ALERT_MESSAGES.SUCCESS.UPLOADED',
		};

		const errorMessages = {
			accountDetails: 'ALERT_MESSAGES.ERROR.CLIENT.ACCOUNT_DETAILS',
			basic: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_BASIC',
			cannotAddPhoto: 'ALERT_MESSAGES.ERROR.CLIENT.CANT_ADD_PHOTO',
			cannotAddMorePhotos: 'ALERT_MESSAGES.ERROR.CLIENT.CANT_ADD_MORE',
			changedStatus: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_CHANGED_STATUS',
			connect: 'ALERT_MESSAGES.ERROR.SERVER.DOCUSIGN.CONNECTED',
			disconnect: 'ALERT_MESSAGES.ERROR.SERVER.DOCUSIGN.DISCONNECTED',
			docusign: 'ALERT_MESSAGES.ERROR.SERVER.DOCUSIGN.ENVELOPE',
			docusignDisconnected: 'ALERT_MESSAGES.ERROR.SERVER.DOCUSIGN.NOT_CONNECTED',
			envelopeCreate: 'ALERT_MESSAGES.ERROR.SERVER.DOCUSIGN.ENVELOPE_CREATE',
			envelopeExists: 'ALERT_MESSAGES.ERROR.SERVER.DOCUSIGN.ENVELOPE_EXISTS',
			login: 'ALERT_MESSAGES.ERROR.CLIENT.LOGIN_FAIL',
			noRoles: 'ALERT_MESSAGES.ERROR.CLIENT.NO_ROLES',
			requiredFields: 'ALERT_MESSAGES.ERROR.CLIENT.REQUIRED_FIELDS',
			setPassword: 'ALERT_MESSAGES.ERROR.CLIENT.SET_PASSWORD',
			signup: 'ALERT_MESSAGES.ERROR.CLIENT.SIGNUP',
			wrongUrl: 'ALERT_MESSAGES.ERROR.CLIENT.BAD_URL',
		};

		const errorVerbs = {
			activate: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_ACTIVATING',
			add: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_ADDING',
			convert: 'ALERT_MESSAGES.ERROR.CLIENT.CONVERT_LISTING',
			create: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_CREATING',
			deactivate: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_DEACTIVATING',
			delete: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_DELETING',
			generate: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_CREATING',
			load: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_LOADING',
			remove: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_DELETING',
			save: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_SAVING',
			send: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_SENDING',
			switch: 'ALERT_MESSAGES.ERROR.CLIENT.ROLE_SWITCH',
			transfer: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_TRANSFERRING',
			upload: 'ALERT_MESSAGES.ERROR.CLIENT.ERROR_UPLOADING',
		};

		translate(errorMessages.basic, {}, 'ERROR').then((res) => {
			basicError = res;
		});

		if (AccountService.loggedIn()) {
			_checkProcessing();
		}

		socket.on('notification-new', _checkProcessing);

		// FUNCTIONS ------------------------------------------------------------------------------------------------

		self.cancelLoop = _cancelLoop;

		self.changedRoles = function(role) {
			const filtered = $filter('role')(role);

			translate('ALERT_MESSAGES.SUCCESS.CHANGED_ROLE', { what: filtered }).then((res) => {
				_enableAlert('success', { message: res });
			})
		}

		self.doneLoading = function() {
			end();
		}

		self.error = function (item, verb, step, emitLoadEnd, messageEnd) {
			translate(item).then((translatedItem) => {
				return translate(errorVerbs[verb] || errorMessages['basic'], { what: translatedItem });
			})
			.then((res) => {
				_enableAlert(ERROR, { message: res + (!!messageEnd ? ': ' + messageEnd : '') });
			})

			if (emitLoadEnd) {
				self.doneLoading();
			}
		}

		self.errorCreate = function (item, step, emitLoadEnd) {
			self.error(item, 'create', step, emitLoadEnd);
		}

		self.errorLoad = function (item, step, emitLoadEnd) {
			self.error(item, 'load', step, emitLoadEnd);
		}

		self.errorMessage = function (msg, data, isSticky) {
			const message = !!errorMessages[msg] ? errorMessages[msg] : msg;
			const sticky = isSticky || ($state.current.name === 'main.login' ? true : false);
			const isPublic = $state.current.name.includes('public');

			const alert = {
				message: $sce.trustAsHtml('' + basicError),
				sticky: sticky,
				isPublic: isPublic
			}

			$translate(message, data).then((res) => {
				alert.message = $sce.trustAsHtml('' + res);
				_enableAlert(ERROR, alert);
			}, (translationId) => {
				_enableAlert(ERROR, alert);
			})
		}

		self.errorPopup = function(msg, data) {
			const message = !!errorMessages[msg] ? errorMessages[msg] : msg;
			let alert   = $sce.trustAsHtml('' + basicError);

			$translate(message, data).then((res) => {
				alert = $sce.trustAsHtml('' + res);
			})
			.finally(() => {
				$rootScope.prompt(alert, null, 'Ok');
			})
		}

		self.getLoadingText = function() {
			return alerts.loading;
		}

		self.loading = function(message, translate = false) {
			if(message && translate) {
				const overlayMessage = '' + $filter('translate')(message);
				LoadingOverlayService.enable(overlayMessage);
			} else if(message) {
				LoadingOverlayService.enable(message);
			} else {
				LoadingOverlayService.enable(alerts.loading);
			}

		}

		self.loadingMessage = function(message, translate) {
			const overlay = angular.element(document.getElementById('loading-overlay'));

			const old = overlay.find('.sub-message');

			if (old.length > 0) {
				old.remove();
			}

			if (translate) {
				message = '' + $filter('translate')(message);
			}

			const subMessage = angular.element('<div class="sub-message adding">' + message + '</div>');
			overlay.append(subMessage);
		}

		self.reload = function () {
			$translate('ALERT_MESSAGES.ALERT.LOADING_OVERLAY').then((res) => {
				alerts.loading = '' + res;
			}, (translationId) => {})
		}

		self.resetPassword = function () {
			$translate('ALERT_MESSAGES.SUCCESS.RESET_PASSWORD').then((res) => {
				_enableAlert(SUCCESS, { message: res, sticky: true });
			}, (translationId) => {
				_enableAlert(SUCCESS, { message: translationId, sticky: true });
			})
		}

		self.saved = function () {
			$translate('ALERT_MESSAGES.SUCCESS.SAVED').then((res) => {
				_enableAlert(SAVED, { message: res });
			}, () => {
				_enableAlert(SAVED, { message: 'Saved' });
			})
		}

		self.startVideo = function(fileName, listingId) {
			self.successMessage('ALERT_MESSAGES.SUCCESS.VIDEO_START');

			$sessionStorage.processing = $sessionStorage.processing || [];
			$sessionStorage.processing.push({
				what: 'video',
				file: fileName,
				listing: listingId || false
			})
			$rootScope.processing = $sessionStorage.processing;
		}

		self.success = function (item, what, plural) {
			translate(item)
			.then((translatedItem) => {
				return translate(successMessages[what], { what: translatedItem, plural: plural || false });
			})
			.then((res) => {
				_enableAlert(SUCCESS, { message: res });
			})
		}

		self.successMessage = function (msg, data, link) {
			$translate(successMessages[msg] || msg, data).then((res) => {
				_enableAlert(SUCCESS, { message: res, link: link });
			}, (translationId) => {
				_enableAlert(SUCCESS, { message: translationId, link: link });
			})
		}

		self.warning = function(message) {
			$translate(message).then((res) => {
				_enableAlert(WARNING, { message: res });
			}, (translationId) => {
				_enableAlert(WARNING, { message: translationId });
			})
		}

		function _cancelLoop() {
			$rootScope.processing = null;
			$sessionStorage.processing = null;
		}

		function _checkProcessing() {

			const processing = $rootScope.processing || [];

			const role = AccountService.getRole();

			if (!AccountService.loggedIn() || processing.length == 0 || !Array.isArray(processing)) {
				_cancelLoop();
			}

			const names = processing.reduce((arr, proc) => {
				if (proc.file) {
					arr.push(proc.file);
				}
				return arr;
			}, []);

			const filter = {
				where: {
					fileName: {
						inq: names
					},
					type: 'video_done'
				},
				include: 'related'
			}

			if(role !== 'sellerProfile') {

				SbUser.notifications({ id: AccountService.getUserId(), filter: filter }, (res) => {
					res = res || [];

					if (res.length == 0) return;

					let control = 100;

					while (res.length > 0 && control > 0) {
						const finished = res.pop();
						_finished(finished);
						control -= 1;
					}
				})

			} else {
				_cancelLoop();
			}

			function _finished(finished) {
				finished      = finished || {};
				const related   = finished.related || {};

				const translate = {
					label: 'ALERT_MESSAGES.SUCCESS.VIDEO_DONE',
					type: 'success',
					data: {
						listingId: related.listingId
					}
				}

				if (!!finished.hasError) {
					translate.label = 'ALERT_MESSAGES.ERROR.SERVER.Listing.generateVideo.400';
					translate.type = 'error';
				}

				const functionName  = translate.type + 'Message';

				self[functionName](translate.label, translate.data, related.url);

				const i = processing.findIndex(x => x.file == finished.fileName);

				if (i >= 0 && !!($sessionStorage.processing || [])[i]) {
					$sessionStorage.processing.splice(i, 1);
				}

				$rootScope.processing = $sessionStorage.processing;

				if ($rootScope.processing.length == 0) {
					_cancelLoop();
				}
			}
		}

		function end() {
			const overlay = angular.element(document.getElementById('loading-overlay'));
			const old = overlay.find('.sub-message');
			if (old.length > 0) {
				old.remove();
			}
			LoadingOverlayService.disable();
		}

		//var defaultKey = "ERROR"
		function handle(rejection) {
			rejection = rejection || {};

			if (!rejection.data) return;

			const info = ((rejection.data || {}).error || {}).details || null;
			const code = ((rejection.data || {}).error || {}).statusCode;
			const stripeError = ((rejection.data || {}).error || {}).raw;
			let extra = ((rejection.data || {}).error || {}).problem || null;

			let model,
				method,
				specificKey = null,
				languageKey = null,
				statusKey = null,
				defaultKey = null;

			if (stripeError) {
				if (stripeError.code) {
					specificKey = 'ALERT_MESSAGES.ERROR.SERVER.Stripe.codes.' + stripeError.code;
				} else if (stripeError.type) {
					specificKey = 'ALERT_MESSAGES.ERROR.SERVER.Stripe.types.' + stripeError.type;
				}
			}
			else if (info) {
				model = info.model.name;
				method = info.model.method;
				languageKey = 'ALERT_MESSAGES.ERROR.SERVER.' + model + '.' + method + '.' + code;

				if (info.context) {
					if (!extra) {
						extra = {};
					}
					extra.context = info.context;
				}
			}
			else if (code) {
				statusKey = 'ALERT_MESSAGES.ERROR.SERVER.General.' + code;
			}
			else {
				defaultKey = 'ALERT_MESSAGES.ERROR.SERVER.General.UNRECOGNIZED';
			}

			self.errorMessage(specificKey || languageKey || statusKey || defaultKey, extra);
		}

		function translate(key, args, _default) {
			args = args || {};
			return $translate(key, args).then((translatedItem) => {
				return '' + translatedItem;
			})
			.catch((err) => {
				return _default || key;
			})
		}

		// WATCHERS ------------------------------------------------------------------------------------------------

		$rootScope.$on('rejection', (event, data) => {
			handle(data);
		});

		$rootScope.$on('success', (event, data) => {
			_enableAlert(SUCCESS, data);
		});

		$rootScope.$on('error', (event, data) => {
			_enableAlert(ERROR, data);
		});

		$rootScope.$on('saved', (event, data) => {
			_enableAlert(SAVED, data);
		});

		$rootScope.$on('warning', (event, data) => {
			_enableAlert(WARNING, data);
		});

		function _enableAlert(type, data) {
			data = data || {};
			type = type || ERROR;

			if (type == ERROR && !data.isPublic) {
				$rootScope.publicErr = data;
			}

			$rootScope[type] = data;

			if (!data.sticky) {
				$timeout(() => {
					$rootScope[type] = {};
				}, ALERT_DURATION);
			}
		}

		$rootScope.$on('loadingStart', () => {
			LoadingOverlayService.enable(alerts.loading);
		});

		$rootScope.$on('loadingEnd', end);
	})
})(); // End of function()
