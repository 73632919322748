/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('ReportsController', function (AlertService, DocusignService, FileService, GlobalVars, ListingService, ModalService, ReportsService, UploadService, $window, BrokerService, SortingService, $sce, $state, $scope, $rootScope, AccountService, $filter, $timeout) {

		// VARIABLES ============================================================================================================================
		const self = this;

		self.current 			= null;		// Current page
		self.role 				= null;		// Current user's role in the system
		self.folders 			= [];		// Folders to show in My Files page
		self.limit 				= 6;
		self.reportLink			= null;		// Link for generated report file
		self.isClosed 			= true;		// Flag for popover with report link
		self.copyLinkText		= null; 	// Text for 'Copy to Clipboard' link in popover. Changes to 'Copied' briefly after link is clicked.

		self.filteredBrokers	= [];		// Options for broker dropdown
		self.brokerConfig 		= null;		// Selectize config for broker dropdown

		self.folderFileUploader	= null;		// Uploader object for My Files page
		self.uploader 			= null;		// Uploader object

		self.startDate 			= null;		// Start date for report
		self.endDate 			= null; 	// End date for report
		self.startCalendar 		= null;		// Calendar options to select startDate
		self.endCalendar 		= null;		// Calendar options to select endDate

		self.columns 			= null;  	// Columns to display reports in each page
		self.load 				= null;		// Load functions for each page
		self.currSorter 		= null;		// Selected sorter in each page
		self.filters 			= null;		// Filters for each page

		self.rows 				= null; 	// Rows to display a report in the page
		self.listings           = [];
		self.loading            = false;
		self.propertyTypeId     = null;
		self.selectedAddress    = '';

		if($state.current.name === 'main.reports.new-buyers-by-property-type') {
			self.singleConfig = GlobalVars.createMultiConfig(false, 'value', 'name', 'name');
			self.singleConfig.render = {
				option: function(data) {
					if(data.labels && data['labels'][$rootScope.language || 'EN']) {
						return '<div class="option" data-selectable="" data-value="' + data['value'] + '">' + data['labels'][$rootScope.language || 'EN'] + '</div>';
					} else {
						return '<div class="option" data-selectable="" data-value="' + data['value'] + '">' + data['name'] + '</div>';
					}
				},
				item: function(data) {
					if(data.labels && data['labels'][$rootScope.language || 'EN']) {
						return '<div class="item" data-value="' + data['value'] + '">' + data['labels'][$rootScope.language || 'EN'] + '</div>';
					} else {
						return '<div class="item" data-value="' + data['value'] + '">' + data['name'] + '</div>';
					}
				}
			}
		} else {
			self.singleConfig = GlobalVars.createSingleConfig(false, 'id', 'addressString', 'addressString');
			self.singleConfig.render = {
				option: function(data) {
					return '<div class="option" data-selectable="" data-value="' + data['id'] + '">' + data['id']  + ' - ' + data['addressString'] + '</div>';
				},
				item: function(data) {
					return '<div class="item" data-value="' + data['id'] + '">' + data['id'] + ' - ' + data['addressString'] + '</div>';
				}
			}
		}



		const watchers 			= [];		// All watchers created for this scope

		// SETUP VARIABLES ======================================================================================================================

		self.role 				= AccountService.getRole();
		self.copyLinkText 		= 'FILES.COPY_TO_CLIPBOARD';
		self.folderFileUploader = UploadService.initFileFolderUploader();
		self.uploader	 		= UploadService.getUploader();
		self.brokerConfig 		= GlobalVars.createSingleConfig(false, 'id', 'fullName', 'fullName');

		self.startDate 			= moment().subtract(1,'months').startOf('month').toDate();
		self.endDate 			= moment().subtract(1,'months').endOf('month').startOf('day').toDate();

		self.columns 			= getColumns();

		self.currSorter = {
			expiry: {
				category: 'expiry'
			},
			ytd: {
				category: 'fullName'
			},
			'no-seller': {
				category: 'id'
			},
			'missing-documents': {
				category: 'id'
			}
		};

		self.load = {
			expiry: null,
			ytd: ReportsService.getListingsYTD,
			'no-seller': ReportsService.getListingsWithNoSeller,
			'missing-documents': ReportsService.getListingsWithMissingDocuments,
			'visits-by-broker': ListingService.getActive,
			'new-buyers-by-property-type': () => { return new Promise((resolve) => { resolve(GlobalVars.getCodes('propertyTypes', false))}) },
		}

		self.filters = {
			expiry: {
				id: { weight: 0, value: '', desc: 1 },
				addressString: { weight: 0, value: '', desc: 1 },
				expiry: { weight: 1, value: null, desc: -1, date: true },
				askingPrice: { weight: 0, value: '', desc: 1, num: true },
				type: { weight: 0, value: '', desc: 1 },
				postalCode: { weight: 0, value: '', desc: 1 },
				fullName: { weight: 0, value: '', desc: 1 },
				email: { weight: 0, value: '', desc: 1 },
			},
			ytd: {
				fullName: { weight: 0, value: '', desc: 1 },
				jan: { weight: 0, value: '', desc: 1, num: true },
				feb: { weight: 0, value: '', desc: 1, num: true },
				mar: { weight: 0, value: '', desc: 1, num: true },
				apr: { weight: 0, value: '', desc: 1, num: true },
				may: { weight: 0, value: '', desc: 1, num: true },
				jun: { weight: 0, value: '', desc: 1, num: true },
				jul: { weight: 0, value: '', desc: 1, num: true },
				oct: { weight: 0, value: '', desc: 1, num: true },
				aug: { weight: 0, value: '', desc: 1, num: true },
				sep: { weight: 0, value: '', desc: 1, num: true },
				nov: { weight: 0, value: '', desc: 1, num: true },
				dec: { weight: 0, value: '', desc: 1, num: true },
				total: { weight: 0, value: '', desc: 1, num: true }
			},
			'no-seller': {
				id: { weight: 0, value: '', desc: 1 },
				address: { weight: 0, value: '', desc: 1 },
				expiry: { weight: 0, value: '', desc: 1, date: true },
				sold: { weight: 0, value: '', desc: 1, date: true }
			},
			'missing-documents': {
				id: { weight: 0, value: '', desc: 1 },
				address: { weight: 0, value: '', desc: 1 },
				expiry: { weight: 0, value: '', desc: 1, date: true },
				sold: { weight: 0, value: '', desc: 1, date: true }
			}
		};

		self.sortInfo = {
			expiry: {
				page: 'expiry',
				sub: ['property', 'property._address', 'sellers.user']
			}
		}

		_getCurrent($state.current.name);
		setupCalendars();

		// FUNCTIONS ============================================================================================================================

		self.displayExpired = function () {
			self.rows = [];

			for (const i in self.filters.expiry) {			// Reset filters
				self.filters.expiry[i].weight = 0;
				self.filters.expiry[i].value = '';
				if (i !== 'expiry') {
					self.filters.expiry[i].desc = 1;
				}
				else {
					self.filters.expiry[i].desc = -1;
				}
			}

			if (!self.isClosed) {
				self.isClosed = true;
			}
			ReportsService.getExpiredBetweenDates(self.startDate, self.endDate, AccountService.getBrokerId())
			.then((res) => {
				self.rows = res;
			})
			.catch((err) => {})
			.finally(() => {
				AlertService.doneLoading();
			})
		}

		function getColumns() {
			return {
				expiry: [
					{
						name: 'MLS/Smart',
						filter: 'id',
						field: function(listing) {
							return listing.id;
						}
					},
					{
						name: 'ADDRESS.ADDRESS',
						size: 2,
						filter: 'addressString',
						field: function(listing) {
							return ((listing.property || {})._address || {}).addressString;
						}
					},
					{
						name: 'ADDRESS.POSTAL_CODE',
						filter: 'postalCode',
						field: function(listing) {
							return ((listing.property || {})._address || {}).postalCode;
						}
					},
					{
						name: 'COMMON.TYPE',
						filter: 'type',
						field: function(listing) {
							return (((listing.property || {}).type || {}).labels || {})[$rootScope.language] || (((listing.property || {}).type || {}).labels || {})['EN'] || '';
						}
					},
					{
						name: 'LISTING.ASKING_PRICE',
						filter: 'askingPrice',
						field: function(listing) {
							return listing.askingPrice;
						}
					},
					{
						name: 'DATES.EXPIRY_DATE',
						filter: 'expiry',
						field: function(listing) {
							return $filter('dateFormatLong')(listing.expiry);
						}
					},
					{
						name: 'PERSON.SELLER.SELLER_NAME',
						filter: 'fullName',
						isHtml: true,
						field: function(listing) {
						  	let ret = '';

							if ((listing.sellers || []).length > 0) {
								listing.sellers.forEach((seller, i) => {
									ret += '<p>' + ((seller.user || {}).fullName || 'N/A') + '</p>';
								})
							}
							else {
								ret += '<p>-----</p>';
							}

							return ret;
						}
					},
					{
						name: 'PERSON.SELLER.SELLER_EMAIL',
						span: 2,
						isEmail: true,
						filter: 'email',
						field: function(listing) {
						  return listing.sellers;
						}
					}
				],
				ytd:
					[
						{
							name: 'PERSON.NAME',
							filter: 'fullName',
							span: 3
						},
						{
							name: 'DATES.MONTHS.01',
							filter: 'jan'
						},
						{
							name: 'DATES.MONTHS.02',
							filter: 'feb'
						},
						{
							name: 'DATES.MONTHS.03',
							filter: 'mar'
						},
						{
							name: 'DATES.MONTHS.04',
							filter: 'apr'
						},
						{
							name: 'DATES.MONTHS.05',
							filter: 'may'
						},
						{
							name: 'DATES.MONTHS.06',
							filter: 'jun'
						},
						{
							name: 'DATES.MONTHS.07',
							filter: 'jul'
						},
						{
							name: 'DATES.MONTHS.08',
							filter: 'aug'
						},
						{
							name: 'DATES.MONTHS.09',
							filter: 'sep'
						},
						{
							name: 'DATES.MONTHS.10',
							filter: 'oct'
						},
						{
							name: 'DATES.MONTHS.11',
							filter: 'nov'
						},
						{
							name: 'DATES.MONTHS.12',
							filter: 'dec'
						},
						{
							name: 'COMMON.TOTAL',
							filter: 'total'
						}
					],
				'no-seller': [
					{
						name: 'MLS/Smart',
						filter: 'id'
					},
					{
						name: 'ADDRESS.ADDRESS',
						filter: 'address'
					},
					{
						name: 'DATES.EXPIRY_DATE',
						filter: 'expiry'
					},
					{
						name: 'DATES.SOLD_DATE',
						filter: 'sold'
					}
				],
				'missing-documents': [
					{
						name: 'MLS/Smart',
						filter: 'id'
					},
					{
						name: 'ADDRESS.ADDRESS',
						filter: 'address'
					},
					{
						name: 'DATES.EXPIRY_DATE',
						filter: 'expiry'
					},
					{
						name: 'DATES.SOLD_DATE',
						filter: 'sold'
					},
					{ name: 'FILES.DOCUMENTS' },
				],
				'visits-by-broker': [
					{
						name: 'DATES.START_DATE',
						filter: 'start'
					},
					{
						name: 'DATES.END_DATE',
						filter: 'end'
					}
				]
			}
		}

		function setupCalendars() {

			self.startCalendar 	= GlobalVars.createCalendarConfig(null,self.current == 'expiry' ? self.endDate : new Date());
			self.endCalendar 	= GlobalVars.createCalendarConfig(self.startDate,self.current == 'expiry' ? null : new Date());

			const w1 = $scope.$watch(() => { return self.startCalendar.opened }, () => {
				if (self.startCalendar.opened) {
					self.endCalendar.opened = false;
					self.isClosed = true;
				}
			})

			const w2 = $scope.$watch(() => { return self.endCalendar.opened }, () => {
				if (self.endCalendar.opened) {
					self.startCalendar.opened = false;
					self.isClosed = true;
				}
			})

			const w3 = $scope.$watch(() => { return self.endDate; }, (newValue, oldValue, scope) => {
				self.startCalendar.options.maxDate = self.endDate;
			}, true)

			const w4 = $scope.$watch(() => { return self.startDate; }, (newValue, oldValue, scope) => {
				self.endCalendar.options.minDate = self.startDate;
			}, true)

			$scope.$on('$destroy',() => {
				w1();
				w2();
				w3();
				w4();
			})
		}

		if (self.load[self.current]) {
			self.loading = true;
			AlertService.loading();
			self.load[self.current]()
			.then((res) => {
				console.log(res)
				self.rows = [];
				if (res.brokers) {
					for (let i = 0; i < res.brokers.length; i++) {
						const row = {};
						for (const j in res.brokers[i].months) {
							row[j] = res.brokers[i].months[j];
						}
						row.fullName = res.brokers[i].fullName;
						row.total = res.brokers[i].total;
						self.rows.push(row);
					}
					self.total = res.total;
				} else if(self.current === 'new-buyers-by-property-type') {
					self.rows = res;
					self.total = res.length;
				} else {
					for (let i = 0; i < res.length; i++) {
						const row = res[i];

						if (res[i].property) {
							row.type = res[i].property.buildingTypeId;

							if (res[i].property._address) {
								row.addressString = res[i].property._address.addressString;
								row.postalCode = res[i].property._address.postalCode;
							}
						}

						if(self.current === 'visits-by-broker') {
							if(row.addressString && row.addressString != 'No Address') {
								self.rows.push(row);
							}
						} else {
							self.rows.push(row);
						}
					}
				}
			})
			.catch((err) => {})
			.finally(() => {
				self.loading = false;
				AlertService.doneLoading();
			});
		}

		self.sortListings = function (category) {
			if (category) {
				try {
					SortingService.sort(self.rows, self.filters[self.current], category, { 'sellers.user': '' }, self.current);
				}
				catch (err) {
					console.error('Error: ', err);
				}
			}
		};
		function clearPopup() {
			self.isClosed = false;
			self.reportLink = null;
		}

		self.createFolder = function () {
			const modal = ModalService.openModal('createFolder', { folder: function () { return {} }, type: function() { return '' }, options: function() { return { } } }, 'FolderController', 'folderController');
			modal.result
			.then((res) => {
				_getFolders();
			})
			.catch((err) => {
			});
		}

		self.generateVisitsByBroker = function(startDate, endDate, selectedAddress) {
			clearPopup();
			ReportsService.generateVisitsByBroker(startDate, endDate, selectedAddress, AccountService.getBrokerId())
			.then((res) => {
				self.isClosed = false;
				self.reportLink = res.url;
				AlertService.success('PAGES.REPORTS.REPORT', 'generate');
			})
			.catch((err) => {});
		}

		self.generateLastMonthListingsByBroker = function (startDate, endDate) {
			clearPopup();
			ReportsService.generateLastMonthListingsByBroker(startDate, endDate)
			.then((res) => {
				self.isClosed = false;
				self.reportLink = res.url;
				AlertService.success('PAGES.REPORTS.REPORT', 'generate');
			})
			.catch((err) => {})
		}

		self.generateLastMonthSalesByBroker = function (startDate, endDate) {
			clearPopup();
			ReportsService.generateLastMonthSalesByBroker(startDate, endDate)
			.then((res) => {
				self.isClosed = false;
				self.reportLink = res.url;
				AlertService.success('PAGES.REPORTS.REPORT', 'generate');
			})
			.catch((err) => {});
		}


		self.generateSalesByRegion = function (startDate, endDate) {
			clearPopup();
			ReportsService.generateSalesByRegion(startDate, endDate)
			.then((res) => {
				self.isClosed = false;
				self.reportLink = res.url;
				AlertService.success('PAGES.REPORTS.REPORT', 'generate');
			})
			.catch((err) => {});
		}

		self.generateNewBuyersByPropertyType = function(startDate, endDate, propertyTypeId) {
			clearPopup();
			ReportsService.getBuyersByPropertyTypes(startDate, endDate, propertyTypeId)
			.then((res) => {
				self.isClosed = false;
				self.reportLink = res.url;
				AlertService.success('PAGES.REPORTS.REPORT', 'generate');
			})
			.catch((err) => {});
		}

		self.generateListingExpiryReport = function (startDate, endDate) {
			clearPopup();
			ReportsService.generateListingExpiryReport(startDate, endDate)
			.then((res) => {
				self.isClosed = false;
				self.reportLink = res.url;
				AlertService.success('PAGES.REPORTS.REPORT', 'generate');
			})
			.catch((err) => {});
		}

		self.generateYTDListings = function () {
			clearPopup();
			ReportsService.generateYTDListings()
			.then((res) => {
				self.isClosed = false;
				self.reportLink = res.url;
				AlertService.success('PAGES.REPORTS.REPORT', 'generate');
			})
			.catch((err) => {});
		}
		self.deleteDocument = function (id, fk, isGenerated, agencyFolder) {
			if (self.role === 'brokerProfile' && agencyFolder === true) {
				const userId = AccountService.getBrokerId();
				let toUpdate = [];
				let fieldForUpdate = 'brokerIds';
				ReportsService.getDocument(id, fk).then((res) => {
					if (res.brokerBlackList) {
						fieldForUpdate = 'brokerBlackList';
						res.brokerBlackList.push(userId);
						toUpdate = res.brokerBlackList;
					}
					else if (res.allBrokers) {
						fieldForUpdate = 'brokerBlackList';
						toUpdate = [userId];
					}
					else {
						toUpdate = res.brokerIds;
						for (let i = 0; i < toUpdate.length; i++){
							if (toUpdate[i] === userId) {
								toUpdate.splice(i, 1);
								i = toUpdate.length + 1;
							}
						}
					}
				})
				.finally(() => {
					ReportsService.updateDocument(id, fk, toUpdate, fieldForUpdate, true)
					.then(() => {
						_getFolders();
					});
				})
				.catch((err) => {
					console.log(err);
				});
			}
			else {
				ReportsService.deleteDocument(id, fk, isGenerated, agencyFolder).then((res) => {
					if (res) {
						_getFolders();
					}
				})
				.catch((err) => {})
			}
		}
		self.deleteFolder = function (id) {
			ReportsService.deleteFolder(id).then((res) => {
				if (res) {
					_getFolders();
				}
			})
			.catch((err) => {})
		}

		self.setUploader = function (id, listing, document) {
			self.folderFileUploader.url = UploadService.baseUrl + '/Folders/' + id + '/uploadFile';
			self.folderFileUploader.onSuccessItem = function (fileItem, response, status, headers) {
				_getFolders();
			};
		}

		self.changeUploader = function (id, fk, dIndex) {
			self.activeUploader = {
				listing: id,
				document: dIndex
			}
			self.uploader.url = UploadService.baseUrl + '/Listings/' + id + '/document/' + fk + '/uploadDocument';
			self.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				if (self.rows) {
					for (let i = 0; i < self.rows.length; i++) {
						if (self.rows[i].id == response.listingId) {
							for (let j = 0; j < self.rows[i].documents.length; j++) {
								if (self.rows[i].documents[j].id == response.listingDocumentId) {
									self.rows[i].documents.splice(j, 1);
									if (self.rows[i].documents.length == 0) {
										self.rows.splice(i, 1);
									}
								}
							}
						}
					}
				}
			};
		}

		self.copyLink = function () {
			if (!self.reportLink) {
				return;
			}
			const fileName = self.reportLink;
			const copyTextarea = document.createElement('textarea');
			copyTextarea.value = fileName;
			copyTextarea.style.display = 'hidden';
			document.body.appendChild(copyTextarea);
			copyTextarea.select();
			try {
				document.execCommand('copy');
				self.copyLinkText = 'COMMON.COPIED';
			}
			catch (err) {
				return false;
			}
			finally {
				document.body.removeChild(copyTextarea);
				$timeout(() => {
					self.copyLinkText = 'FILES.COPY_TO_CLIPBOARD'
				}, 5000);
			}
		}
		self.open = function (url) {
			$window.open(url, '_blank');
		}

		self.close = function () {
			self.isClosed = true;
			self.reportLink = false;
		}
		self.isOpen = function () {
			return (!!self.reportLink && !self.isClosed)
		}
		self.agencySendFiles = function (id) {
			const url = UploadService.baseUrl + '/Folders/' + id + '/uploadFile';
			const modal = ModalService.openModal('agencySendFiles', { url: function () { return url } }, 'SendFilesController', 'sendFilesController');
			modal.result
			.then((res) => {
				_getFolders();
			})
			.catch((err) => { });
		}

		self.editFolder = function(event, folder){
			event.stopPropagation();
			event.preventDefault();
			const modal = ModalService.openModal('createFolder', { folder: function () { return { folder:folder }}, type: function() { return '' }, options: function() { return { } }  }, 'FolderController', 'folderController');
			modal.result
			.then((res) => {
				_getFolders();
			})
			.catch((err) => {
			});
		}

		self.downloadFolder = function(file, folder) {
			file = file || {};
			folder = folder || {};
			if (file.id && folder.id) {
				FileService.download('Folders', folder.id, file.id);
			}
		}

		self.docusignConnected = false;

		DocusignService.getStatus().then((res) => {
			self.docusignConnected = res.connected;
		})
		.catch((err) => {
			$log.error(err);
		});

		self.docusign = function(file) {
			AlertService.loading();
			DocusignService.sendDocument(file.id).then((res) => {
				AlertService.doneLoading();
				AlertService.successMessage('envelope');
				return DocusignService.popup(res.data.url);
			})
			.catch((err) => {
				AlertService.doneLoading();
				const message = err.data.error.message;
				if(message.indexOf('sent') > -1) {
					AlertService.errorMessage('envelopeExists');
				} else if(message.indexOf('integrated') > -1) {
					AlertService.errorMessage('docusignDisconnected');
				} else {
					AlertService.errorMessage('envelopeCreate');
				}
				$log.error(err);
			});
		}

		function _getFolders() {
			ReportsService.getFoldersAndReports()
			.then((res) => {
				res.forEach((folder) => {
					if (folder.agencyFolder) {
						folder.files.forEach((file) => {
							if (!file.allBrokers && file.brokers) {
								file.sentTo = 'Sent to: ';
								file.brokers.forEach((broker, i) => {
									file.sentTo += broker.user.fullName;

									if ( i < file.brokers.length - 1 ) {
										file.sentTo += ' + ';
									}
								})
							}
						})
					}
					folder.name = $sce.valueOf($filter('translate')(FileService.getFolderName(folder.name)));
				})
				self.folders = res;
			})
			.catch((err) => {})
			.finally(() => {
				AlertService.doneLoading();
			})
		}

		function _getCurrent(state) {
			switch(state) {
			case 'main.reports.listing-expiry-report':
				self.current = 'expiry';
				BrokerService.getBrokers()
				.then((res) => {
					self.filteredBrokers = res.reduce((arr,item) => {
						item.fullName = (item.user || {}).fullName || 'N/A';
						arr.push(item);
						return arr;
					}, []);
				})
				.catch((err) => {
					console.log('err: ', err);
				})
				break;
			case 'main.reports.new-buyers-by-property-type':
				self.current = 'new-buyers-by-property-type';
				break;
			case 'main.reports.ytd-listings':
				self.current = 'ytd';
				break;
			case 'main.reports.visits-by-broker':
				self.current = 'visits-by-broker';
				break;
			case 'main.reports.listings-with-no-seller-info':
				self.current = 'no-seller';
				break;
			case 'main.reports.missing-documents':
				self.current = 'missing-documents';
				break;
			case 'main.resources.my-files':
				_getFolders();
			}
		}
	});
})(); // End of function()
