/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
'use strict';

(function () {
	angular.module('smartbrokr')
	.controller('PhotosModalsController', function (data, type, $filter, $stateParams, $uibModalInstance, AlertService, GlobalVars, FileService, ListingService, ModalService, ResourceService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.data               = data;     // Used for the "finished generating" modal. Data that was created.
		self.type               = null;     // The type of file that will be generated
		self.title              = null;     // Translated title for the modal
		self.typeTranslationID  = null;     // The translation label for this type of file (video, feature sheet, etc)
		self.generating         = false;    // PDF: If true, displays spin icon - indicates that the "generate" function is running
		self.headerId           = {};       // PDF: The ID of the selected photo to be used as header
		self.headerPhoto        = {};       // PDF: The selected photo to be used as header
		self.currentFolderId      = {};
		self.caravan = {                    // PDF: Options for Caravan Bulletin
			isting: false,
			newPrice:   false
		};

		self.dropdowns = {                  // Options and config for images dropdowns
			config:     null,
			marketing:  [],
			folderConfig: {}
		};

		self.currentFolder = { files: [] };

		// SETUP VARIABLES ======================================================================================================================

		self.type           = _getType(type);
		self.title          = _getTitle(self.type);
		self.typeTranslationID = _getTypeTranslationID(self.type);
		self.tooltip = $filter('translate')('RESOURCES.HEADER_TOOLTIP', { type: self.type });

		_setup();

		// FUNCTIONS ============================================================================================================================

		/**
		 *  CARAVAN/FEATURE: Updates header image to be used in new file
		 */
		self.updateHeader = function () {
			self.headerPhoto = (self.currentFolder.files || []).find(x => x.id == self.headerId) || {};
		}

		self.selectFolder = function() {
			if(self.headerId != '' || self.headerId != {}) self.headerId = {};
			self.currentFolder = (self.dropdowns.marketing || []).find(x => x.id == self.currentFolderId) || {};
		}

		function _setup() {
			AlertService.loading();
			const folderConfig = GlobalVars.createSingleConfig(true, 'id', 'name', 'name');
			folderConfig.render = {
				item: function(item, escape) {
					return ('<div class="item">' + $filter('translate')(FileService.getFolderName(escape(item.name))) + '</div>');
				},
				option: function(item, escape) {
					return ('<div class="option">' + $filter('translate')(FileService.getFolderName(escape(item.name))) + '</div>')
				}
			}

			const config = GlobalVars.createSingleConfig(true, 'id', 'originalFilename', 'originalFilename');
			config.render = {
				option: function(item, escape) {
					return ('<div class=\'item has-image\'>' +
							( item.url ? '<div class=\'photo-preview-wrap\'><img src=\'' + escape(item.url) + '\' class=\'photo-preview\'></div>' : '' ) +
							'<div class=\'name\'>' + $filter('translate')(escape(item.originalFilename) + '</div>' +
						'</div>'))
				}
			};

			self.dropdowns.folderConfig = folderConfig;
			self.dropdowns.config = config;

			if ((self.type == 'caravan' || self.type == 'feature') && !data) {
				_getPdfImages();
			}
			else {
				AlertService.doneLoading();
			}
		}

		self.finish = function () {
			if (self.type == 'caravan') {
				_finishCaravanBulletin();
			}
			else if (self.type == 'feature') {
				_finishFeatureSheet();
			}
		}

		self.sendMessage = function () {
			$uibModalInstance.close();
			const resolve = {
				params: function() {
					return  { files: [ self.data ] }
				}
			}

			ModalService.openModal('sendEmail', resolve, 'SendEmailController', 'sendEmailController', null, 'email-modal');
		}

		function _finishCaravanBulletin() {
			self.generating = true;
			ListingService.generateCaravanBulletin($stateParams.id, self.caravan.newPrice, self.caravan.newListing, self.headerId)
			.then((res) => {
				self.generating = false;
				const ret = {
					type: 'cb',
					data: res
				}
				$uibModalInstance.close(ret);
			})
			.catch((err) => {
				self.generating = false;
			});
		}

		function _finishFeatureSheet() {
			self.generating = true;
			ListingService.generateFeatureSheet($stateParams.id, self.headerId)
			.then((res) => {
				self.generating = false;
				const ret = {
					type: 'fs',
					data: res
				}
				$uibModalInstance.close(ret);
			})
			.catch((err) => {
				self.generating = false;
			});
		}

		function _getPdfImages() {
			ResourceService.getMarketingImages()
			.then((folders) => {
				for(let i = 0; i < folders.length; i++) {
					self.dropdowns.marketing.push(folders[i]);
				}
			})
			.catch((err) => {})
			.finally(AlertService.doneLoading)
		}

		function _getTitle(type) {
			switch (type) {
			case 'caravan': return 'RESOURCES.NEW_CARAVAN_BULLETIN';
			case 'detailedFeature': return 'RESOURCES.NEW_FEATURE_SHEET';
			case 'feature': return 'RESOURCES.NEW_FEATURE_SHEET';
			}
		}

		function _getType (type) {
			switch (type) {
			case 'RESOURCES.CARAVAN_BULLETIN': return 'caravan';
			case 'Detailed Feature Sheet': return 'detailedFeature';
			case 'RESOURCES.FEATURE_SHEET': return 'feature';
			}
		}

		function _getTypeTranslationID (type) {
			let ret = '';
			switch (type) {
			case 'caravan': ret = 'RESOURCES.CARAVAN_BULLETIN'; break;
			case 'detailedFeature': ret = 'RESOURCES.FEATURE_SHEET'; break;
			case 'feature': ret = 'RESOURCES.FEATURE_SHEET'; break;
			}
			return '' + $filter('translate')(ret);
		}
	})
})();
