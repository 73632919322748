/* eslint-disable no-undef */
/**
 *  Supplier service
 */

(function () {

	'use strict';

	angular.module('smartbrokr.supplier', [])
	.service('SupplierService', function (Supplier, Broker, AgencyMember, AgencyOwner, AgencySupplier, ListingSeller, SupplierBroker, AccountService, GlobalVars, ModalService, $filter, $rootScope, $sce) {

		const self = this;
		let role;
		let roleApi;
		let roleId;
		let throughModel;
		let throughApi;
		let listApi;

		const getFilter = function() {
			setup();
			switch(role) {
			case 'agencyMemberProfile':
				return { agencyPersonId: roleId, agencyPersonType: 'AgencyMember' };
			case 'agencyOwnerProfile':
				return { agencyPersonId: roleId, agencyPersonType: 'AgencyOwner' };
			default:
				return { brokerId: roleId };
			}
		}

		const setup = function() {
			role = AccountService.getRole();

			switch(role) {
			case 'agencyMemberProfile':
				roleApi = AgencyMember.suppliers;
				roleId = AccountService.getAgencyMemberId();
				throughModel = AgencySupplier;
				throughApi = Supplier.supplierAgencies;
				listApi = AgencyMember.getRelatedList;
				break;
			case 'agencyOwnerProfile':
				roleApi = AgencyOwner.suppliers;
				roleId = AccountService.getAgencyOwnerId();
				throughModel = AgencySupplier;
				throughApi = Supplier.supplierAgencies;
				listApi = AgencyOwner.getRelatedList;
				break;
			case 'sellerProfile':
				roleApi = {
					create: function(args, supplier) {
						return ListingSeller.saveSupplier({ supplier: supplier });
					},
					updateById: function(args, supplier) {
						supplier.id = args.fk;
						return ListingSeller.saveSupplier({ supplier: supplier });
					},
					destroyById: function(args) {
						return ListingSeller.deleteSupplier({ supplierId: args.fk });
					}
				};
				break;
			default:
				roleApi = Broker.suppliers;
				roleId = AccountService.getBrokerId();
				throughModel = SupplierBroker;
				throughApi = Supplier.supplierBrokers;
				listApi = Broker.getRelatedList;
				break;
			}
		}

		self.createFolder = function (id, folder) {
			setup();
			return throughModel.folders.create({ id: id }, { name: folder }).$promise;
		}

		self.createSupplier = function (supplier) {
			setup();
			if (!supplier.id) {
				return roleApi.create({ id: roleId }, supplier).$promise;
			}
			else {
				return self.updateSupplier(supplier.id, supplier);
			}
		}

		self.deleteFolder = function (id, fk) {
			setup();
			const del = function() {
				return throughModel.folders.destroyById({ id: id, fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FOLDER');

		}

		self.deleteSupplier = function (fk) {
			setup();
			return roleApi.destroyById({ id: roleId, fk: fk }).$promise;
		}

		self.getColumns = function(suppliers, deleteFunction, isListing) {
			const lang = $rootScope.language;
			const config = GlobalVars.singleConfig;

			let aux = $filter('unique')(suppliers, 'industry');

			const industries = aux.reduce((arr, curr) => {
				console.log('curr', curr);
				if (curr) {
					console.log('labels', curr.labels[lang] || curr.labels['EN'], curr.id);
					arr.push({ name: curr.labels[lang] || curr.labels['EN'], value: curr.id });
				}

				return arr;
			}, [{ name: '------', value: 'xxx' }]);

			aux = $filter('unique')(suppliers, 'owner._address.city');

			const cities = aux.reduce((arr, curr) => {
				if (curr) {
					arr.push({ name: curr.name, value: curr.id });
				}

				return arr;
			}, [{ name: '------', value: 'xxx' }]);

			return [
				{
					name: 'PERSON.SUPPLIER.COMPANY',
					filter: 'company',
					cardView: true,
					size: 2,
					field: function(supplier) {
						return supplier.company;
					}
				},
				{
					name: 'PERSON.SUPPLIER.INDUSTRY',
					filter: 'industry',
					select: true,
					size: 2,
					config: config,
					options: industries,
					cardView: true,
					field: function(supplier) {
						return ((supplier.industry || {}).labels || {})[lang] || ((supplier.industry || {}).labels || {})['EN'];
					}
				},
				{
					name: 'PERSON.SUPPLIER.CONTACT',
					filter: 'fullName',
					cardView: true,
					size: 2,
					field: function(supplier) {
						return (supplier.owner || {}).fullName || '';
					}
				},
				{
					name: 'PERSON.PHONE',
					filter: 'phones',
					size: 2,
					field: function(supplier) {
						return $filter('getOnePhone')((supplier.owner || {}).phones || {});
					}
				},
				{
					name: 'EMAIL.EMAIL',
					filter: 'email',
					size: 2,
					isEmail: true
				},
				{
					name: 'ADDRESS.CITY',
					filter: 'city',
					size: 2,
					select: true,
					config: config,
					options: cities,
					field: function(supplier) {
						return (((supplier.owner || {})._address || {}).city || {}).name;
					}
				},
				{
					name: 'ADDRESS.POSTAL_CODE',
					filter: 'postalCode',
					size: 2,
					field: function(supplier) {
						return ((supplier.owner || {})._address || {}).postalCode;
					}
				},
				{
					nameImgClass: 'btn-list-icon do-not-email',
					nameImgPopover: 'btnDoNotEmail',
					filter: 'emailEnabled',
					noSearch: true,
					size: 1,
					class: 'check',
					style: { 'text-align': 'center' },
					isHtml: true,
					field: function(supplier) {
						return supplier.owner.emailEnabled ? '' : '<div class="icon-checked"></div>';
					}
				},
				{
					name: '',
					noSort: true,
					noSearch: true,
					isClick: true,
					isLimited: true,
					class: 'check',
					clickFunction: deleteFunction || false,
					field: function (supplier) {
						if ((supplier.isMine && isListing) || !isListing) {
							return $sce.trustAsHtml('<button class="btn btn-trash" ng-disabled="limitedAccess"></button>');
						}
						else return '';
					}
				}
			];
		}

		self.getFolders = function (id) {
			return throughApi({ id: id, filter: { where: getFilter(), include: { folders: 'files' } } }).$promise;
		}

		self.getMySupplier = function (id) {

			const role      = AccountService.getRole();
			const isManager = AccountService.getRole(true) === 'managerProfile';

			const filter = {
				fields: [ 'id', 'company', 'ownerId', 'industryId', 'rate', 'photoUrl', 'info', 'languageId', 'modified' ],
				include: [
					{
						relation: 'industry',
						scope: {
							fields: [
								'supplierId',
								'id',
								'type',
								'labels',
								'centris',
								'realtor'
							]
						}
					},
					{
						relation: 'owner',
						scope: {
							fields: [
								'supplierId',
								'id',
								'firstName',
								'lastName',
								'fullName',
								'languageIds',
								'email',
								'emailEnabled',
								'phones',
								'_address',
								'photoUrl',
								'secondaryEmail'
							]
						}
					}
				]
			}

			if (role === 'brokerProfile') {
				filter.include.push({
					relation: 'supplierBrokers',
					scope: {
						fields: ['supplierId'],
						where: {
							brokerId: AccountService.getBrokerId()
						}
					}
				})

				filter.include.push({
					relation: 'listingSuppliers',
					scope: {
						fields: [ 'id', 'supplierId', 'assets' ]
					}
				});
			}

			return Supplier.findById({ id: id, filter: filter }).$promise
			.then((supplier) => {
				supplier.isMine = !supplier.supplierBrokers || (supplier.supplierBrokers || []).length > 0;
				delete supplier.supplierBrokers;

				if (isManager) {
					const manager = AccountService.getManaging() || {};
					supplier.isMine = supplier.isMine && (manager.allClients || (manager.clientIds || []).indexOf(supplier.ownerId) >= 0);
				}

				if ((supplier.listingSuppliers || []).length > 0) {
					supplier.assets = ((supplier.listingSuppliers || [])[0] || {}).assets;
					delete supplier.listingSuppliers;
				}

				return supplier;
			});
			//return Broker.suppliers.findById({ id: AccountService.getBrokerId(), fk:fk }).$promise;
		}

		self.getOwnerId = function (fk) {
			setup();
			return roleApi.findById({ id: roleId, fk: fk }).$promise;
		}

		self.getSuppliers = function (skip, limit, sortBy) {
			setup();

			let options = null;

			if (sortBy) {
				options = {
					order: sortBy,
					limit: 8
				}
				options.order = sortBy;
				options.limit = 8;
			}

			//return Broker.getRelatedList({ id: AccountService.getBrokerId(), rel: 'suppliers' }).$promise;
			return listApi({ id: roleId, rel: 'suppliers', options: options }).$promise;
		}

		self.getThrough = function (id) {
			setup();
			return throughApi({ id: id, filter: { where: getFilter(), fields: { id: true } } }).$promise;
		}

		self.updateSupplier = function (fk, changes) {
			setup();
			delete changes.isMine;
			return roleApi.updateById({ id: roleId, fk: fk }, changes).$promise;
		}

		self.getVisits = function(lId) {
			return Supplier.getVisits({ id: lId }).$promise;
		}

		self.getAppointments = function (lId) {
			return Supplier.getAppointments({ id: lId }).$promise;
		}

		self.getSentEmails = function(id) {
			return Supplier.sentEmails({ id: id }).$promise;
		}

		self.linkBuyer = function(id, fk) {
			return Supplier.buyers.link({ id: id, fk: fk }).$promise;
		}

		self.unlinkBuyer = function(id, fk) {
			return Supplier.buyers.unlink({ id: id, fk: fk }).$promise;
		}

		self.linkSupplier = function(id, fk) {
			return Supplier.sellers.link({ id: id, fk: fk }).$promise;
		}

		self.unlinkSupplier = function(id, fk) {
			return Supplier.sellers.unlink({ id: id, fk: fk }).$promise;
		}
	})
})();
