/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
'use strict';

(function () {



	angular.module('smartbrokr')

	.controller('CalendarController', function (AccountService, AlertService, GlobalVars, ModalService, VisitService, $rootScope, $sce, $scope, $location, $timeout) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.selectedDay    = {};       // Today. Highlighted with grey background.
		self.popover        = null;     // Object with popover template IDs
		self.show           = null;     // Object with flags for what to show
		self.title          = null;     // Calendar title
		self.buttons        = null;     // Type of buttons to show (either 'B' for Broker or 'A' for Agency)
		let currentRole;                // Role of current user
		let scroll;                     // Object with anchor and container to use to scroll to events (mobile)
		let initDate        = null;     // Initial date, as set by a hash (used to create links to a day in the calendar)

		// SETUP VARIABLES ======================================================================================================================

		self.title  = moment().format('MMMM YYYY');
		currentRole = AccountService.getRole();
		const isManager = AccountService.isAdmin();

		self.user = isManager ? AccountService.getManaging() : AccountService.getAccount().user;
		if(self.user && self.user.googleCalendarId) {
			self.googleCalendarUrl = $sce.trustAsResourceUrl('https://calendar.google.com/calendar/embed?src=' + self.user.googleCalendarId + '&ctz=America%2FNew_York');
		}

		self.show = {
			listings: true,
			personal: true,
			closing: true,
			appointments: true,
			visits: true
		}

		if ($location.hash() !== '') {
			initDate = new Date($location.hash());
			$location.hash('');
		}

		self.popover = {
			day: GlobalVars.popover('calendarDay'),
			personal: GlobalVars.popover('calendarPersonal'),
			visits: GlobalVars.popover('calendarEvent'),
			curr: '',
			headerOpen: false
		}

		switch (currentRole) {
		case 'adminProfile':
		case 'managerProfile':
		case 'brokerProfile':
			self.show.visits = true;
			self.buttons = 'B';
			break;
		default:
			self.show.visits = false;
			self.buttons = 'A';
		}

		scroll = {
			container: angular.element(document.getElementById('main-content')),
			doScroll: function() {
				const anchor = angular.element(document.getElementById('mobile-day'));
				this.container.scrollToElement(anchor, 20, 200);
			}
		}

		// FUNCTIONS ============================================================================================================================

		self.limitedAccess 	= AccountService.limitedAccess;

		/**
       *  Sets current popover to empty value so all popovers are closed.
       */
		self.closePopovers = function() {
			self.popover.curr = '';
			self.popover.headerOpen = false;
			self.selectedDay = {};
		};

		self.getStatus = function(person) {
			if(person.isCancelled) return 'DECLINED';
			if(person.isConfirmed) return 'CONFIRMED';
			return 'AWAITING_RESPONSE';
		}

		/**
       *  Checks if a given day has the same date as initDate (if set)
       */
		self.isInitial = function(day) {
			if (!initDate)
				return;

			if (moment(day.date).isSame(moment(initDate), 'day')) {
				self.selectDay(day);
			}
		}

		self.getAll = function (rows) {

			rows = rows || [];

			if (rows.length > 0) {
				const startDate = moment.utc(((rows[0] || [])[0] || {}).date).subtract(1, 'days').hours(23).minute(59).toDate();
				const nDays = 42; // Number of days that directive adds to the calendar

				return VisitService.getMyCalendar(startDate, nDays)
				.then((events) => {
					return events;
				})
				.catch((err) => {
					console.error('error: ', err);
					return Promise.resolve([]);
				})
				.finally(() => {
					AlertService.doneLoading();
				})
			}
			else {
				return Promise.resolve([]);
			}
		}

		/**
       *  Called by each day of the calendar when view is refreshed.
       *  Gets events to be shown in each day.
       *  @param    day       object      Object representing a day (created by uib-daypicker directive)
       *  @return             array       Array of events (visits/appointments) scheduled for that date
       */
		self.getEvents = function (day, events) {

			const ret = events.reduce((arr, entry) => {
				let date, check;

				if (entry.type == 'L' || entry.type == 'C') {   // Dates stored in UTC
					date = moment.utc(day);
					check = moment.utc(entry.closing || entry.expiry);
				}
				else {  // Dates are relevant to user's timezone
					date = moment(day);
					check = moment(entry.scheduled || entry.start);
				}

				if (date.isSame(check, 'day')) {
					arr.push(entry);
				}
				return arr;
			}, []);

			if (self.selectedDay.date) {  // Selected day users UTC timezone
				if (moment.utc(self.selectedDay.date).isSame(moment.utc(day), 'day')) {
					self.selectedDay.events = ret;
				}
			}

			return ret;
		}

		/**
       *  Opens modal to edit or create a visit/appointment
       *  Broadcasts an update event so view is refreshed (in case something was saved)
       *  @param    event     Visit       Visit that will be edited
       */
		self.editEvent = function (event) {
			self.closePopovers();

			if (!self.limitedAccess()) {
				const modal = ModalService.openModal('createVisit', {
					visit:          () => event,
					isAppointment:  () => event.type == 'A',
					isGoogle:       () => (self.user && self.user.googleCalendarId && self.user.isGoogleIntegrated) ? true : false,
				}, 'VisitController', 'visitController');

				modal.result.then((res) => {
					if (res) {
						AlertService.saved();
						$scope.$broadcast('update');
					}
				})
				.catch((err) => {})
			}
		}

		self.editPersonal = function (event) {
			self.closePopovers();

			if (!self.limitedAccess()) {
				const modal = ModalService.openModal('personalEvent', {
					event: () => event
				}, 'PersonalEventController', 'controller');

				modal.result.then((res) => {
					if (res) {
						AlertService.saved();
						$scope.$broadcast('update');
					}
				})
				.catch((err) => {})
			}
		}

		/**
       *  Deletes an event from a user's calendar.
       *  @param    event     Visit       Visit that will be cancelled
       */
		self.deleteEvent = function (event) {
			self.closePopovers();

			if (!self.limitedAccess()) {

				if (event.type !== 'P') {
					const fields = [
						{
							labels: [ 'PAGES.CALENDAR.ALERTS.CANCEL_BROKER' ],
							justText: true,
							class: 'just-text'
						},
						{
							label: 'COMMON.REASON',
							model: 'reason',
							inputType: 'text',
							required: false
						}
					];

					ModalService.openForm(fields, { label: 'PAGES.CALENDAR.ALERTS.TITLE', vars: { what: event.type } }, true).then((res) => {
						cont(res.reason);
					},
					(reason) => {})
				}
				else cont();
			}

			function cont(reason) {
				let api;
				switch (event.type) {
				case 'A':
				case 'V': api = VisitService.deleteVisit; break;
				case 'P': api = VisitService.deletePersonal; break;
				}

				api(event.id, event.type, reason).then((res) => {
					if (res) {
						AlertService.success('COMMON.EVENT', 'cancel');
						$scope.$broadcast('update');
					}
				})
				.catch((err) => {})
			}
		}

		/**
       *  Marks a day as selected.
       *  On desktop version, opens popover.
       *  On mobile version, adds border and opens info in the bottom of screen.
       *  @param    day     Object        Day that was clicked by the user
       */
		self.selectDay = function (day) {
			self.selectedDay = null;

			$timeout(() => {
				self.popover.curr = day.uid;
				self.selectedDay = day;

				if ($rootScope.screenSize !== 'lg') {
					$timeout(() => {
						scroll.doScroll();
					}, 50)
				}
			})
		}

		/**
       *  Checks whether a certain type of event (Visit or Appointment)
       *  is present in an array of events.
       *  Used in mobile view.
       *  @param    events    array       Array of Visits to check
       *  @param    type      string      Type of event to check for. Either 'A' or 'V'.
       *  @return             boolean     true if one event of the relevant type was found. false otherwise.
       */
		self.doIHave = function (events, type) {
			if (!events) return false;
			return !!events.find(x => x.type == type);
		}

		self.getPopover = function (type) {
			switch (type) {
			case 'V':
			case 'A': return self.popover.visits;
			case 'P': return self.popover.personal;
			default: return null;
			}
		}

		self.updateFilter = function(key) {
			self.show[key] = !self.show[key];
		}

		self.doPopover = function(event) {
			self.popover.curr = event.id

			const max = ((($('.uib-daypicker').first() || [])[0] || {}).clientHeight || 300) - 95;

			$timeout(() => {
				$('.event-content').css('max-height', max + 'px');
			}, 100)
		}

	}) // End controller

	/**
     *  Filter to show only selected types.
     *  Currently checks only for Visits or Appointments
     *  Returns array of events sorted by scheduled day and time.
     */
	.filter('eventType', () => {
		return function (array, show) {

			show = show || {};

			let showNothing = true;

			for (const i in show) {
				if (show[i]) {
					showNothing = false;
					break;
				}
			}

			if (showNothing || !array) return [];

			const types = {
				V: show.visits,
				A: show.appointments,
				L: show.listings,
				C: show.closing,
				P: show.personal
			}

			const ret = array.reduce((arr, curr) => {
				if (types[curr.type]) {
					arr.push(curr);
				}
				return arr;
			}, []);

			return ret.sort((a, b) => {
				const diff = Date.parse(a.scheduled || a.expiry || a.start) - Date.parse(b.scheduled || b.expiry || b.start);

				if (diff == 0) {
					return Date.parse(a.modified) - Date.parse(b.modified);
				}
				else {
					return diff > 0;
				}
			});
		};
	})
})(); // End function
