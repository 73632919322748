(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('QuickbooksController', function ($localStorage, $filter, $log, $scope, AdminService, AlertService, QuickbooksService, GlobalVars, SortingService) {
		const self = this;
		self.showTooltips = $localStorage.quickbooksTooltips;

		self.startLoading = true;
		self.loading = true;

		self.enabled = true;
		self.disabled = !self.disabled;

		self.QBEmail = '';
		self.QBName = '';

		self.QBConnectWatcher;

		self.connect = function() {
			QuickbooksService.connect();
		}

		self.disconnect = async function() {
			self.QBEmail = '';
			self.QBName = '';
			await QuickbooksService.disconnect();
			getQuickbooksStatus();
		}

		async function getQuickbooksStatus(showLoader = true) {
			if (showLoader) {
				AlertService.loading();
			}
			self.loading = true;
			const res = await QuickbooksService.getStatus();
			self.loading = false;
			self.startLoading = false;

			if (!res || !res.data) {
				if (showLoader) {
					AlertService.doneLoading();
				}
				$scope.$apply();
				return;
			}

			self.enabled = res.data.status;
			// If connected, fetch QB's User Data
			if (self.enabled) {
				QuickbooksService.getUserInfo().then(
					({ data }) => {
						self.QBEmail = data.email;
						self.QBName = `${data.givenName} ${data.familyName}`;
					},
					err => console.error(err),
				);
			}

			if (showLoader) {
				AlertService.doneLoading();
			}
			$scope.$apply();
		}

		function setupWatcher() {
			self.QBConnectWatcher = setInterval(() => {
				if (!self.loading && !self.enabled) {
					getQuickbooksStatus(false);
				}
			}, 500);
			$scope.$on('$destroy', () => {
				if (self.QBConnectWatcher) clearInterval(self.QBConnectWatcher);
			});
		}

		getQuickbooksStatus();

		setupWatcher();

	});
})();
