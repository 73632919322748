/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.task', [])
	.service('TaskService', function (AccountService, AlertService, ModalService, Agency, AgencyMember, AgencyOwner, Broker, Buyer, Comment, Container, Listing, SbUser, Seller, Supplier, Task, $filter, $window, $q, $rootScope) {

		const self = this;

		const _ = $window._;

		// Translatable labels for each type of model that a task can be related as 'about'
		const SUPPLIER      = 'ROLES.SUPPLIER';
		const BROKER        = 'ROLES.BROKER';
		const SELLER        = 'ROLES.SELLER';
		const BUYER         = 'ROLES.BUYER';
		const LISTING       = 'LISTING.LISTING';
		const AGENCY_MEMBER = 'ROLES.AGENCY_MEMBER';
		const AGENCY_OWNER  = 'ROLES.AGENCY_OWNER';
		const AUDITOR       = 'ROLES.AUDITOR';
		const PERSONAL      = 'PERSON.PERSONAL';

		let roleApi;
		let roleId;

		const setup = function() {
			const role = AccountService.getRole();

			switch(role) {
			case 'agencyMemberProfile':
				roleApi = AgencyMember;
				roleId = AccountService.getAgencyMemberId();
				break;
			case 'agencyOwnerProfile':
				roleApi = AgencyOwner;
				roleId = AccountService.getAgencyOwnerId();
				break;
			default:
				roleApi = Broker;
				roleId = AccountService.getBrokerId();
				break;
			}
		}

		/** Gets all Brokers from the current broker's agency */
		self.getBrokers = function () {
			const filter = {
				fields: ['id', 'sbUserId'],
				include: {
					relation: 'user',
					scope: {
						fields: ['id', 'fullName']
					}
				}
			}
			return Agency.brokers({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		}

		self.getAgencyPeople = function() {
		  	const promises = [];
			let ret = [];

			const filter = {
				where: {
					status: {
						neq: 'DISABLED'
					}
				},
				fields: [ 'id', 'sbUserId', 'status' ],
				include: {
					relation: 'user',
					scope: {
						fields: ['id', 'fullName']
					}
				}
			}

			const supplierFilter = {
				fields: [ 'id', 'ownerId' ],
				include: {
					relation: 'owner',
					scope: {
						fields: ['id', 'fullName']
					}
				}
			}

			const createPromise = function(callName, tag, dataType) {
				return newPromise(Agency[callName]({ id: AccountService.getAgencyId(), filter: filter }).$promise, (res) => {
					res.forEach((item) => {
						item.tag = tag;
						item.dataType = dataType;
					})
					ret = ret.concat(res);
				})
			}

			promises.push(createPromise('brokers', BROKER, 'Broker'));

			filter.where = {
				disabled: {
					neq: true
				}
			}

			filter.fields[2] = 'disabled';

			promises.push(createPromise('members', AGENCY_MEMBER, 'AgencyMember'));
			promises.push(createPromise('owners', AGENCY_OWNER, 'AgencyOwner'));

			promises.push(
				newPromise(
					Broker.suppliers({ id: AccountService.getBrokerId(), filter: supplierFilter }).$promise,
					(res) => {
						res.forEach((item) => {
							item.tag = SUPPLIER;
							item.dataType = 'Supplier';
						});
						ret = ret.concat(res);
					}
				)
			)

			return $q.all(promises).then((result) => {
				return ret;
			})
			.catch((err) => {
				console.log('err: ', err);
			});
		}

		self.deleteOne = function(taskId) {
			const delFunction = function() {

				if (!!taskId) {
					AlertService.loading();
					return Task.destroyById({ id: taskId }).$promise;
				}
				return $q.when(true);
			}

			return ModalService.confirmDelete(delFunction, $filter('translate')('ALERT_MESSAGES.ALERT.DELETE_TASK'));
		}

		self.deleteTasks = function(aboutId, aboutType, count) {
			setup();

			const delFunction = function() {
				AlertService.loading();
				return roleApi.bulkDeleteTasks({ id: roleId, aboutId: aboutId, aboutType: aboutType }).$promise;
			}
			return ModalService.confirmDelete(delFunction, $filter('translate')('ALERT_MESSAGES.ALERT.DELETE_TASK', { num: count }));
		}

		/** Gets suppliers belonging to current user */
		self.getSuppliers = function (supplierIds) {
			setup();

			const filter =  {
				fields: [ 'id', 'company', 'industryId', 'ownerId' ],
				include: [
					{
						relation: 'industry',
						scope: {
							fields: [
								'supplierId',
								'id',
								'type',
								'labels',
								'centris',
								'realtor'
							]
						}
					},
					{
						relation: 'owner',
						scope: {
							fields: ['id', 'firstName', 'lastName', 'fullName'],
						}
					}
				]
			}

			if ((supplierIds || []).length > 0) {
				filter.where = {
					_id: {
						nin: supplierIds
					}
				}
			}

			return roleApi.suppliers({ id: roleId, filter: filter }).$promise;
		}

		self.getCurrentUser = function () {
		  return SbUser.findById({ id: AccountService.getUserId() }).$promise;
		}

		/**
		 *  Gets all of the current broker's active and smart listings
		 *  Includes Property, Brokers, Tasks (+ Comments, AssignedTo)
		 *
		 *  @param      lId         String          Listing MLS, in case we only want one listing
		 *  @param      simple      boolean         If true, only gets basic information
		 *  @return                 $promise        Query to be executed in the DB
		 */
		const getListings = function (lId, simple) {

			const filter = {
				fields: [ 'id', 'status' ],
				include: [
					{
						relation: 'property',
						scope: {
							fields: ['_address', 'listingId']
						}
					},
				],
				where: {
					or: [
						{ status: 'Active' },
						{ status: 'Smart' }
					]
				}
			}

			if (simple) {
				filter.include.push({
					relation: 'tasks',
					scope: {
						fields: ['listingId', 'name', 'completed', 'description', 'due'],
					}
				});
			}
			else {
				filter.include.push({
					relation: 'tasks',
					scope: {
						fields: ['listingId', 'name', 'completed', 'description', 'due'],
						include: [
							{ assignedTo: { relation: 'user', scope: { fields: ['fullName', 'firstName'] } } },
							{
								relation: 'suppliers',
								scope: {
									fields: [ 'id', 'company', 'industryId' ],
									include: {
										relation: 'industry',
										scope: {
											fields: [
												'supplierId',
												'id',
												'type',
												'labels',
												'centris',
												'realtor'
											]
										}
									},
								}
							}
						],
						order: ['due ASC', 'name ASC']
					}
				});
			}

			if (!lId) {
				return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
			}
			else {
				return Listing.findById({ id: lId, filter: filter }).$promise;
			}
		}

		/**
		 *  Sorts tasks belonging to objects in an array according to due date.
		 *
		 *  @param      arr         Array           Array of objects with tasks that we want to sort (buyers,sellers,listings,etc)
		 *  @param      type        String          Name of model type in the array. Used later for task partials and modals.
		 *  @param      taskField   String          Name of field to look for tasks. Either 'tasks' or 'tasksAbout'
		 *  @return                 Array           Sorted array
		 */
		const sortTasks = function (arr, type, taskField) {
			arr = arr || [];
			arr.forEach((entry) => {
				entry.type = type;
				entry[taskField].sort((a, b) => {
					if ((a.due == null || a.due == undefined || a.due === '') && (b.due == null || b.due == undefined || b.due === '')) {
						return 0;
					}
					else if (a.due == null || a.due == undefined || a.due === '') {
						return 1;
					}
					else if (b.due == null || b.due == undefined || b.due === '') {
						return -1;
					}
					else {
						return Date.parse(a.due) - Date.parse(b.due);
					}
				})
			});

			return arr;
		}

		/**
		 *  Gets the broker's listings, then takes out listings with no tasks
		 *  or that have all the tasks complete. If lId is defined, only gets
		 *  that one listing.
		 *  After array of listings, sorts their tasks by due date.
		 *
		 *  @param      lId         String          Listing ID (in case we only one tasks for one listing)
		 *  @param      simple      boolean         If true, gets only basic information (to show in the menu)
		 *  @return                 Array<Listing>  Listings with task data included
		 */
		self.getListingList = function (lId, simple) {

			return getListings(lId, simple).then((res) => {
				const reduceListings = function (arr, item) {
					if ((item.tasks || []).length > 0) {
						item.taskSuppliers = item.tasks.reduce((arr, task) => {
							return arr.concat(task.suppliers);
						}, []);
						item.taskSuppliers = _.uniq(item.taskSuppliers || [], false, _.iteratee('id')); // Get unique suppliers
						arr.push(item);
					}

					return arr;
				}

				let listings;
				if (!lId || res.length > 1) {

					listings = res.reduce(reduceListings, []);

					listings.sort((a, b) => {
						let item1 = (((a.property || {})._address || {}).streetName || 'ZZZZ' + a.id).toLowerCase();		// Added 'ZZZZ' to force listings with no address to the bottom
						let item2 = (((b.property || {})._address || {}).streetName || 'ZZZZ' + b.id).toLowerCase();

						let ret = item1.localeCompare(item2) - item2.localeCompare(item1);

						if (ret == 0) {
							item1 = (((a.property || {})._address || {}).addressString || 'ZZZZ' + a.id).toLowerCase();
							item2 = (((b.property || {})._address || {}).addressString || 'ZZZZ' + b.id).toLowerCase();
							ret = item1.localeCompare(item2) - item2.localeCompare(item1);
						}

						return ret;
					})
				}
				else {
					res.type = LISTING;
					listings = [res];
				}

				return sortTasks(listings, LISTING, 'tasks');
			})
		}

		const getModel = function (model, filter) {
			setup();
			return model({ id: roleId, filter: filter }).$promise;
		}

		const getPersonsList = function (model, id, type, simple, tasksFilter) {
			const filter = {};

			if (!filter.include) {
				filter.include = [];
			}

			filter.include.push({
				relation: (type == SUPPLIER ? 'owner' : 'user'),
				scope: {
					fields: ['id', 'fullName']
				}
			})

			if (id) {
				filter.where = filter.where || {};
				filter.where[ type === SUPPLIER ? 'id' : 'sbUserId' ] = id;
			}

			if (simple) {
				filter.include.push('tasksAbout');
			}
			else {
				tasksFilter = tasksFilter || {};
				tasksFilter.include = {
					relation: 'assignedTo',
					scope: {
						include: {
							relation: 'user',
							scope: {
								fields: [ 'id', 'fullName' ]
							}
						}
					}
				}

				filter.include.push({
					relation: 'tasksAbout',
					scope: tasksFilter
				});
			}

			return getModel(model, filter).then((res) => {

				let people = res;

				if (!id || res.length > 1) {

					people = res.reduce((arr, item) => {
						if ((item.tasksAbout || []).length > 0) {
							arr.push(item);
						}
						return arr;
					}, []);

					people.sort((a, b) => {
						const item1 = ((a.user || {}).fullName || a.company || '').toLowerCase();		// Added 'ZZZZ' to force listings with no address to the bottom
						const item2 = ((b.user || {}).fullName || b.company || '').toLowerCase();
						const ret = item1.localeCompare(item2) - item2.localeCompare(item1);
						return ret;
					})
				}

				return sortTasks(people, type, 'tasksAbout');
			})
		}

		self.getBuyersList = function (uId, simple) {
			if (uId) {
				return getPersonsList(Broker.buyers, uId, BUYER, simple);
			}
			else {
				return getPersonsList(Broker.buyers, null, BUYER, simple);
			}
		}

		self.getSellersList = function (uId, simple) {
			if (uId) {
				return getPersonsList(Broker.sellers, uId, SELLER, simple);
			}
			else {
				return getPersonsList(Broker.sellers, null, SELLER, simple);
			}
		}

		// Loopback findById (and find with filter by id) doesn't work for related models,
		// so suppliers are fetched according to their ownerId and company name (which should only return one result)
		self.getSuppliersList = function (uId, simple) {
			setup();
			if (uId) {
				return getPersonsList(roleApi.suppliers, uId, SUPPLIER, simple);
			}
			else {
				return getPersonsList(roleApi.suppliers, null, SUPPLIER, simple);
			}
		}

		self.getMyTasks = function (simple, filter) {
			filter = filter || {};
			const currentRole = AccountService.getRole();

			if (!simple) {
				filter.include = [
					{ comments: [{ relation: 'owner', scope: { fields: ['fullName', 'photoUrl'] } }, 'files'] },
					{ assignedTo: { relation: 'user', scope: { fields: ['fullName', 'firstName'] } } },
					'files',
					{ relation: 'suppliers', scope: { fields: ['company'] } }
				]
			}
			if (AccountService.isLikeBroker()) {
				return Broker.getAllTasks({ id: AccountService.getBrokerId(), filter: filter }).$promise;
			}
			else if (currentRole == 'agencyMemberProfile') {
				return AgencyMember.getAllTasks({ id: AccountService.getAgencyMemberId(), filter: filter }).$promise;
			}
			else if (currentRole == 'agencyOwnerProfile') {
				return AgencyOwner.getAllTasks({ id: AccountService.getAgencyOwnerId(), filter: filter }).$promise;
			}
		}

		/** Updates a Task (if it has an id) or Creates a new one */
		self.saveTask = function (task) {
			// Update
			if (task.id) {
				return Task.prototype$updateAttributes({ id: task.id }, task).$promise;
			}

			// New
			else {
				switch (task.aboutType) {
				case SELLER:
					task.aboutType = 'Seller';
					return Seller.tasksAbout.create({ id: task.aboutId }, task).$promise;

				case BUYER:
					task.aboutType = 'Buyer';
					return Buyer.tasksAbout.create({ id: task.aboutId }, task).$promise;

				case SUPPLIER:
					task.aboutType = 'Supplier';
					return Supplier.tasksAbout.create({ id: task.aboutId }, task).$promise;

				case BROKER:
					task.aboutType = 'Broker';
					return Broker.personalTasks.create({ id: task.aboutId }, task).$promise;

				case LISTING:
					task.listingId = task.aboutId;
					delete task.aboutType;
					delete task.aboutId;
					return Listing.tasks.create({ id: task.listingId }, task).$promise;

				case PERSONAL:
					delete task.aboutType;
					var role = AccountService.getRole();

					switch(role) {
					case 'brokerProfile':
					case 'managerProfile':
						task.aboutType = 'Broker';
						return Broker.personalTasks.create({ id: task.aboutId }, task).$promise;
					case 'agencyOwnerProfile':
						task.aboutType = 'AgencyOwner';
						return AgencyOwner.tasksAbout.create({ id: AccountService.getAgencyOwnerId() }, task).$promise;
					case 'agencyMemberProfile':
						task.aboutType = 'AgencyMember';
						return AgencyMember.tasksAbout.create({ id: AccountService.getAgencyMemberId() }, task).$promise;
					}
				}
				return $q.when(true);     // Should not happen...
			}
		}

		self.completeTask = function (task) {
			try {
				if (task.completed != null && task.completed != undefined) {
					task.completed = null;
				}
				else {
					task.completed = new Date();
				}

				return self.saveTask(task);
			}
			catch (err) {
				console.error('ERR: ', err);
				$rootScope.$emit('error', { message: 'Error saving data. Please, check your internet connection and try again.' });
				return $q;
			}
		}

		/** Find a Task by id (includes Listing, Comments, AssignedTo) */
		self.getTask = function (id, type) {
			setup();
			const filter = {
				include: [
					{
						relation: 'listing',
						scope: {
							fields: [ 'id' ],
							include: [
								{
									relation: 'property',
									scope: {
										fields: [ 'listingId', '_address' ]
									}
								},
								{
									relation: 'tasks',
									scope: {
										where: {
											id: { neq: id }
										},
										fields: [ 'id', 'listingId', 'supplierIds' ],
										include: {
											relation: 'suppliers',
											scope: {
												fields: [ 'id', 'company', 'industryId' ],
												include: [
													{
														relation: 'industry',
														scope: {
															fields: [
																'supplierId',
																'id',
																'type',
																'labels',
																'centris',
																'realtor'
															]
														}
													},
													{
														relation: 'supplierBrokers',
														scope: {
															where: {
																brokerId: roleId
															}
														}
													},
													{
														relation: 'supplierAgencies',
														scope: {
															where: {
																agencyPersonId: roleId
															}
														}
													}
												]
											}
										}
									}
								}
							]
						}
					},
					'files',
					{
						comments: [
							{
								relation: 'owner',
								scope: {
									fields: [ 'id', 'fullName', 'photoUrl' ]
								}
							},
							'files'
						]
					},
					{
						assignedTo: 'user'
					},
					{
						relation: 'suppliers',
						scope: {
							fields: [ 'id', 'industryId', 'company' ],
							include: [
								{
									relation: 'industry',
									scope: {
										fields: [
											'supplierId',
											'id',
											'type',
											'labels',
											'centris',
											'realtor'
										]
									}
								},
								{
									relation: 'supplierBrokers',
									scope: {
										where: {
											brokerId: roleId
										}
									}
								},
								{
									relation: 'supplierAgencies',
									scope: {
										where: {
											agencyPersonId: roleId
										}
									}
								}
							]
						}
					}
				]
			}

			if (type) {
				if (type != SUPPLIER) {
					filter.include.push({ about: 'user' });
				}
				else {
					filter.include.push('about');
				}
			}
			return Task.findById({ id: id, filter: filter }).$promise;
		}

		self.getTaskOwner = function (id, type) {
			const filter = {
				include: [
					{
						relation: 'listing',
						scope: {
							fields: ['id'],
							include: {
								relation: 'property'
							}
						}
					},
					'files',
					{ comments: ['owner', 'files'] },
					{ assignedTo: 'user' },
					'suppliers'
				]
			}
			if (type) {
				if (type != 'Supplier') {
					filter.include.unshift({
						relation: 'about',
						scope: {
							include: {
								relation: 'user'
							}
						}
					});
					filter.include.push({ about: 'user' });
				}
				else {
					filter.include.push('about');
				}
			}
			return Task.findById({ id: id, filter: filter }).$promise;
		}

		self.orderTaskItems = function (arr) {
			const weights = {}
			weights[BROKER] = 5;
			weights[BUYER] = 4;
			weights[SELLER] = 3;
			weights[LISTING] = 2;
			weights[SUPPLIER] = 1;
			if (arr.length > 1) {
				return arr.sort((a, b) => {

					let ret = 0;

					ret = weights[b.type] - weights[a.type];

					if (ret == 0) {
						let item1 = (a.user || {}).fullName || ((a.property || {})._address || {}).streetName || a.company || '';
						let item2 = (b.user || {}).fullName || ((b.property || {})._address || {}).streetName || b.company || '';

						ret = item1.localeCompare(item2) - item2.localeCompare(item1);

						if (ret == 0) {
							item1 = (((a.property || {})._address || {}).addressString || 'ZZZZ' + a.id).toLowerCase();
							item2 = (((b.property || {})._address || {}).addressString || 'ZZZZ' + b.id).toLowerCase();
							return item1.localeCompare(item2) - item2.localeCompare(item1);
						}
					}
					return ret;
				})
			}
			return arr;
		}

		function newPromise(call, then) {
			return $q((resolve, reject) => {
				if (!call) return resolve(true);
				call.then((res) => {
					then(res);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				})
			});
		}

		self.getMenuLists = function (complete) {
			const currentRole = AccountService.getRole();
			let list = [];
			const promises = [];
			complete = complete || false;

			const profile = AccountService.getCurrentRoleProfile();
			if (currentRole == 'managerProfile' || currentRole == 'brokerProfile') {
				promises.push(newPromise(self.getMyTasks(), (res) => {
					list.push({ type: BROKER, tasks: res, title: $filter('translate')(PERSONAL), id: AccountService.getBrokerId(), ignoreFilter: true });
				}));

				promises.push(newPromise(self.getBuyersList(null, !complete), (res) => {
					list = list.concat(res);
				}));

				promises.push(newPromise(self.getSellersList(null, !complete), (res) => {
					list = list.concat(res);
				}));

				promises.push(newPromise(self.getListingList(null, !complete), (res) => {
					list = list.concat(res);
				}));

				promises.push(newPromise(self.getSuppliersList(null, !complete), (res) => {
					list = list.concat(res);
				}));
			}
			else {
				promises.push(newPromise(self.getMyTasks(), (res) => {
					list.push({ type: BROKER, tasks: res, title: $filter('translate')(PERSONAL), id: profile.id, ignoreFilter: true });
				}));

				if (currentRole == 'agencyOwnerProfile' || currentRole == 'agencyMemberProfile') {
					promises.push(newPromise(self.getSuppliersList(null, !complete), (res) => {
						list = list.concat(res);
					}));
				}
			}

			return $q.all(promises).then((result) => {
				return self.orderTaskItems(list);
			})
		}

		self.createLists = function(complete) {

			const isBroker = AccountService.isLikeBroker();

			return self.getMenuLists(complete).then((res) => {
				const ret = {};

				const translated = {}
				translated[SUPPLIER]    = $filter('translate')(SUPPLIER);
				translated[SELLER]      = $filter('translate')(SELLER);
				translated[BUYER]       = $filter('translate')(BUYER);
				translated[LISTING]     = $filter('translate')(LISTING);
				translated[PERSONAL]    = $filter('translate')(PERSONAL);

				res.forEach((item, i) => {
					const toAdd = {
						link: '/tasks#' + translated[item.type] + '-',
						type: item.type
					};

					switch (item.type) {
					case BUYER:
					case SELLER:
						toAdd.title = (item.user || {}).fullName || '';
						break;
					case SUPPLIER:
						toAdd.title = item.company || '';
						break;
					case LISTING:
						var address = ((item.property || {})._address || {}).addressString || item.id;
						if (address.includes('No Address')) {
							address = address + ' (' + item.id + ')';
						}
						toAdd.title = address;
						break;
					default:
						toAdd.type = PERSONAL;
						toAdd.title = $filter('translate')(PERSONAL);
						toAdd.ignoreFilter = true;
					}

					const section = translated[toAdd.type];

					if (!ret[section]) {
						ret[section] = {
							title: toAdd.type,
							content: []
						};
					}

					toAdd.link += ret[section].content.length;

					if (complete) {
						toAdd.tasks = item.tasks || item.tasksAbout || [];
						toAdd.id = item.id;
						toAdd.taskSuppliers = item.taskSuppliers;
					}
					ret[section].content.push(toAdd);
				})

				for (const i in translated) {
					if (!ret[translated[i]] && (isBroker || i === SUPPLIER)) {
						ret[translated[i]] = {
							title: i,
							content: []
						};
					}
				}

				return ret;
			})
		}

		self.createComment = function (id, comment) {
			comment.ownerId = AccountService.getUserId();
			return Task.comments.create({ id: id }, comment).$promise;
		}

		self.updateComment = function (id, fk, comment) {
			return Task.comments.updateById({ id: id, fk: fk }, comment).$promise;
		}

		/** Adds comment to a task */
		self.addComment = function (comment, taskId) {
			comment.ownerId = AccountService.getUserId();
			return Task.comments.create({ id: taskId }, comment).$promise;
		}

		/** Adds a file to a comment (id = comment id) */
		self.addFileToComment = function (id, file) {
		  	return Comment.files.create({ id: id }, file).$promise;
		}

		/** Gets the class to apply to the 'AssignedTo' column in the master tasks
		 *  Determines background color
		 */
		self.getClass = function (task) {
			const today = moment().startOf('day');
			const belongsToUser = _belongsToUser();
			const due = moment(task.due).startOf('day');

			if (task.completed) {
				return 'completed';
			}
			else if (!!task.due && today.isAfter(due, 'day')) { // due before today
				return 'overdue';
			}
			else if (!!task.due && due.diff(today, 'days') <= 7 && due.diff(today, 'days') >= 0) {	// 7 days
				return 'due-soon';
			}
			else if (belongsToUser) {
				return 'mine';
			}
			else {
				return 'not-mine';
			}

			function _belongsToUser() {
				const assignedToId = task.assignedToId || (task.assignedTo || {}).id;
				if (!assignedToId) return false;

				const roles = AccountService.getAvailableRoles();
				const assignable = [ 'agencyMemberProfile', 'agencyOwnerProfile', 'brokerProfile' ];
				for (let i = 0; i < roles.length; i++) {
					if (assignable.indexOf(roles[i].type) >= 0 && roles[i].id == assignedToId) {
						return true;
					}
				}
				return false;
			}
		};

		/** Downloads all files in the array */
		//todo task download files
		self.downloadFiles = function (id, files) {
			if (id && files) {
				const fileIds = [];
				for (let i = 0; i < files.length; i++) {
					fileIds.push(files[i].id);
				}
				const baseUrl = $rootScope.baseURL;
				const params = {
					access_token: AccountService.getAccessToken(),
					fileIds: fileIds.toString()
				};

				//Add authentication headers in URL
				const apiURL = baseUrl + '/Tasks/' + id + '/downloadFiles';
				const url = [apiURL, $.param(params)].join('?');
				$window.open(url);
			}
		}

		self.downloadCommentFiles = function(id, commentId, files) {
			if (id && files && commentId) {
				const fileIds = files.reduce((arr,file) => {
					arr.push(file.id);
					return arr;
				}, []);

				const baseUrl = $rootScope.baseURL;
				const params = {
					access_token: AccountService.getAccessToken(),
					fileIds: fileIds.toString()
				};

				//Add authentication headers in URL
				const apiURL = baseUrl + '/Tasks/' + id + '/comments/' + commentId + '/downloadFiles';
				const url = [apiURL, $.param(params)].join('?');
				$window.open(url);
			}
		}

		self.deleteFile = function (file) {
			// File has been linked to task already
			if (file.taskId && file.id) {
				return Task.files.destroyById({ id: file.taskId, fk: file.id }).$promise;
			}
			// File was just uploaded to container -> only need to remove it there
			else {
				return Container.removeFile({ container: 'task', file: file.name }).$promise;
			}
		}

		self.exportIncompleteTasks = function(filters, dropdowns, sort) {
			filters = filters || {};
			sort = sort || {};

			const _filters = {
				assignedToId: (filters.assignedTo || {}).value || false,
				aboutId: (filters.about || {}).value || false,
				createdMin: (filters.createdMin || {}).value || false,
				createdMax: (filters.createdMax || {}).value || false,
				dueMin: (filters.dueMin || {}).value || false,
				dueMax: (filters.dueMax || {}).value || false,
				sort: []
			};

			// Get sort filters
			Object.entries(sort)
			.sort((a, b) => b[1].weight - a[1].weight)  // Sort by weight, descending
			.forEach((f) => {                      // Add each filter to the sort filter
				if (f[0] && f[1]) {
					_filters.sort.push([ f[0], f[1].desc ]);
				}
			});

			if (_filters.aboutId) {
				const about = ((dropdowns || {}).about || []).find(x => x.id == _filters.aboutId);
				if (!!about) {
					_filters.aboutType = about.type;
					_filters.aboutLabel = about.label;
				}
			}

			if (_filters.assignedToId) {
				const assigned = ((dropdowns || {}).assignedTo || []).find(x => x.id == _filters.assignedToId);
				if (!!assigned) {
					_filters.assignee = assigned.label;
				}
			}

			return SbUser.exportIncompleteTasks({ id: AccountService.getUserId(), filters: _filters, lang: $rootScope.language }).$promise;
		}

		self.getTaskName = function (id) {
		  	return Task.findById({ id: id }).$promise;
		}

		self.getIncompleteTasks = function() {
		  	return SbUser.getIncompleteTasks({ id: AccountService.getUserId() }).$promise;
		}

	}); // End of controller
})();
