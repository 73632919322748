/// <reference path="../../node_modules/@types/angular/index.d.ts" />

/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/**
 * © copyright 2017-2018, 2818779 Canada Inc.
 * 
 * Use of this software is only permitted if you have entered into a
 * license agreement with 2818779 Canada Inc. and is subject to the terms
 * and conditions of such license agreement.
 *
 */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr', [
		'ui.router',								// Routes
		'angularMoment',							// AngularJS wrapper for moment.js.
		'browser-information',						// Gets browser type and device being used
		'loading-overlay',							// Loading overlay
		'ui.bootstrap',								// Bootstrap
		'ngAnimate',								// Animations
		'ngResource', 								// ngResource - required for REST calls
		'rzModule',									// Sliders (Dashboard)
		'angularUtils.directives.dirPagination',	// Pagination inside tabs
		'lbServices',								// Loopback
		'ngSanitize',								// Sanitizer
		'ngFileSaver',								// Used to export files
		'selectize',								// Styled select menus with input
		'dndLists',									// Drag and drop lists (used with bookmarks, task templates)
		'ngStorage',								// $localStorage and $sessionStorage services
		'uiCropper',								// Image cropping (used for profile photos)
		'angularFileUpload',						// Used on all file uploads
		'ui.tinymce',								// Text editor
		'images-resizer',							// Image resize (used for profile and listing photos)
		'ang-drag-drop',							// Angular Drag and Drop - used in listing photos page
		'angular.filter',							// Utilities functions
		'angular-cache',							// CacheFactory - used for caching codes, adv. search and cities
		'ngImageAppear',							// Adds a loading layer to photos (ng-image-appear directive)
		'templates',								// Used to create templates of html files
		'hmTouchEvents',							// Used for touch support - menu swipe left/right
		'pascalprecht.translate',					// Translate module
		'duScroll',									// Angular Scroll - used to add animated scrolling
		'color.picker',								// Color picker directive - for Wordpress
		'infinite-scroll',							// Infinite scroll module
		'smartbrokr.account',
		'smartbrokr.admin',
		'smartbrokr.agency',
		'smartbrokr.alerts',
		'smartbrokr.bookmark',
		'smartbrokr.broker',
		'smartbrokr.buyer',
		'smartbrokr.cropper',
		'smartbrokr.dashboard',
		'smartbrokr.directives',
		'smartbrokr.docusign',
		'smartbrokr.file',
		'smartbrokr.filters',
		'smartbrokr.globalVars',
		'smartbrokr.help',
		'smartbrokr.importCsv',
		'smartbrokr.languages',
		'smartbrokr.listing',
		'smartbrokr.location',
		'smartbrokr.manager',
		'smartbrokr.menu',
		'smartbrokr.messages',
		'smartbrokr.modal',
		'smartbrokr.navigation',
		'smartbrokr.owner',
		'smartbrokr.payment',
		'smartbrokr.property',
		'smartbrokr.public',
		'smartbrokr.quickbooks',
		'smartbrokr.role',
		'smartbrokr.reports',
		'smartbrokr.resources',
		'smartbrokr.seller',
		'smartbrokr.settings',
		'smartbrokr.sorter',
		'smartbrokr.static',
		'smartbrokr.storage',
		'smartbrokr.supplier',
		'smartbrokr.task',
		'smartbrokr.upload',
		'smartbrokr.user',
		'smartbrokr.visit',
		'smartbrokr.wordpress',
		'smartbrokr.citySelector',
		'smartbrokr.importCsv'
	])

	.constant('Configuration', {
		title: 'SmartBrokr',
		description: 'SmartBrokr',
		images: {
			favicon: 'favicon.png'
		}
	})

	.config((LoopBackResourceProvider, $locationProvider, CacheFactoryProvider) => {
		LoopBackResourceProvider.setAuthHeader('X-Access-Token');
		$locationProvider.html5Mode(true);
		angular.extend(CacheFactoryProvider.defaults, { maxAge: 15 * 60 * 1000 });
	})

	.config(($translateProvider, $translatePartialLoaderProvider) => {
		$translateProvider
		.useLoader('$translatePartialLoader', {
			urlTemplate: '../js/langs/{part}-{lang}.json',
			loadFailureHandler: 'LanguageErrorHandler'
		})
		.useSanitizeValueStrategy('sce')
		.usePostCompiling(true)
		.useLoaderCache('$templateCache')
		.forceAsyncReload(true)
		.fallbackLanguage('en')
		.preferredLanguage('en')
		//.useMissingTranslationHandlerLog();

		$translatePartialLoaderProvider.addPart('main');
	})
	.config(($httpProvider) => {
		$httpProvider.interceptors.push(($q, $location, $rootScope) => {
			return {
				responseError: function (rejection) {
					console.log('rejection status', rejection.status);
					if (rejection.status == 401) $location.path('/error/401');
					if (rejection.status > 0) $rootScope.$emit('rejection', rejection);
					return $q.reject(rejection);
				}
			};
		});
	})
	.config(($provide) => {
		$provide.decorator('$q', ($delegate) => {
			//Helper method copied from q.js.
			const isPromiseLike = function (obj) { return obj && angular.isFunction(obj.then); }

			/*
				 * @description Execute a collection of tasks serially.  A task is a function that returns a promise
				 *
				 * @param {Array.<Function>|Object.<Function>} tasks An array or hash of tasks.  A tasks is a function
				 *   that returns a promise.  You can also provide a collection of objects with a success tasks, failure task, and/or notify function
				 * @returns {Promise} Returns a single promise that will be resolved or rejected when the last task
				 *   has been resolved or rejected.
				 */
			function serial(tasks) {
				//Fake a "previous task" for our initial iteration
				let prevPromise;
				const error = new Error();
				angular.forEach(tasks, (task, key) => {
					const success = task.success || task;
					const fail = task.fail;
					const notify = task.notify;
					let nextPromise;

					//First task
					if (!prevPromise) {
						nextPromise = success();
						if (!isPromiseLike(nextPromise)) {
							error.message = 'Task ' + key + ' did not return a promise.';
							throw error;
						}
					} else {
						//Wait until the previous promise has resolved or rejected to execute the next task
						nextPromise = prevPromise.then(
					  /*success*/(data) => {
								if (!success) { return data; }
								const ret = success(data);
								if (!isPromiseLike(ret)) {
									error.message = 'Task ' + key + ' did not return a promise.';
									throw error;
								}
								return ret;
							},
					  /*failure*/(reason) => {
								if (!fail) { return $delegate.reject(reason); }
								const ret = fail(reason);
								if (!isPromiseLike(ret)) {
									error.message = 'Fail for task ' + key + ' did not return a promise.';
									throw error;
								}
								return ret;
							},
							notify);
					}
					prevPromise = nextPromise;
				});

				return prevPromise || $delegate.when();
			}

			$delegate.serial = serial;
			return $delegate;
		})

		// Extend uibDatepicker directive to include calendarController and getEvents function
		$provide.decorator('uibDatepickerDirective', ($delegate) => {
			let directive, link;

			directive = $delegate[0];
			link = directive.link;

			directive.compile = function () {
				return function Link(scope, element, attrs, ctrls) {
					if (scope.$parent.calendarController) {
						scope.calendarController = scope.$parent.calendarController;

						if (scope.$parent.$parent.smartbrokrController) {
							scope.smartbrokrController = scope.$parent.$parent.smartbrokrController;
						}
					}
					scope.getEvents = scope.$parent.$eval(attrs.getEvents);
					return link.apply(this, arguments);
				}
			}
			return $delegate;
		})

		// Extend uibDayPicker directive to add events to day object
		$provide.decorator('uibDaypickerDirective', ($delegate) => {
			let directive, link;

			directive = $delegate[0];
			link = directive.link;

			directive.compile = function () {
				return function Link(scope, element, attrs, ctrls) {

					if (scope.$parent.calendarController) {
						scope.calendarController = scope.$parent.calendarController;

						if (scope.$parent.$parent.smartbrokrController) {
							scope.smartbrokrController = scope.$parent.$parent.smartbrokrController;
						}
					}

					// Only applies if we have a function to get events
					if (scope.$parent.getEvents) {
						scope.$watch('rows', () => {		// After 'rows' is populated, add events to its objects
							let i, j, rowsLength, innerLength;
							rowsLength = (scope.rows || []).length || 0;

							scope.calendarController.getAll(scope.rows).then((events) => {
								for (i = 0; i < rowsLength; i++) {
									const row = scope.rows[i];

									innerLength = (row || []).length;

									for (j = 0; j < innerLength; j++) {
										row[j].placement = '';

										if (j === 0) {
											row[j].placement += 'right';
										}
										else {
											row[j].placement += 'left';
										}

										if (i === 5) {
											row[j].placement += '-bottom';
										}
										else if (i === 0) {
											row[j].placement += '-top';
										}

										row[j].events = scope.$parent.getEvents(row[j].date, events);
									}
								}
							});
						})

						scope.$on('update', (e, args) => {
							scope.datepicker.refreshView();
						})
					}
					return link.apply(this, arguments);
				}
			}
			return $delegate;
		})

		// Extend Selectize directive to fix issue with selected option not clearing when options change
		$provide.decorator('selectizeDirective', ($delegate) => {
			let directive, link;

			directive = $delegate[0];
			link = directive.link;

			directive.compile = function () {
				return function Link(scope, element, attrs, ctrls) {

					const w = scope.$watch('options', () => {
						const value = ctrls.$modelValue;
						const selectize = element[0].selectize;

						if (!value) {
							selectize.clear();
						}

						// Original logic from selectize watcher
						scope.disableOnChange = true;
						selectize.clearOptions();
						selectize.addOption(scope.options);
						selectize.setValue(scope.ngModel);

						// Added lines to clear cache and refresh options
						selectize.clearCache();
						selectize.refreshOptions(false);

						scope.disableOnChange = false;
					})

					scope.$on('destroy', () => {
						w();
					})

					return link.apply(this, arguments);
				}
			}
			return $delegate;
		})

		// Extend typeahead to always use our popup template and show 'No results' message
		$provide.decorator('uibTypeaheadDirective', ($delegate) => {
			let directive, link;

			directive = $delegate[0];
			link = directive.link;

			directive.compile = function (tElement, tAttrs) {
				const model = tAttrs.ngModel;
				const noResultsVar = '_noResults_' + model;
				tAttrs.$set('typeaheadNoResults', noResultsVar);	// Set default variable for 'noResults'
				tAttrs.$set('typeaheadPopupTemplateUrl', '/js/src/templates/typeahead/popup.html');	// Always use our template

				return function Link(scope, element, attrs, ctrls) {

					// Popup scope sends event to reset 'noResults' variable
					const w1 = scope.$on('typeaheaResetIsNoResults', (ev, data) => {
						if ((data || {}).noResultsVar) {
							scope[data.noResultsVar] = false;
						}
					})

					// Broadcast 'noResults' to popup scope
					const w2 = scope.$watch(noResultsVar, (isNoResults) => {
						const isEditable = scope.$eval(attrs.typeaheadEditable);
						if (!isEditable || attrs.typeaheadEditable === 'false') {	// Only show for non-editable typeahead.
							scope.$broadcast('typeaheadNoResults', { noResults: isNoResults, noResultsVar: noResultsVar, popup: element.attr('aria-owns') });
						}
						else {
							w1(); w2();	// Remove listeners if typeahead is editable
						}
					})

					return link.apply(this, arguments);
				}
			}
			return $delegate;
		})

		// Extended typeahead popup directive to show message when there are no results
		$provide.decorator('uibTypeaheadPopupDirective', ($delegate, $timeout) => {
			let directive, link;

			directive = $delegate[0];
			link = directive.link;

			directive.compile = function (tElement, tAttrs) {
				return function Link(scope, element, attrs, ctrls) {

					// Receive 'noResults' variable through event from parent scope
					scope.$on('typeaheadNoResults', (ev, data) => {
						if (data.popup === tAttrs.id) {
							scope.isNoResults = data.noResults;
							scope.noResultsVar = data.noResultsVar;
						}
					})

					// If 'noResults' variable is set to true, reset it after 2s and broadcast it
					scope.$watch('isNoResults', (isNoResults) => {
						if (isNoResults) {
							$timeout(() => {
								scope.isNoResults = false;
								scope.$emit('typeaheaResetIsNoResults', { noResultsVar: scope.noResultsVar });
							}, 2000);
						}
					})

					return link.apply(this, arguments);
				}
			}
			return $delegate;
		})

		// Add confirm message when closing modals that have changes to get checked ('are you sure you want to close?')
		$provide.decorator('$uibModalStack', ($delegate, $rootScope) => {
			const oldDismiss = $delegate.dismiss;
			$delegate.dismiss = function(modalInstance, reason) {
				if (modalInstance.scope && modalInstance.check) {
					const listener = modalInstance.scope.$on('modal.closing', (event, reason) => {
						if ((reason === 'backdrop click' || reason === 'cancel') && !modalInstance.check.isConfirmed) {
							$rootScope.checkClosingModal(event, modalInstance.check);
						}
						listener();	// Destroy listener after using it once
					})
				}
				return oldDismiss.apply(this, arguments);
			}

			return $delegate;
		})

		// Default color picker options
		$provide.decorator('ColorPickerOptions', ($delegate) => {
			const options = angular.copy($delegate);
			options.round = false;
			options.alpha = false;
			options.format = 'hexString';
			options.inputClass = 'form-control';
			options.swatchBootstrap = false;
			options.case = 'lower';
			return options;
		});
	})

	.config(($urlRouterProvider, $stateProvider, stateConfig) => {
		stateConfig($urlRouterProvider, $stateProvider);
	})

	.factory('socket', ($rootScope, AccountService) => {
		const socket = io.connect({
			query: {
				userId: AccountService.getUserId()
			}
		});

		// When the SbUserId changes, we disconnect, update the socket, then connect again with a new userId
		$rootScope.$watch(() => AccountService.getUserId(), (neu, old) => {
			if (neu !== old || !neu) {
				socket.disconnect(true);

				if (!!neu) {
					socket.io.opts.query = {
						userId: neu
					}
					socket.open();
				}
			}
		})

		return {
			on: function (eventName, callback) {
				socket.on(eventName, function () {
					const args = arguments;
					$rootScope.$apply(() => {
						callback.apply(socket, args);
					});
				});
			},
			emit: function (eventName, data, callback) {
				socket.emit(eventName, data, function () {
					const args = arguments;
					$rootScope.$apply(() => {
						if (callback) callback.apply(socket, args);
					});
				})
			}
		};
	})

	.run((StaticService) => {
		const cacheDestroyDate = localStorage.getItem('cache-destroy-date');
		const currentDate =  moment(new Date());
		const newDate = moment(new Date()).add(1, 'days');
		if(!cacheDestroyDate) {
			localStorage.setItem('cache-destroy-date', newDate.valueOf());
		} else {
			if(cacheDestroyDate <= currentDate.valueOf()) {
				StaticService.clearCache();
				localStorage.setItem('cache-destroy-date', newDate.valueOf());
			}
		}
		StaticService.init();
	})
	.run(($filter, $rootScope, $state, $timeout, $uibModalStack, AccountService, AlertService, FileService, GlobalVars, LanguageService, MenuService, ModalService, NavService, StaticService, StorageService) => {

		// HammerJS disables user select by default. Delete this behaviour. See: http://hammerjs.github.io/tips/
		delete Hammer.defaults.cssProps.userSelect;

		$rootScope.widePopup = false;
		$rootScope.loggedIn = AccountService.loggedIn();
		$rootScope.limitedAccess = AccountService.limitedAccess();
		$rootScope.changeLanguage = changeLanguage;
		$rootScope.TRANSLATABLE = [ 'EN', 'FR' ];		// Languages that can be translated

		if ($rootScope.loggedIn) {
			AccountService.getAccountNow();
		}

		changeLanguage();

		function changeLanguage() {
			$rootScope.language = StorageService.getLanguage();

			if ($rootScope.TRANSLATABLE.indexOf($rootScope.language) < 0) {
				StorageService.setLanguage('EN');
				return changeLanguage();
			}

			LanguageService.changeLanguage($rootScope.language.toLowerCase()).then((data) => {
				AlertService.reload();
				GlobalVars.initCodes();
			})
		}

		$rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
			const check = $state.$current.locals.globals.check || {};
			if (check.isDirty && !check.isSaved) {
				if (check.isDirty()) {
					event.preventDefault();

					const modal = ModalService.prompt('ALERT_MESSAGES.ALERT.CHANGES_NOT_SAVED',
						'COMMON.ARE_YOU_SURE',
						'FORMS.CANCEL', 'Ok', null, true
					);

					modal.result.then((res) => {
						check.isSaved = true;
						$state.go(toState, toParams);
					},
					() => {})
					.catch((err) => {})
				}
				else {
					cont();
				}
			}
			else {
				cont();
			}

			function cont() {
				// Leaving signup
				if (fromState.name.includes('signup') && !toState.name.includes('signup')) {
					AccountService.clear();
				}
				// Starting signup
				else if (toState.name.includes('signup') && !fromState.name.includes('signup')) {
					AccountService.clearUser();
					AccountService.clear();
				}
				else if (fromState.name === 'main.tasks') {
					NavService.popStack(true);
				}

				if (toState.params && toState.params.public) {
					$rootScope.widePopup = true;
				}
				else {
					$rootScope.widePopup = false;
				}

				const currentRole = AccountService.getRole(true);

				if (!AccountService.loggedIn() && (!toState.params || !toState.params.public)) {
					event.preventDefault();
					$state.go('main.login');
					return;
				}
				else if (!!toState.params && !!toState.params.whitelist) {
					if (toState.params.whitelist.indexOf(currentRole) < 0) {
						event.preventDefault();
						$state.go('main.error', { menu: null, type: '', error: 'Your role [' + $filter('role')(currentRole) + '] does not have access to that page.', code: 403 });
						return;
					}
					else {
						NavService.getStack(angular.copy(fromState), angular.copy(toState), angular.copy(toParams));
					}
				}
				else if (toState.name != 'main.login' && !toState.name.includes('public') && !toState.name.includes('main.signup') && toState.name !== 'main.redirect') {
					NavService.getStack(angular.copy(fromState), angular.copy(toState), angular.copy(toParams));
				}

				StaticService.init();
			}
		})

		$rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
			$uibModalStack.dismissAll('state-change');
			$rootScope.wide = (toState.data || {}).wide || false;

			const role = AccountService.getRole();

			if (role === 'sellerProfile') return;

			if (toState.name.includes('public')) {
				$timeout(() => {
					const anchor = document.getElementById('top');
					const container = angular.element(document.getElementById('root'));

					container.scrollToElement(anchor, 200, 10);
					  }, 100)

				return;
			}

			if (!(toParams || {}).public) {
				self.showMenu = false;
				self.navStack = NavService.navStack;

				if (!$state.params.menu) {
					if (($state.params.role || '').toLowerCase() === 'buyer') {
						$state.params.menu = MenuService.getBuyerIndex();
					}
					else if (($state.params.role || '').toLowerCase() === 'seller') {
						$state.params.menu = MenuService.getSellerIndex();
					}
				}
			}
		});

		$rootScope.$on('updateAccount', (event, data) => {
			AccountService.getAccountNow();
			AccountService.updateManaging();
			changeLanguage();
		});

		$rootScope.setBaseURL = function (url) {
			$rootScope.baseURL = url;
		}

		$rootScope.download = FileService.download;
		$rootScope.prompt 	= ModalService.prompt;
	});
})();
