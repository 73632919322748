(function() {

	'use strict';

	angular.module('smartbrokr')

	.controller('EmailTemplateModalController', function($filter, $log, $rootScope,$scope,$timeout,$uibModalInstance,AlertService, GlobalVars,ResourceService,template,newTemplate,UploadService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.newTemplate    = null;   // Template being edited

		$scope.codeInputs   = null;   // Possible code inputs for email templates
		self.codePopover    = null;   // Popover template for code inputs

		self.editorConfig   = null;   // TinyMCE options (text editor)
		const imageList       = [];     // List of images to use with text editor (from Resources/Marketing Images)

		self.uploader       = null;   // Used to upload files to the template
		let innerUploader   = null;   // Used to upload images that will be embedded in the template content


		// SETUP VARIABLES ======================================================================================================================

		self.newTemplate  = template;
		self.isNew = newTemplate;

		self.codePopover = GlobalVars.popover('codeInputs');

		$scope.codeInputs = [
			{ code: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.FULL_NAME_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.FULL_NAME_SUB') },
			{ code: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.FIRST_NAME_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.FIRST_NAME_SUB') },
			{ code: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.LAST_NAME_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.LAST_NAME_SUB') },
			{ code: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.COMPANY_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.COMPANY_SUB') },
			{ code:  $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.SALUTATION_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.SALUTATION_SUB') },
			{ code: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.ADDRESS_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.ADDRESS_SUB') },
			{ code: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.TIME_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.TIME_SUB') },
			{ code: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.LISTING_URL_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.LISTING_URL_SUB') },
			{ code: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.EXPIRY_DATE_CODE'), description: $filter('translate')('EMAIL.CODE_INPUTS_POPOVER.EXPIRY_DATE_SUB') }
		];

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.newTemplate),
			clone: self.newTemplate
		}

		_setupUploader();
		_setupEditor();

		// FUNCTIONS ============================================================================================================================

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		self.save = function() {
			const template = self.newTemplate;

			template.attachments = (template.files || []).reduce((arr, file) => {
				arr.push({ path: file.url });
				return arr;
			}, []);

			if ((template.fileIds || []).length !== (template.files || []).length) {
				template.fileIds = (template.files || []).reduce((arr, file) => {
					arr.push(file.id);
					return arr;
				}, []);
			}

			ResourceService.saveEmailTemplate(template).then((res) => {
				AlertService.success('RESOURCES.TEMPLATE','save');
				$uibModalInstance.close(res);
			})
			.catch((err) => {})
		};

		$scope.importVar = function(entry) {
			const position = tinymce.activeEditor.selection.getSel().anchorOffset;
			const node = tinymce.activeEditor.selection.getNode();
			const text = node.textContent;

			node.textContent = text.substring(0,position) + entry + text.substring(position);
			tinymce.activeEditor.focus();
		};

		/** Deletes a file from the task (before sending it)
      * @param i: int (index of the file to be deleted)
      */
		self.deleteFile = function(i) {

			if (!!self.newTemplate.id) {
				self.newTemplate.files.splice(i, 1);
				self.newTemplate.fileIds.splice(i, 1);
			}
			else {
				ResourceService.deleteFile(self.newTemplate.files[i]).then((res) => {
					self.newTemplate.files.splice(i, 1);
					self.newTemplate.fileIds.splice(i, 1);
				})
			}
		}

		// Adds listener to adjust popover on window resize
		const deRegister = $rootScope.$on('resize-window', () => {
			if (self.isCodeInputsOpen) {
				self.isCodeInputsOpen = false;    // Closes input popover on window resize
			}
		})

		$scope.$on('modal.closing', () => {
			if (!!deRegister) {
				deRegister();
			}
		});

		self.checkCodeInputs = function() {
			$timeout(() => {
				const codeInputs = $('.popover.right.in').first();

				if (codeInputs.length > 0) {
					const initialOffset = codeInputs.offset();

					if (initialOffset.top < 0) {

						let curr = angular.copy(codeInputs);
						let control = 0;
						let totalOffset = 0;

						while (true && control < 500) {
							const parent = curr.offsetParent();

							if (parent[0].offsetTop == 0) {
								break;
							}
							else {
								totalOffset += parent[0].offsetTop;
								curr = parent;
							}

							control += 1;
						}

						const newOffset = totalOffset + 11 - initialOffset.top;

						$('.popover.right.in').css('margin-top', newOffset + 'px');

						// Adjust arrow height
						$('.arrow').css('margin-top', '-' + (newOffset + 11) + 'px');

					}
				}
			},50)
		}

		function _setupEditor() {
			const plugins = 'advlist anchor autolink charmap directionality help hr image imagetools link lists preview print searchreplace table toc textcolor textpattern visualblocks visualchars wordcount';
			const toolbar = 'formatselect | bold italic strikethrough forecolor backcolor | link image | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat';
			innerUploader = UploadService.initEmailTemplateUploader(true, self.newTemplate.id || 'TEMP');

			const config = GlobalVars.createEditorConfig(false, false, plugins, toolbar);
			config.menubar = 'file edit view insert format table tools help';
			config.min_height = 375;

			config.image_list = function(success) {
				ResourceService.getMarketingImages().then((res) => {
					const ret = res.reduce((arr, folder) => {
						folder.files.reduce((imgArr, img) => {
							arr.push({ title: img.originalFilename, value: img.url });
						}, []);
						return arr;
					}, []);
					success(ret);
				});
			};

			config.images_upload_handler = function (blobInfo, success, failure) {
				innerUploader.onSuccessItem = function (fileItem, response, status, headers) {
					if (!self.newTemplate.innerImages) {
						self.newTemplate.innerImages = [];
					}

					if (response.url) {
						self.newTemplate.innerImages.push({
							id: response.id,
							url: response.url
						});

						success(response.url);
					}
					else {
						failure('FAILED');
					}
				}

				innerUploader.onErrorItem = function (fileItem, response, status, headers) {
					failure(status);
				}

				innerUploader.addToQueue(blobInfo.blob());
				innerUploader.uploadAll();
			};

			self.editorConfig = config;
		}

		function _setupUploader() {
			self.uploader = UploadService.initEmailTemplateUploader(false, self.newTemplate.id || 'TEMP');

			self.uploader.onSuccessItem = function (fileItem, response, status, headers) {
				if (!self.newTemplate.files) {
					self.newTemplate.files = [];
				}

				if (!self.newTemplate.fileIds) {
					self.newTemplate.fileIds = [];
				}

				self.newTemplate.files.push(response);
				self.newTemplate.fileIds.push(response.id);
			}
		}

	}) // End of controller
})(); // End of function()
