/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
(function () {

	'use strict';

	angular.module('smartbrokr.resources', [])

	.service('ResourceService', function (AccountService, AlertService, ModalService, Folder, SbUser, TaskTemplate, Container) {
		const self = this;

		//Resources
		self.getMarketingImages = function () {
			return SbUser.folders({ id: AccountService.getUserId(), filter: { where: { type: 'marketing-images' }, order:'name ASC', include: ['files'] } }).$promise;
		}

		self.getImagesForVideo = function () {
			AlertService.loading();
			return SbUser.folders({ id: AccountService.getUserId(), filter: { where: { type: 'images-for-video' }, order:'name ASC', include: ['files']  } }).$promise;
		}

		self.getForms = function () {
			return SbUser.files({ id: AccountService.getUserId(), filter: { where: { bucket: 'forms' },order:'created DESC'  } }).$promise;
		}

		self.deleteResource = function (fk) {
			const delFunction = function() {
				return SbUser.files.destroyById({ id: AccountService.getUserId(), fk: fk }).$promise;
			}
			return ModalService.confirmDelete(delFunction, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
		}

		// Deletes a file -> Used with email templates
		self.deleteFile = function (file) {

			if (!file.id) {
				//To Do: Won't have access to container
				return Container.removeFile({ container: 'user', file: file.name }).$promise;
			}
			else {
				return SbUser.files.destroyById({ id: AccountService.getUserId(), fk: file.id }).$promise;
			}
		}

		/* MEDIA MANAGER ------------------------------------------------------------------------------------------------- */
		self.getMediaManager = function() {
			return SbUser.files({ id: AccountService.getUserId(), filter: { where: { bucket: 'media-manager' }, order: 'created DESC' } })
			.$promise
			.then((res) => {
				const images = [];
				const videos = [];
				res = res || [];

				res.forEach((file) => {
					if (((file || {}).type || '').includes('mp4')) {
						videos.push(file);
					}
					else {
						images.push(file);
					}
				})

				return { images: images, videos: videos };
			})
		}

		self.getMediaManagerFolders = function() {
			return SbUser.folders(
				{
					id: AccountService.getUserId(),
					filter: {
						where: {
							type: 'media-manager'
						},
						include: 'files',
						order: 'name ASC'
					}
				}
			)
			.$promise
			.then((res) => {
				return res;
			});
		}

		self.createMediaManagerFolder = function(name) {
			return SbUser.folders.create({ id: AccountService.getUserId() }, { type: 'media-manager', name: name }).$promise;
		}

		self.createMediaManagerVideo = function(options, listingId = null) {

			options = options || {};
			const name = 'media_manager_' + Date.now() + '.mp4';
			options.name = name;

			if (options.originalName) {
				options.originalName = options.originalName.replace(/\./g, '').split(' ').join('-') + '.mp4';
			}

			if ((options.first || {}).id) {
				options.images.unshift(options.first);
			}

			if ((options.last || {}).id) {
				options.images.push(options.last);
			}

			if(listingId) {
				options.listingId = listingId;
			}

			delete options.first;
			delete options.last;

			SbUser.generateVideo({ id: AccountService.getUserId() }, options).$promise
			.then((res) => {
				AlertService.startVideo(name);
			});
		}


		/* EMAIL TEMPLATES ----------------------------------------------------------------------------------------------- */

		/** Get all Email Templates */
		self.getEmailTemplates = function () {
			return SbUser.emailTemplates({ id: AccountService.getUserId(), filter: { include: 'files' } }).$promise;
		}

		self.getAllEmailTemplates = function() {
			return SbUser.getAllEmailTemplates({ id: AccountService.getUserId(), filter: { include: 'files' } }).$promise;
		}

		/** Get Shared Email Templates */
		self.getSharedEmailTemplates = function() {
			return SbUser.getSharedEmailTemplates({ id: AccountService.getUserId(), filter: { include: 'files' } }).$promise;
		}

		/** Get one Email Template by ID */
		self.getEmailTemplate = function (templateId) {
			return SbUser.emailTemplates.findById({ id:AccountService.getUserId(),fk: templateId }).$promise;
		}

		/** Update/Create Email Template */
		self.saveEmailTemplate = function (template) {
			if (template.id) {
				return SbUser.emailTemplates.updateById({ id: AccountService.getUserId(), fk: template.id }, template).$promise;
			}
			else {
				return SbUser.emailTemplates.create({ id: AccountService.getUserId() }, template).$promise;
			}
		}

		/** Delete Email Template by ID */
		self.deleteEmailTemplate = function (templateId) {

			const del = function() {
				return SbUser.emailTemplates.destroyById({ id: AccountService.getUserId(), fk: templateId }).$promise;
			}

			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_TEMPLATE');
		}

		/* TASK TEMPLATES ------------------------------------------------------------------------------------------------ */

		/** Get all Task Templates */
		self.getTaskTemplates = function (type) {
			const filter = {
				where: {},
				include: {
					relation: 'taskList',
					scope: {
						include: {
							relation: 'assignedTo',
							scope: {
								fields: [ 'id', 'sbUserId' ],
								include: {
									relation: 'user',
									scope: {
										fields: [ 'id', 'fullName' ]
									}
								}
							}
						}
					}
				},
				order: 'created DESC'
			}

			if (type) {
				switch(type) {
				case 'ROLES.BROKER': filter.where.isPersonal = true; break;
				case 'ROLES.BUYER': filter.where.isBuyer = true; break;
				case 'ROLES.SELLER': filter.where.isSeller = true; break;
				case 'LISTING.LISTING': filter.where.isListing = true; break;
				case 'ROLES.SUPPLIER': filter.where.isSupplier = true; break;
				}
			}
			return SbUser.taskTemplates({ id: AccountService.getUserId(), filter: filter }).$promise;
		}

		/** Get all tasks including shared tasks */
		self.getSharedTaskTemplates = function(type) {
			const filter = {
				where: {},
				include: {
					relation: 'taskList',
					scope: {
						include: {
							relation: 'assignedTo',
							scope: {
								fields: [ 'id', 'sbUserId' ],
								include: {
									relation: 'user',
									scope: {
										fields: [ 'id', 'fullName' ]
									}
								}
							}
						}
					}
				},
				order: 'created DESC'
			}

			if (type) {
				switch(type) {
				case 'ROLES.BROKER': filter.where.isPersonal = true; break;
				case 'ROLES.BUYER': filter.where.isBuyer = true; break;
				case 'ROLES.SELLER': filter.where.isSeller = true; break;
				case 'LISTING.LISTING': filter.where.isListing = true; break;
				case 'ROLES.SUPPLIER': filter.where.isSupplier = true; break;
				}
			}
			return SbUser.getSharedTaskTemplates({ id: AccountService.getUserId(), filter: filter }).$promise;
		}

		self.getTaskTemplates = function(type) {
			const filter = {
				where: {},
				include: {
					relation: 'taskList',
					scope: {
						include: {
							relation: 'assignedTo',
							scope: {
								fields: [ 'id', 'sbUserId' ],
								include: {
									relation: 'user',
									scope: {
										fields: [ 'id', 'fullName' ]
									}
								}
							}
						}
					}
				},
				order: 'created DESC'
			}

			if (type) {
				switch(type) {
				case 'ROLES.BROKER': filter.where.isPersonal = true; break;
				case 'ROLES.BUYER': filter.where.isBuyer = true; break;
				case 'ROLES.SELLER': filter.where.isSeller = true; break;
				case 'LISTING.LISTING': filter.where.isListing = true; break;
				case 'ROLES.SUPPLIER': filter.where.isSupplier = true; break;
				}
			}
			return SbUser.getTaskTemplates({ id: AccountService.getUserId(), filter: filter }).$promise;
		}

		/** Get one Task Template by ID */
		self.getTaskTemplate = function (templateId) {
			const filter = {
				include: {
					taskList: [
						{ assignedTo: 'user' }
					]
				}
			}

			return SbUser.getTaskTemplate({ id: AccountService.getUserId(), fk: templateId, filter: filter }).$promise;
		}

		/** Saves all the task models embedded in a task template */
		self.saveTaskModel = function (templateId, taskModel) {

			if (!!taskModel.assignedTo) {
				taskModel.assignedToId = taskModel.assignedTo.id;
				delete taskModel.assignedTo;
			}

			if (taskModel.id) {
				return TaskTemplate.taskList.updateById({ id: templateId, fk: taskModel.id }, taskModel).$promise;
			}
			else {
				return TaskTemplate.taskList.create({ id: templateId }, taskModel).$promise;
			}
		}

		/** Update/Create Task Template */
		self.saveTaskTemplate = function (template) {
			if (template.id) {
				return SbUser.updateTaskTemplate({ id: AccountService.getUserId(), fk: template.id }, { data: template }).$promise;
			}
			else {
				return SbUser.taskTemplates.create({ id: AccountService.getUserId() }, template).$promise;
			}
		}

		/** Delete Task Template by ID */
		self.deleteTaskTemplate = function (templateId) {
			const del = function() {
				return SbUser.taskTemplates.destroyById({ id: AccountService.getUserId(), fk: templateId }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_TEMPLATE');
		}

		self.getTaskTemplateName = function(fk) {
			return SbUser.getTaskTemplate({ id: AccountService.getUserId(), fk: fk }).$promise;
		}

		self.deleteFolder = function (fk) {
			const del = function() {
				return SbUser.folders.destroyById({ id: AccountService.getUserId(), fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FOLDER');
		}

		self.deleteDocument = function (id, fk, isGenerated,agencyFolder) {
			const del = function() {
				if (isGenerated && ! agencyFolder) {
					return SbUser.files.destroyById({ id: AccountService.getUserId(), fk: fk }).$promise;
				}
				else {
					return Folder.files.destroyById({ id: id, fk: fk }).$promise;
				}
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_FILE');
		}

		self.shareEmailTemplate = function(templateId, recipients) {
			return SbUser.shareEmailTemplate({ id: AccountService.getUserId(), fk: templateId }, { recipients: recipients }).$promise;
		}

		self.shareTaskTemplate = function(templateId, recipients) {
			return SbUser.shareTaskTemplate({ id: AccountService.getUserId(), fk: templateId }, { recipients: recipients }).$promise;
		}
	});

})(); // End of function()
