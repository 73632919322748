(function () {

	'use strict';

	// eslint-disable-next-line no-undef
	angular.module('smartbrokr')
	.controller('CreateVideoController', function (data, type, options, $filter, $scope, $state, $stateParams, $uibModalInstance, AlertService, FileService, GlobalVars, ListingService, ResourceService) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.data               = data;     // Images to be used in the video. Not used with listing videos.
		self.type               = type;     // The type of file that will be generated
		self.applyToAllSlides   = false;    // VIDEO: If true, the data added to the first slide is applied to all slides

		self.selected = {                   // VIDEO: Selected images to be used in the first and last slides
			first:  null,
			last:   null
		}

		self.dropdowns = {                  // Options and config for images dropdowns
			config:     null,
			folderConfig: null,
			video:      [],
		};



		self.video = {                      // VIDEO: Video options
			first:  {},
			last:   {},
			images: []
		};

		self.listings = [];
		self.selectListing = options.selectListing;
		self.listingId = options.listingId;

		self.currentFolderId = '';

		self.folders = [];
		self.currentFolder = { files: [] };

		_setup();

		// FUNCTIONS ============================================================================================================================
		self.createVideo = function () {

			if (type == 'listing') {
				ListingService.generateVideo($stateParams.id, self.video);
			}
			else {
				ResourceService.createMediaManagerVideo(self.video, self.listingId);
			}

			$uibModalInstance.close();
		}

		/**
         *  VIDEO: Updates first image to be used in the video
         */
		self.updateVideoImage = function (attr) {
			const id = self.selected[attr];
			const image = self.dropdowns.video.find(x => x.id == id);
			if (!!image && (image || {}).id !== 'xxx') {
				self.video[attr].id = image.id;
				self.video[attr].url = image.url;
				self.video[attr].name = image.originalFilename;
				self.video[attr].fileId = image.fileId || image.id;
			}
			else {
				self.video[attr].id = null;
				self.video[attr].url = null;
				self.video[attr].name = null;
				self.video[attr].fileId = null;
				self.selected[attr] = null;
			}
		}

		self.selectFolder = function(id) {
			const selectedFolder = self.folders.find((value) => {
				if(value.id === self.currentFolderId || value.id === id) return value;
			});
			if(selectedFolder) {
				self.currentFolder = JSON.parse(JSON.stringify(selectedFolder));
			} else {
				self.currentFolder = JSON.parse(JSON.stringify({ files: [] }));
			}
		}

		self.updateSlide = function(value, attr) {
			if (self.applyToAllSlides && value !== ((((self.video | {}).images || [])[0] || {})[attr])) {
				self.applyToAllSlides = false;
			}
		}

		function _setup() {
			const config = GlobalVars.createSingleConfig(true, 'id', 'originalFilename', 'originalFilename');
			config.render = {
				option: function(item, escape) {
					return ('<div class=\'item has-image\'>' +
							( item.url ? '<div class=\'photo-preview-wrap\'><img src=\'' + escape(item.url) + '\' class=\'photo-preview\'></div>' : '' ) +
							'<div class=\'name\'>' + escape(item.originalFilename) + '</div>' +
							'</div>');
				}
			};
			self.dropdowns.config = config;


			const folderConfig = GlobalVars.createSingleConfig(false, 'id', 'name', 'name', 'name', false, null, 'name');
			folderConfig.render = {
				option: function(item, escape) {
					if(!item || !item.name || !item.files) return '<div class="options"></div>';
					return ('<div class="option">' + $filter('translate')(FileService.getFolderName(escape(item.name))) + ' (' + (item.files.length) + ')</div>')
				}
			}
			self.dropdowns.folderConfig = folderConfig;

			self.listingConfig = GlobalVars.createSingleConfig(false, 'id', 'id', 'id');
			self.dropdowns.listingConfig = self.listingConfig;

			_getVideoImages();

			_getVideoFolders();

			_getListings();

			if (type == 'listing') {
				_getListingImages();
			}
			else if (!!data) {
				self.video.images = data.reduce(_getImages, []);
			}
			else {
				return $uibModalInstance.dismiss('cancel');
			}

			_watchers();
      	}

		function _getImages(arr, image) {
			arr.push({
				id: image.id,
				fileId: image.fileId || image.id,
				url: (image.file || {}).url || image.url,
				imageSecs: null,
				fadeSecs: null,
				text: ''
			});
			return arr;
		}

		function _getListingImages() {
			ListingService.getVideoImages($state.params.id).then((res) => {
				self.video.images = res.reduce(_getImages, []);
			})
			.catch(() => {})
		}

		function _getVideoFolders() {
			ResourceService.getImagesForVideo().then((res) => {
				self.folders = res;
			})
			.catch(() => {})
			.finally(AlertService.doneLoading);
		}

		/**
		 *  Retrieves all the "Images for Video" that belong to this user
		 *  and adds them to the "video" dropdown options
		 */
		function _getVideoImages() {
			AlertService.loading();
			ResourceService.getImagesForVideo().then((res) => {
				self.dropdowns.video.push({
					id: 'xxx',
					originalFilename: '--- ' + $filter('translate')('COMMON.NONE') + ' ---'
				})

				res.forEach((folder) => {
					folder.files.forEach((image) => {
						if (!image.originalFilename) {
							image.originalFilename = image.name;
						}
						self.dropdowns.video.push(image);
					});
				})
			})
			.catch(() => {})
			.finally(AlertService.doneLoading)
		}

		function _getListings() {
			ListingService.getAllListings().then((res) => {
				self.listings = res;
			});
		}

		/**
       *  Add watchers for "apply to all slides logic"
       *  The first listener is watching 'applyToAllSlides'
       *  ->  If true, a listener is added to all input fields in the first slide.
       *      The value applied to the first slide is applied to all other slides.
       *  ->  If false, all previously created listeners (if any) are deleted
       */
		function _watchers() {
			const watchers = [];

			$scope.$watch('createVideoController.applyToAllSlides', (apply) => {
				if (apply) {
					[ 'imageSecs', 'fadeSecs' ].forEach((attr) => {      // Add one listener for each of these attributes
						const w = $scope.$watch('createVideoController.video.images[0].' + attr, (val) => {
							for (let i = 1; i < self.video.images.length; i++) {          // Apply value to all other slides
								self.video.images[i][attr] = val;
							}
							if (attr == 'imageSecs') {
								self.video.first[attr] = val;
								self.video.last[attr] = val;
							}
						})
						watchers.push(w);
					})
				}
				else if (watchers.length > 0) {
					watchers.forEach((w) => {
						w();                          // Stop all previously created watchers
					})
				}
			})
		}
	});
})();
