/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('VisitsController', function (AccountService, AlertService, BuyerService, ListingService, ModalService, SellerService, SupplierService, VisitService, $filter, $rootScope, $sce, $stateParams, $state) {

		const self = this;

		// VARIABLES ============================================================================================================================
		let isAppointment;      // Changes some functions if true
		let getVisits;          // Function to get visits or appointments

		self.columns        = null;   // Table columns
		self.visits         = null;   // Visits data
		self.appointments   = null;   // Appointment data
		self.email          = null;   // Prepopulated email object (with subject)
		self.listing        = null;   // Listing
		self.smallMode = false;

		self.filters = {
			person: { weight: 1, value: '', desc: 1, id: false },
			scheduled: { weight: 1, value: 'scheduled', desc: 1, id: false },
		};

		const isManager = AccountService.isAdmin();

		self.user = isManager ? AccountService.getManaging() : AccountService.getAccount().user;

		$rootScope.$watch('screenSize', (newValue) => {
			if(newValue === 'xs' || newValue === 'sm') {
				self.smallMode = true;

			} else {
				self.smallMode = false;
			}
		});

		self.listingVisits = false;

		self.isAppointments = $state.current.name.includes('appointments');

		// FUNCTIONS ============================================================================================================================
		self.addVisit = function () {
			const visit = {
				listingId: $stateParams.id,
				location: (isAppointment ? '' : (((self.listing.property || {})._address || {}).addressString || '')),
				type: (isAppointment ? 'A' : 'V')
			};

			self.user = isManager ? AccountService.getManaging() : AccountService.getAccount().user;
			if(self.user && self.user.googleCalendarId) {
				self.googleCalendarUrl = $sce.trustAsResourceUrl('https://calendar.google.com/calendar/embed?src=' + self.user.googleCalendarId + '&ctz=America%2FNew_York');
			}

			const modal = ModalService.openModal('createVisit',
				{
					visit: function () { return visit },
					isAppointment: function () { return isAppointment },
					isGoogle: () => (self.user && self.user.googleCalendarId && self.user.isGoogleIntegrated) ? true : false,
				},
				'VisitController',
				'visitController'
			);

			modal.result.then((res) => {
				if (res.closed) {
					return
				}
				else {
					getVisits();
				}
			})
			.catch((err) => {
			});
		}

		self.deleteVisit = function(visit){
			ListingService.deleteVisit($stateParams.id, visit.id).then((res) => {
				if (res) {
					getVisits();
				}
			})
			.catch((err) => {})
		}

		self.editVisit = function (visit) {

			const modal = ModalService.openModal('createVisit', {
				visit: function () { return visit },
				isAppointment: function () { return isAppointment },
				isGoogle: () => (self.user && self.user.googleCalendarId && self.user.isGoogleIntegrated) ? true : false,
			}, 'VisitController', 'visitController');
			modal.result.then((res) => {
				if (res.closed) {
					return
				}
				else {
					getVisits();
				}
			})
			.catch((err) => {

			});
		}

		self.addNote = function(visit,add) {

			const modal = ModalService.openModal('visitNotes', {
				visit: function () { return visit },
				add: function () { return add }
			}, 'VisitNotesController', 'visitNotesController');

			modal.result.then((res) => {})
			.catch((err) => {})
			.finally(getVisits);
		}

		// SETUP VARIABLES ======================================================================================================================

		self.inject = {
			name: 'visitsController',
			inject: self
		}

		const extraColumns = [
			{
				name: 'LISTING.LISTING_NO',
				filter: 'id',
				noSearch: true,
				noSort: true,
				size: 2,
				field: function(visit) {
					if(!visit.listing) return 'N/A';
					return visit.listing.id;
				}
			},
			{
				name: 'ADDRESS.ADDRESS',
				filter: 'addressString',
				noSearch: true,
				noSort: true,
				size: 3,
				field: function(visit) {
					return (((visit.listing || {}).property || {})._address || {}).addressString || 'N/A';
				}
			},
			// {
			// 	name: 'ADDRESS.REGION',
			// 	select: true,
			// 	size: 2,
			// 	options: cityOptions,
			// 	filter: 'city',
			// 	config: locationConfig,
			// 	cardView: true,
			// 	field: function(listing) {
			// 		return (((listing.property || {})._address || {}).city || {}).name || '';
			// 	}
			// },
			// {
			// 	name: 'ADDRESS.PROVINCE_STATE',
			// 	select: true,
			// 	selectType: 'province',
			// 	options: provOptions,
			// 	filter: 'province',
			// 	size: 2,
			// 	config: locationConfig,
			// 	cardView: true,
			// 	field: function(listing) {
			// 		return (((listing.property || {})._address || {}).province || {}).id || '';
			// 	}
			// },
		];

		if ($state.current.name.includes('visits')) {
			isAppointment = false;

			self.columns = [
				{
					name: 'PERSON.PERSON',
					noSearch: false,
					filter: 'person',
					noSort: true,
					isHtml: true,
					size: 4,
					field: function(visit) {
						let ret = '';

						if (visit.users) {
							visit.users.forEach((user) => {
								ret += '<div><i class="far fa-envelope" ' +
									'ng-click="smartbrokrController.sendMessage({ id: \'' + user.id + '\'},null,visitsController.listing,visitsController.email)" ' +
									'style="margin-right: 8px" aria-hidden="true"></i>' +
									user.fullName;

								if ((user.ownerSuppliers || []).length > 0) {
									ret += '(' + user.ownedSuppliers[0].company + ')';
								}

								ret += '</div>';
							});
						}
						if(visit.coBuyers) {
							visit.coBuyers.forEach((coBuyer) => {
								ret += '<div><i class="far fa-envelope" ' +
									'ng-click="smartbrokrController.sendMessage({ id: \'' + coBuyer._coBuyer.id + '\'},null,visitsController.listing,visitsController.email)" ' +
									'style="margin-right: 8px" aria-hidden="true"></i>' +
									coBuyer._coBuyer.fullName;

								ret += '</div>';
							});
						}
						return ret;
					}
				},
				{
					name: 'ROLES.ROLE',
					noSearch: true,
					noSort: true,
					isHtml: true,
					size: 3,
					field: function(visit) {
						let ret = '';
						if (visit.users) {
							visit.users.forEach((user) => {
								ret += '<div>';
								if (user.buyerProfile) {
									ret += $filter('translate')('ROLES.BUYER');
								}
								else if (user.sellerProfile) {
									ret += $filter('translate')('ROLES.SELLER');
								}
								else if ((user.ownedSuppliers || []).length > 0) {
									ret += $filter('translate')('ROLES.SUPPLIER');
								}
								ret += '</div>';
							});
						}

						if(visit.coBuyers) {
							visit.coBuyers.forEach((coBuyer) => {
								ret += '<div>';

								ret += $filter('translate')('ROLES.CO_BUYER');

								ret += '</div>';
							})
						}

						return ret;
					}
				},
				{
					name: 'DATES.DATE',
					noSearch: true,
					noSort: false,
					filter: 'scheduled',
					size: 3,
					field: function(visit) {
						return $filter('dateFormatLong')(visit.scheduled);
					}
				},
				{
					name: 'DATES.TIME',
					noSearch: true,
					noSort: true,
					size: 2,
					field: function(visit) {
						return $filter('date')(visit.scheduled, 'shortTime');
					}
				},
				{
					name: 'COMMON.REASON',
					noSearch: true,
					noSort: true,
					class: 'clip-long',
					size: 3,
					field: function(visit) {
						return visit.reason;
					}
				},
				{
					name: 'NOTES_LOG.NOTES',
					noSearch: true,
					noSort: true,
					class: 'hover-underline',
					isClick: true,
					size: 2,
					clickFunction: function(visit,i) {
						self.addNote(visit, false);
					},
					field: function(visit) {
						return (visit.notes || []).length || 0;
					}
				},
				{
					name: '',
					noSearch: true,
					noSort: true,
					isHtml: true,
					class: 'check',
					size: 1,
					field: function (visit) {
						return '<a tabindex="0" class="btn btn-dots" ' +
										'uib-popover-template="smartbrokrController.popover(\'visitMenu\')" ' +
										'popover-placement="auto bottom" ' +
										'popover-trigger="\'focus\'"></a>';
					}
				}
			];

			if($stateParams.role == 'buyer' || $stateParams.role == 'supplier') {
				const endColumns = self.columns.splice(self.columns.length - 2, 2);
				self.columns = self.columns.concat(extraColumns);
				self.columns = self.columns.concat(endColumns);
			}

			getVisits = function () {
				AlertService.loading();
				const service = getService();
				service.getVisits($stateParams.id).then((res) => {
					self.visits = res;
				})
				.catch((err) => {})
				.finally(() => {
					AlertService.doneLoading();
				})
			}
		}
		else if ($state.current.name.includes('appointments')) {
			isAppointment = true;

			self.columns = [
				{
					name: 'PERSON.PARTICIPANTS',
					noSearch: true,
					noSort: true,
					isHtml: true,
					size: 4,
					field: function(visit) {
						let ret = '';

						if (visit.users) {
							visit.users.forEach((user) => {
								ret += '<div><i class="far fa-envelope" ' +
									'ng-click="smartbrokrController.sendMessage({ id: \'' + user.id + '\'},{ company: \'' + user.company + '\'},visitsController.listing,visitsController.email)" ' +
									'style="margin-right: 8px" aria-hidden="true"></i>' +
									user.fullName;

								if ((user.ownerSuppliers || []).length > 0) {
									ret += '(' + user.ownedSuppliers[0].company + ')';
								}

								ret += '</div>';
							})

						}
						return ret;
					}
				},
				{
					name: 'ADDRESS.LOCATION',
					noSearch: true,
					noSort: true,
					size: 3,
					field: function(visit) {
						return visit.location;
					}
				},
				{
					name: 'DATES.DATE',
					noSearch: true,
					noSort: false,
					size: 3,
					filter: 'scheduled',
					field: function(visit) {
						return $filter('dateFormatLong')(visit.scheduled);
					}
				},
				{
					name: 'DATES.TIME',
					noSearch: true,
					noSort: true,
					size: 2,
					field: function(visit) {
						return $filter('date')(visit.scheduled, 'shortTime');
					}
				},
				{
					name: 'COMMON.REASON',
					noSearch: true,
					noSort: true,
					class: 'clip-long',
					size: 3,
					field: function(visit) {
						return visit.reason;
					}
				},
				{
					name: 'NOTES_LOG.NOTES',
					noSearch: true,
					noSort: true,
					class: 'hover-underline',
					isClick: true,
					size: 2,
					clickFunction: function(visit,i) {
						self.addNote(visit, false);
					},
					field: function(visit) {
						return (visit.notes || []).length || 0;
					}
				},
				{
					name: '',
					noSearch: true,
					noSort: true,
					isHtml: true,
					class: 'check',
					size: 1,
					field: function (visit) {
						return '<a tabindex="0" class="btn btn-dots" ' +
										'uib-popover-template="smartbrokrController.popover(\'visitMenu\')" ' +
										'popover-placement="auto bottom" ' +
										'popover-trigger="\'focus\'"></a>';
					}
				}
			];

			if($stateParams.role == 'buyer' || $stateParams.role == 'supplier') {
				const endColumns = self.columns.splice(self.columns.length - 2, 2);
				self.columns = self.columns.concat(extraColumns);
				self.columns = self.columns.concat(endColumns);
			}

			getVisits = function () {
				AlertService.loading();
				const service = getService();
				service.getAppointments($stateParams.id).then((res) => {
					self.appointments = res;
				})
				.catch((err) => {})
				.finally(() => {
					AlertService.doneLoading();
				});
			}
		}
		else {
			console.error('ERROR');
		}

		getVisits();


		function getService() {
			if($stateParams.role == 'buyer') return BuyerService;
			if($stateParams.role == 'seller') return SellerService;
			if($stateParams.role == 'supplier') return SupplierService;
			return ListingService;
		}

		if($stateParams.id && $state.current.name.indexOf('listings') >= 0) {
			self.listingVisits = true;
			VisitService.getListing($stateParams.id).then((res) => {
				self.listing = res;
				self.email = {
					subject: (isAppointment ? 'Appointment' : 'Visit') + ' scheduled at ' + (((self.listing.property || {})._address || {}).addressString || ''),
					text: ''
				}
			});
		}
	});
})();
