/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr.visit', []).service('VisitService', function (Agency, AgencyMember, AgencyOwner, Broker, Buyer, Listing, AccountService, SbUser, Visit, $q, ModalService) {

		const self = this;

		let role;
		let roleApi;
		let roleLinkAndUnlink;
		let roleId;

		const setup = function() {
			role = AccountService.getRole();

			switch(role) {
			case 'agencyMemberProfile':
				roleApi = AgencyMember.appointments;
				roleId = AccountService.getAgencyMemberId();
				roleLinkAndUnlink = AgencyMember.linkAndUnlinkVisitors;
				break;
			case 'agencyOwnerProfile':
				roleApi = AgencyOwner.appointments;
				roleId = AccountService.getAgencyOwnerId();
				roleLinkAndUnlink = AgencyOwner.linkAndUnlinkVisitors;
				break;
			default:
				roleApi = Broker.visits;
				roleId = AccountService.getBrokerId();
				roleLinkAndUnlink = Broker.linkAndUnlinkVisitors;
				break;
			}
		}

		self.getListing = function(id) {
			const filter = {
				fields: [ 'id' ],
				include: {
					relation: 'property',
					scope: {
						fields: [ 'id', 'listingId', '_address' ]
					}
				}
			}

			return Listing.findById({ id: id, filter: filter }).$promise;
		}

		self.saveVisit = function(visit) {
			setup();

			if (visit.users) {
				delete visit.users;
			}

			if (visit.id) return roleApi.updateById({ id: roleId, fk: visit.id }, visit).$promise;
			return roleApi.create({ id: roleId }, visit).$promise;
		}

		self.deleteVisit = function(id, type, reason) {
			setup();
			const del = function() {
				return Visit.cancelVisit({ id: id, reason: reason }).$promise;
			}

			if (type === 'P') return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_APPOINTMENT');
			return del();
		}

		self.linkPerson = function(visitId, personId, notifyRecipients) {
			return SbUser.visits.link({ id: personId, fk: visitId }, { notifyRecipients: notifyRecipients }).$promise;
		}

		self.unlinkPerson = function(visitId, personId, notifyRecipients) {
			return SbUser.visits.unlink({ id: personId, fk: visitId }, { notifyRecipients: notifyRecipients }).$promise;
		}

		self.coLinkPerson = function(visitId, personId, notifyRecipients) {
			return Buyer.visits.link({ id: personId, fk: visitId }, { notifyRecipients: notifyRecipients }).$promise;
		}

		self.coUnLinkPerson = function(visitId, personId, notifyRecipients) {
			return Buyer.visits.unlink({ id: personId, fk: visitId }, { notifyRecipients: notifyRecipients }).$promise;
		}

		self.doLinkAndUnlink = function(visitId, toLink, toUnlink, toCoLink, toCoUnlink, notifyRecipients) {
			return roleLinkAndUnlink({ id: roleId, fk: visitId }, { toLink: toLink, toUnlink: toUnlink, toCoLink: toCoLink, toCoUnlink: toCoUnlink, notifyRecipients: notifyRecipients }).$promise;
		}

		self.linkUnlinkAndNote = function(visit, people, toLink, toUnlink, toCoLink, toUnCoLink, newNote, notifyRecipients) {
			const newPromise = function(promise) {
				return $q((resolve, reject) => {
					promise.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					})
				});
			}

			const promises = [];
			people = people || [];

			const toLinkIds = [];
			const toUnlinkIds = [];
			const toCoLinkIds = [];
			const toCoUnlinkIds = [];

			if (toLink && toLink.length > 0) {
				toLink.forEach((i) => {
					if ((people[i] || {}).id) {
						toLinkIds.push(people[i].id);
						// promises.push(newPromise(self.linkPerson(visit.id, people[i].id, notifyRecipients)));
					}
				});
			}

			if (toUnlink && toUnlink.length > 0) {
				toUnlink.forEach((item) => {
					if ((item || {}).id) {
						toUnlinkIds.push(item.id);
						// promises.push(newPromise(self.unlinkPerson(visit.id, item.id, notifyRecipients)));
					}
				});
			}

			if(toUnCoLink && toUnCoLink.length > 0) {
				toUnCoLink.forEach((item) => {
					if((item || {}).id) { // Is the buyer ID
						toCoUnlinkIds.push(item.id);
						// promises.push(self.coUnLinkPerson(visit.id, item.id, notifyRecipients));
					}
				});
			}

			if(toCoLink && toCoLink.length > 0) {
				toCoLink.forEach((i) => {
					if((people[i] || {}).buyerId) {
						toCoLinkIds.push(people[i].buyerId);
						// promises.push(self.coLinkPerson(visit.id, people[i].buyerId, notifyRecipients));
					}
				});
			}

			promises.push(self.doLinkAndUnlink(visit.id, toLinkIds, toUnlinkIds, toCoLinkIds, toCoUnlinkIds, notifyRecipients));

			if (!!newNote) {
				newNote = {
					text: newNote,
					author: AccountService.getAccount().user.fullName || AccountService.getAccount().user.firstName + ' ' + AccountService.getAccount().user.lastName,
					created: new Date()
				}

				if (!visit.notes) {
					visit.notes = [];
				}

				visit.notes.push(newNote);
				promises.push(newPromise(self.saveVisit(visit)));
			}

			return $q.all(promises).then((result) => {
				return visit;
			});
		}

		self.getVisit = function(visitId) {
			setup();

			const filter = {
				where: {
					id: visitId
				},
				include: [
					{ users: ['buyerProfile','sellerProfile','ownedSuppliers'] }
				]
			}
			return roleApi({ id: roleId, filter: filter }).$promise;
		}

		function getMyVisits(day, dayAfter) {
			setup();
			const filter = {
				where: {
					scheduled: {
						between: [ day, dayAfter ]
					}
				},
				include: [
					'usersVisit',
					'coBuyersVisit',
					{
						relation: 'users',
						scope: {
							fields: [ 'id', 'fullName', 'photoUrl', 'phones', 'email' ]
						},
					},
					{
						relation: 'coBuyers',
						scope: {
						}
					}
				],
				order: 'scheduled ASC'
			}

			return roleApi({ id: roleId, filter: filter } ).$promise;
		}

		function getMyPersonalEvents(day, dayAfter) {
			const filter = {
				where: {
					start: {
						between: [ day, dayAfter ]
					}
				},
				order: 'start ASC'
			}

			return SbUser.events({ id: AccountService.getUserId(), filter: filter } ).$promise;
		}

		function getListingsToClose(day, dayAfter) {
			const filter = {
				where: {
					closing: {
						between: [ day, dayAfter ]
					}
				},
				include: [
					{
						relation: 'property',
						scope: {
							fields: [ 'listingId', '_address' ]
						}
					}
				],
				fields: [ 'id', 'closing' ],
				order: 'closing ASC'
			}

			if (AccountService.isLikeBroker()) return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
			return Agency.getListings({ id: AccountService.getAgencyId(), filter: filter }).$promise;
		}

		function getListingsToExpire(day, dayAfter) {
			const filter = {
				where: {
					status: {
						neq: 'Sold'
					},
					expiry: {
						between: [ day, dayAfter ]
					}
				},
				include: [
					{
						relation: 'property',
						scope: {
							fields: ['listingId', '_address']
						}
					}
				],
				fields: ['id', 'expiry'],
				order: 'expiry ASC'
			}

			if (AccountService.isLikeBroker()) {
				return Broker.listings({ id: AccountService.getBrokerId(), filter: filter }).$promise;
			}
			else {
				return Agency.getListings({ id: AccountService.getAgencyId(), filter: filter }).$promise;
			}
		}

		self.getGoogleCalendarInfo = function() {
			return SbUser.getGoogleEvents({ id: AccountService.getUserId() }).$promise;
		}

		self.getMyCalendar = function(day, days) {
			let events = [];
			const promises = [];
			const role = AccountService.getRole();

			days = days || 1;
			const dayAfter = moment.utc(day).add(days, 'days').toDate();

			const newPromise = function(call, then) {
				return $q((resolve, reject) => {
					call(day, dayAfter).then((res) => {
						then(res);
						resolve(true);
					})
					.catch((err) => {
						reject(err);
					})
				});
			}

			promises.push(newPromise(getMyPersonalEvents, (res) => {
				let i, length = res.length;
				for (i = 0; i < length; i++) {
					events.push({
						id: res[i].id,
						type: 'P',
						subject: res[i].subject,
						start: res[i].start,
						end: res[i].end,
						isCompleted: res[i].isCompleted || false
					})
				}
			}));

			promises.push(newPromise(getMyVisits, (res) => {
				res.forEach((visit) => {

					visit.confirmedCount = 0;
					visit.cancelledCount = 0;
					visit.awaitingResponseCount = 0;

					if ((visit.users || []).length > 0 && (visit.usersVisit || []).length > 0) {
						visit.users.forEach((user) => {
							const rel = visit.usersVisit.splice(visit.usersVisit.findIndex((obj) => { return obj.sbUserId === user.id }), 1)[0] || {};
							user.isCancelled = rel.isCancelled || false;
							user.isConfirmed = rel.isConfirmed || false;
							if(user.isCancelled) {
								visit.cancelledCount++;
							} else if(user.isConfirmed) {
								visit.confirmedCount++;
							} else {
								visit.awaitingResponseCount++;
							}
						});

					}

					if((visit.coBuyers || []).length > 0 && (visit.coBuyersVisit || []).length > 0) {

						visit.coBuyers.forEach((user) => {
							const rel = visit.coBuyersVisit.splice(visit.coBuyersVisit.findIndex((obj) => { return obj.buyerId === user.id }), 1)[0] || {};
							user.isCancelled = rel.isCancelled || false;
							user.isConfirmed = rel.isConfirmed || false;
							if(user.isCancelled) {
								visit.cancelledCount++;
							} else if(user.isConfirmed) {
								visit.confirmedCount++;
							} else {
								visit.awaitingResponseCount++;
							}
						})
					}
					delete visit.coBuyersVisit;
					delete visit.usersVisit;
				})
				events = events.concat(res);
			}));

			promises.push(newPromise(getListingsToExpire, (res) => {
				let i, length = res.length;
				for (i = 0; i < length; i++) {
					events.push({
						type: 'L',
						address: ((res[i].property || {})._address || {}).addressString || 'N/A',
						id: res[i].id,
						expiry: res[i].expiry
					})
				}
			}));

			promises.push(newPromise(getListingsToClose, (res) => {
				res.forEach((listing) => {
					events.push({
						type: 'C',
						address: ((listing.property || {})._address || {}).addressString || 'N/A',
						id: listing.id,
						closing: listing.closing
					})
				})
			}));

			return $q.all(promises).then((result) => {
				return events;
			});
		}

		self.savePersonal = function(event) {
			if (event.id) {
				return SbUser.events.updateById({ id: AccountService.getUserId(), fk: event.id }, event ).$promise;
			}
			else {
				return SbUser.events.create({ id: AccountService.getUserId() }, event ).$promise;
			}
		}

		self.deletePersonal = function(fk) {
			const del = function() {
				return SbUser.events.destroyById({ id: AccountService.getUserId(), fk: fk }).$promise;
			}
			return ModalService.confirmDelete(del, 'ALERT_MESSAGES.ALERT.DELETE_APPOINTMENT');
		}

  	});
})(); // End of function()
