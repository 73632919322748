/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('HelpController', function ($location, $sce, $scope, $window, AccountService, AlertService, HelpService, ModalService) {
		const self = this;
		const _ = $window._;  // Underscore library

		// VARIABLES ============================================================================================================================
		self.topics         = [];       // All the Help topics retrieved from the DB
		self.search         = '';       // Search parameter
		self.filtered       = null;     // Articles filtered after searching
		self.article        = null;     // Current article being displayed
		self.types          = [''];
		// let isIntercomOpen  = false;    // Whether the Intercom popup is open or not


		self.typeFilter = '';
		self.isShowingAdvancedOptions = false;

		_loadHelp();
		_verifyBrowser();
		// _setupIntercom();

		// FUNCTIONS ============================================================================================================================

		self.filter = function() {
			if ((self.search || '').length === 0 && self.typeFilter === '') {
				self.filtered = null;
				return;
			}

			const reg = new RegExp(self.search, 'i');

			self.filtered = self.topics.reduce((arr, topic, i) => {
				topic.articles.forEach((article, j) => {
					if (
						(article.title || '').match(reg) &&
						(article.type === self.typeFilter || self.typeFilter === '')
					) {
						article.hash = '#' + i + '_' + j;
						arr.push(article);
					}
				})
				return arr;
			}, []);
		}

		self.toggleIntercom = function() {
			if (!isIntercomOpen) {
				$window.Intercom('update');
				$window.Intercom('show');
				isIntercomOpen = true;
			}
			else {
				$window.Intercom('hide');
				isIntercomOpen = false;
			}
		}

		function _createWatcher() {
			$scope.$watch(() => { return $location.$$hash; }, () => {
				let hash = $location.$$hash || '';

				if (hash.length > 0) {
					hash = hash.split('_');
					const topic = parseInt(hash[0] || '');
					const i = parseInt(hash[1] || '');

					self.article = ((self.topics[topic] || {}).articles || [])[i] || null;
				}
				else {
					self.article = null;
				}
			});
			AlertService.doneLoading();
		}

		function _loadHelp() {
			AlertService.loading();
			HelpService.getTopics().then((res) => {
				self.topics = res;
				self.topics.forEach((topic) => {
					self.types.push(topic.title);
					topic.articles.forEach((article) => {
						article.text = $sce.trustAsHtml(article.text || '');
						article.type = topic.title;
					});
				});
				self.types.sort((a, b) => {
					if(a > b) return 1;
					if(a < b) return -1;
					return 0;
				})
			})
			.finally(_createWatcher);
		}

		function _setupIntercom() {
			AccountService.getIntercomInfo().then((creds) => {
				if (creds) {
					const settings = $window.intercomSettings || {};
					settings.name = creds.name;
					settings.user_hash = creds.user_hash;
					settings.company = creds.company;
					settings.phone = creds.phone;
					settings.email = creds.email;
					settings.avatar = creds.avatar;
					$window.Intercom('update', creds);
				}
			});
		}

		function _verifyBrowser() {
			const userAgent = navigator.userAgent;
			if((userAgent.indexOf('edge') > -1) || (userAgent.indexOf('edg/') > -1) || (userAgent.indexOf('Edg') > -1)) {
				ModalService.confirmDelete(() => {}, 'ALERT_MESSAGES.ALERT.EDGE_MESSAGE', 'ALERT_MESSAGES.ALERT.WARNING');
			}
		}

	}) // End of controller
})(); // End of function()
