/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('MediaManagerController', function (socket, $filter, $timeout, FileService, GlobalVars, ModalService, ResourceService, UserService, UploadService) {

		const self = this;

		// SETUP VARIABLES ======================================================================================================================
		self.images         = [];
		self.videoImages    = [];
		self.videos         = [];
		self.transferring   = false;
		self.progress       = 0;
		self.selectedAll    = false;

		self.currentFolder = {};

		self.folderName     = '';
		self.folderType     = 'media-manager';

		self.folders = [];

		self.uploader 	    = UploadService.initMediaManagerUploader(true);
		self.originalUploaderUrl = self.uploader.url;

		self.uploader.onSuccessItem = function(fileItem, response, status, header) {
			if(self.currentFolder.id) {
				_load(true);
			} else {
				self.newFolder(response.id);
			}
		}


		self.selected = {
			left: false,
			right: false,
			video: false
		};

		socket.on('user-video', _load);

		_load(true);

		// FUNCTIONS ============================================================================================================================
		self.createVideo = function() {
			ModalService.openModal('createVideo', {
				type: () => 'video',
				data: () => self.videoImages || [],
				options: () =>  { return { listingId: null, selectListing: false } }
			}, 'CreateVideoController', 'createVideoController', null, 'video-modal');
		}

		self.deleteLeft = function(img, isVideo) {
			let toDelete = [];
			const param = {};
			let arr     = 'currentFolder.files';
			let label   = 'ALERT_MESSAGES.ALERT.DELETE_PHOTO';

			if (isVideo) {
				arr = 'videos';
				label = 'ALERT_MESSAGES.ALERT.DELETE_FILE';
			}

			function toReduce(arr, image) {
				if (image.selected) {
					arr.push(image);
				}
				return arr;
			}

			if (!img) {
				if(arr == 'currentFolder.files') {
					toDelete = self.currentFolder.files.reduce(toReduce, []);
				} else {
					toDelete = self[arr].reduce(toReduce, []);
				}

				if (toDelete.length > 1) {
					param.num = toDelete.length;
				}
			}
			else {
				toDelete = [ img ];
			}


			ModalService.prompt(
				$filter('translate')(label, param),
				'COMMON.DELETE',
				'FORMS.CANCEL',
				'FORMS.CONFIRM',
				delLeft, false, 'modal-header'
			)

			function delLeft() {
				GlobalVars.runPromises(UserService.deleteFile, toDelete).then((res) => {
					toDelete.forEach((image) => {
						const i = self.videoImages.findIndex(x => x.id == image.id);
						if (i >= 0) {
							self.videoImages.splice(i, 1);
						}
					})

					_load(true);
				});
			}
		}

		self.deleteRight = function(img) {
			if (!!img) {
				const i = self.videoImages.findIndex(x => x.id == img.id);
				if (i >= 0) {
					self.videoImages.splice(i, 1);
				}
			}
			else {
				self.videoImages = self.videoImages.reduce((arr, img) => {
					if (!img.selected)
						arr.push(img);
					return arr;
				}, []);
			}

			_checkSelected('right');
		}

		self.downloadSelected = function(side) {
			if (side == 'video') {
				const toDownload = self.videos.reduce((arr, video) => {
					if (video.selected)
						arr.push(video);
					return arr;
				}, []);

				_download(toDownload, 0);
			}

			function _download(arr, i) {
				if (!arr[i]) return;
				FileService.download('SbUsers', arr[i].sbUserId, arr[i].id);
				$timeout(() => {
					_download(arr, i+1);
				}, 100);    // Wait a little between each download call to it's not blocked
			}
		}

		self.downloadSingle = function (image) {
			image = image || {};
			if (!image.id || !image.sbUserId) return;
			FileService.download('SbUsers', image.sbUserId, image.id);
		}

		self.dropLeft = function(object, event, secondPhoto, type, callback) {
			const firstIndex 	= angular.copy(object.data.seq);
			const secondIndex = angular.copy(secondPhoto.seq);

			let photos = self.options[self.current].photos;

			const photosCopy = JSON.parse(JSON.stringify(photos));
			const splicedPhoto = photosCopy.splice(firstIndex - 1, 1);
			if(firstIndex > secondIndex) {
				photosCopy.splice(secondIndex - 1, 0, splicedPhoto[0]);
			} else {
				photosCopy.splice(secondIndex - 2, 0, splicedPhoto[0]);
			}
			photos = photosCopy;
			for(let i = 0; i < photosCopy.length; i++) {
				photosCopy[i].seq = i + 1;
			}
			return ListingService.savePhotos($stateParams.id, photos, object.type);
		}

		self.dropRight = function (object, event, secondPhoto, type, callback) {

			// Check if we're doing a swap of the same type. Link to specific bucket/relation.
			if (object.type != type) {
				self.videoImages.push(angular.copy(object.data));
				return Promise.resolve(object);
			}
			//Same type -> reorder
			else if (object.type == type && secondPhoto) {
				const i1 = object.secondIndex;
				const i2 = self.videoImages.findIndex(x => x.id == secondPhoto.id);

				if (i1 >= 0 && i2 >= 0) {
					self.videoImages[i1] = secondPhoto;
					self.videoImages[i2] = object.data;
				}

				return Promise.resolve(true);
			}
			else {
				return Promise.reject();
			}
		}

		self.selectAll = function() {
			if(self.currentFolder && self.currentFolder.files) {
				self.selectedAll = !self.selectedAll;
				self.currentFolder.files.forEach((image) => {
					image.selected = self.selectedAll;
				});
				self.selected.left = self.selectedAll;
			}
		}

		self.selectPhoto = function(photo, i, isLeftSide) {
			photo.selected = !photo.selected;
			const side = isLeftSide ? 'left' : 'right';
			_checkSelected(side);
		}

		self.selectVideo = function(video) {
			video.selected = !video.selected;
			const oneSelected = self.videos.findIndex(x => x.selected);
			self.selected.video = oneSelected >= 0;
		}

		self.transferSelected = function() {
			const selected = self.currentFolder.files.reduce((arr, image) => {
				if (image.selected) {
					image.selected = false;
					image.hover = false;
					arr.push(angular.copy(image));
				}
				return arr;
			}, []);
			self.videoImages = self.videoImages.concat(selected);
		}

		self.newFolder = function(fileId) {
			const folderModal = fileId ? 'createOrSelectFolder' : 'createFolder';
			const modal = ModalService.openModal(folderModal, { folder: function () { return {} }, type: function() { return self.folderType }, options: function() { return { fileId: fileId, folders: self.folders } } }, 'FolderController', 'folderController');
			modal.result
			.then((res) => {
				_load();
			})
			.catch((err) => {
			});
		}

		self.uploadPhoto = function() {
			const dimensions = {
				init_w: 640,
				init_h: 480,
				result_w: 640,
				result_h: 480
			}

			const type = 'media_manager';
			const options = { assignToFolder: true };
			if(self.currentFolder.id) {
				options.currentFolder = self.currentFolder.id;
			}
			options.folders = JSON.parse(JSON.stringify(self.folders));

			const m = ModalService.openModal('wpImageUpload', {
				dimensions: () => dimensions,
				type:       () => type,
				options:    () => options
			},
			'WPImageUploadController', 'uploadController',  // controller, controllerAs
			null, 'wp-upload-modal wp-' + type  // scope, windowClass
			);

			m.result.then((res) => {
				_load(true);
			})
			.catch((err) => {});
		}

		self.viewPhoto = function (image, type) {
			ModalService.openModal('viewImage', {
				media:      () => image,
				mediaType:  () => type || 'img',
				sbDownload: () => self.downloadSingle
			}, 'MediaController', 'mediaController');
		}

		self.editFolder = function(event, folder) {
			event.stopPropagation();
			event.preventDefault();
			const modal = ModalService.openModal('createFolder', { folder: function () { return { folder:folder }}, type: function() { return self.folderType }, options: function() { return { } }  }, 'FolderController', 'folderController');
			modal.result
			.then((res) => {
				_load();
			})
			.catch((err) => {
			});
		}

		self.deleteFolder = function(event, folder) {
			event.stopPropagation();
			event.preventDefault();
			ResourceService.deleteFolder(folder.id).then((res) => {
				self.clearCurrentFolder();
			})
			.finally(() => {
				_load();
			})
			.catch((err) => {

			});
		}

		self.selectFolder = function(folder) {
			self.currentFolder = JSON.parse(JSON.stringify(folder));
			self.uploader.url = self.originalUploaderUrl + '?options=' + JSON.stringify({ folder: self.currentFolder.id });
		}

		self.clearCurrentFolder = function() {
			self.currentFolder = {};
			self.uploader.formData = [];
			self.uploader.url = self.originalUploaderUrl;
			self.selectedAll = false;
			self.selected.left = self.selectedAll;
		}

		function _checkSelected(side) {
			if(self.currentFolder.files) {
				const oneDeselected = self.currentFolder.files.findIndex(x => !x.selected);
				self.selectedAll = oneDeselected < 0;

				const arr = side == 'left' ? self.currentFolder.files : self.videoImages;
				const oneSelected = arr.findIndex(x => x.selected);
				self.selected[side] = oneSelected >= 0;
			}
		}

		function _load(all) {
			ResourceService.getMediaManager().then((res) => {
				res = res || {};
				self.videos = res.videos || [];
			}).finally(() => {
				_checkSelected('left');
			});
			ResourceService.getMediaManagerFolders().then((res) => {
				self.folders = res;
				const refreshedFolder = self.folders.find((value, index) => {
					if(value.id === self.currentFolder.id) return value;
				});
				if(refreshedFolder) self.currentFolder = refreshedFolder;
			})
			.finally(() => {
				_checkSelected('left');
			});
		}
	}) // End of controller
})(); // End of function()
