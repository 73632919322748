/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('ListingsDetailController', function ($anchorScroll, $filter, $location, $rootScope, $scope, $state, $stateParams,$timeout, check, AccountService, AlertService, BrokerService, BuyerService, GlobalVars, LanguageService, ListingService, MenuService, ModalService, NavService, PropertyService, SellerService, SupplierService, UserService) {
		const self = this;

		// VARIABLES ============================================================================================================================

		self.disabled           = null;     // True if new listing (some tabs are disabled) or when loading
		self.index              = null;     // Index of currently active tab
		self.tabs               = null;     // Tab options

		self.sections           = null;     // Sections in Details tab that have language properties (inclusions, exclusions, remarks)
		self.supportedLanguages = null;     // Languages supported in this page
		self.totalSections      = null;     // Array with one section per supported language

		self.buttons            = null;     // Buttons that appear in each tab's header
		self.headerButtons      = null;     // Buttons of currently active tab

		self.listing            = {};       // Current listing
		self.characteristics    = {};       // Text characteristics (Addenda, Remarks, Virtual Lang URL, Inclusions, Exclusions)
		self.lot                = {};       // Information that is displayed in 'Lot' tab
		self.evaluation         = {};       // Information that is displayed in 'Evaluation' tab
		self.building           = {};       // Information that is displayed in 'Building' tab
		self.costs              = {};       // Information that is displayed in 'Costs' tab
		self.features           = {};       // Information that is displayed in 'Features' tab
		self.rooms              = {};       // Information that is displayed in 'Rooms' tab

		self.listingId          = null;     // Current listing's id
		self.propertyId         = null;     // Current listing's property's id
		self.isMLS              = null;     // Whether current listing is an MLS listing

		self.propertyTypes      = [];       // Options for property type dropdown
		self.buildingTypes      = [];       // Options for building type dropdown
		self.measureUnits       = [];       // Options for measure units dropdown
		self.propertyStyles     = [];       // Options for property styles dropdown (filtered by property type)
		let propertyStyles      = [];       // Options for property styles dropdown (not filtered)
		self.currencies         = [];       // Options for currency dropdown (in sold amount)
		self.calendar           = {};       // Calendar objects for expiry date, created date and sold date
		self.shared 			= true;
		self.lockSuppliers 		= false;

		self.additionalBuildings = [];

		let page;                           // Currently active page
		let country;                        // Current user's country (used to pre-populate address data)
		let province;                       // Current user's province (used to pre-populate address data)

		// SETUP VARIABLES ======================================================================================================================

		// Control to prevent user from leaving page when they have unsaved changes

		self.currencies     = [{ id: 'CAN', description: 'CAN' }, { id: 'US', description: 'US' }];
		self.propertyTypes  = GlobalVars.getCodes('propertyTypes', false);
		self.buildingTypes  = GlobalVars.getCodes('buildingTypes');
		self.measureUnits   = GlobalVars.getCodes('measureUnits');
		propertyStyles      = GlobalVars.getCodes('propertyStyles');
		self.isMLS          = $stateParams.submenu === 'mls';
		self.isInventory    = $stateParams.submenu === 'inventory';
		self.isArchived     = $stateParams.submenu === 'archive';
		self.isSmart        = $stateParams.id ? $stateParams.id.includes('S') : false;
		self.disabled       = !!$stateParams.new;
		self.singleConfig   = GlobalVars.singleConfig;
		self.buttons        = ListingService.headerButtons();

		self.isShared       = $stateParams.submenu === 'shared'; // True if someone is viewing a shared smart listing that was shared with them
		self.isSharing      = self.isSmart && !self.isShared; // True if being viewed by the sharer

		self.canCancel      = false;
		self.multiConfig 	= GlobalVars.multiConfig;

		self.sharedStatus   = 'pending';

		self.showCentrisToggle = false;

		self.isRealtor = AccountService.getSchema() === 'realtor';
		// self.isRealtor = true;

		$stateParams.subsubmenu = [];

		province            = {};

		page = {
			0: 'costs',
			1: 'lot',
			2: 'evaluation',
			3: 'building',
			4: 'description',
			5: 'addenda',
			6: 'features',
			7: 'rooms',
		}

		self.listing = {
			id: $stateParams.id || null,
			property: { _address: {} }
		}

		self.lot = {
			_address: {}
		};

		self.tabs = [
			{
				title: 'PROPERTY.COSTS',
				disabled: self.disabled,
				active: false,
				name: 'costs',
				class: 'upper',
				index: 0
			},
			{
				title: 'PROPERTY.LOT',
				disabled: self.disabled,
				active: true,
				name: 'lot',
				class: 'upper',
				index: 1,
				save: saveLot
			},
			{
				title: 'PROPERTY.EVALUATION',
				disabled: self.disabled,
				active: false,
				name: 'evaluation',
				class: 'upper',
				index: 2,
				save: saveEvaluation
			},
			{
				title: 'PROPERTY.BUILDING',
				disabled: self.disabled,
				active: false,
				name: 'building',
				class: 'upper',
				index: 3,
				save: saveBuilding
			},
			{
				title: 'COMMON.DESCRIPTION',
				disabled: false,
				active: false,
				name: 'description',
				class: 'lower',
				index: 4,
				save: saveDescription
			},
			{
				title: 'PROPERTY.ADDENDA',
				disabled: self.disabled,
				active: false,
				name: 'addenda',
				class: 'lower',
				index: 5,
				save: saveAddenda
			},
			{
				title: 'PROPERTY.FEATURES',
				disabled: self.disabled,
				active: false,
				name: 'features',
				class: 'lower',
				index: 6
			},
			{
				title: 'PROPERTY.ROOMS',
				disabled: self.disabled,
				active: false,
				name: 'rooms',
				class: 'lower',
				index: 7
			}
		];

		self.sections = [
			{
				title: $filter('translate')('PROPERTY.INCLUSIONS'),
				data: ''
			},
			{
				title: $filter('translate')('PROPERTY.EXCLUSIONS'),
				data: ''
			},
			{
				title: $filter('translate')('PROPERTY.REMARKS'),
				data: ''
			}
		];

		self.currencyConfig = {
			create: false,
			valueField: 'id',
			labelField: 'description',
			maxItems: 1,
			searchField: 'name',
			onInitialize: function(selectize) {
				const input = (selectize.$control_input || [])[0] || null;

				if (input) {
					input.readOnly = true;
				}
			}
		}

		// FUNCTIONS ============================================================================================================================

		$scope.$watch('listingsDetailController.index', () => {
			if (self.index != Number($stateParams.tab)) {
				self.index = Number($stateParams.tab);
			}
		})

		if (AccountService.getSchema() === 'realtor' || ($state.params.id && $state.params.tab == 3)) {
			self.propertyStyles = propertyStyles;
		}
		else {
			if(self.isSmart) {
				$scope.$watch('listingsDetailController.listing.property.propertyTypeIds', (newValue) => {
					if (newValue && newValue.length > 0) {
						for(let i = 0; i < self.listing.property.propertyTypeIds.length; i++) {
							const styleFilter = newValue[i].substring(0,5).replace('CAT', 'STY');
							if(i === 0) {
								self.propertyStyles = propertyStyles.reduce((arr,item) => {
									if (item.value === 'xxx' || item.value.includes(styleFilter)) {
										arr.push(item);
									}
									return arr;
								}, []);
							} else {
								let tempPropertyStyles = propertyStyles.reduce((arr,item) => {
									if (item.value.includes(styleFilter)) {
										arr.push(item);
									}
									return arr;
								}, []);
								self.propertyStyles = self.propertyStyles.concat(tempPropertyStyles);
							}
						}
					} else {
						self.propertyStyles = [propertyStyles[0]];
					}
				});
			} else {
				$scope.$watch('listingsDetailController.listing.property.typeId', (newValue) => {
					if (newValue) {
						const styleFilter = newValue.substring(0,5).replace('CAT', 'STY');
						self.propertyStyles = propertyStyles.reduce((arr,item) => {
							if (item.value === 'xxx' || item.value.includes(styleFilter)) {
								arr.push(item);
							}
							else if (item.value === ((self.listing || {}).property || {}).styleIds) {
								self.listing.property.styleIds = [];
							}
							return arr;
						}, []);
					}
					else {
						self.propertyStyles = [propertyStyles[0]];
					}
				});
			}
		}

		self.check = function () {
			self.index = Number($stateParams.tab);
			getListing();
			getCountryAndProvince();
			//self.index = Number($stateParams.tab)
			self.headerButtons = self.buttons[self.tabs[self.index].name];
		}

		self.goToPhotos = function() {
			if(!self.isShared) {
				$state.go('main.listings.detail.photos', { id: self.listing.id });
			}
			// ui-sref="main.listings.detail.photos({id:listingsDetailController.listing.id})"
		}

		self.getLabel = function (code) {
			return GlobalVars.getLabel(code);
		}

		self.checkForm = function () {

			if (!!$scope.descriptionForm.$invalid && self.index == 4) {
				const address = ((self.listing || {}).property || {})._address || {};
				if (!address.streetName) {
					$location.hash('address');
					$anchorScroll();
				}
				return false;
			}
			return true;
		}

		// COST -----------------------------------
		self.addCost = function () {
			const modal = ModalService.openModal('createCost', {
				id: function () { return self.propertyId },
				cost: function () { return {} },
				updating: false
			}, 'CostController', 'costController');

			modal.result.then((res) => {
				AlertService.saved();
				getListing();
			})
			.catch((err) => {});
		}

		self.editCost = function (old) {
			const cost = angular.copy(old);
			const modal = ModalService.openModal('createCost', {
				id: function () { return self.propertyId },
				cost: function () { return cost },
				updating: true
			}, 'CostController', 'costController');

			modal.result.then((res) => {
				AlertService.saved();
				getListing();
			})
			.catch((err) => {});
		}

		self.deleteCost = function (cost) {
			PropertyService.deleteCost(self.propertyId, cost.id)
			.then((res) => {
				if (res) {
					AlertService.saved();
					getListing();
				}
			})
			.catch((err) => {})
		}

		// FEATURE --------------------------------
		self.addFeature = function () {
			const modal = ModalService.openModal('createFeature', {
				id: function () { return self.propertyId },
				feature: function () { return {}; },
				updating: function () { return false }
			}, 'FeatureController', 'featureController');

			modal.result.then((res) => {
				AlertService.saved();
				getListing();
			})
			.catch((err) => {});
		}

		self.editFeature = function (old) {
			const feature = angular.copy(old);

			const modal = ModalService.openModal('createFeature', {
				id: function () { return self.propertyId },
				feature: function () { return feature },
				updating: true
			}, 'FeatureController', 'featureController');

			modal.result.then((res) => {
				AlertService.saved();
				getListing();
			})
			.catch((err) => { });
		}

		self.deleteFeature = function (feature) {
			PropertyService.deleteFeature(self.propertyId, feature.id)
			.then((res) => {
				if (res) {
					AlertService.saved();
					getListing();
				}
			})
			.catch((err) => {})
		}

		// ROOM -----------------------------------
		function saveRooms(rooms) {
			const args = [];
			rooms.forEach((room, i) => {
				room.order = i + 1;
				args.push(room);
			})

			GlobalVars.runPromises(ListingService.updateRoom, args).then((res) => {
				getRooms();
				AlertService.saved();
			})
		}

		self.doReorder = function(srcIndex, targetIndex) {
			// Copy the item from source to target.
			self.rooms.splice(targetIndex, 0, self.rooms[srcIndex]);

			// Remove the item from the source, possibly correcting the index first.
			// We must do this immediately, otherwise ng-repeat complains about duplicates.
			if (targetIndex <= srcIndex) srcIndex++;

			self.rooms.splice(srcIndex, 1);

			saveRooms(self.rooms);

			// By returning true from dnd-drop we signalize we already inserted the item.
			return true;
		}

		self.addRoom = function () {
			const modal = ModalService.openModal('createRoom', {
				id: function () { return self.propertyId },
				room: function () { return {} },
				updating: function () { return false },
				maxOrder: function () { return self.rooms.length }
			}, 'RoomController', 'roomController');

			modal.result.then((res) => {
				self.rooms.push(res);
				self.doReorder(self.rooms.length - 1, res.order - 1);
			})
			.catch((err) => {
			});
		}

		self.editRoom = function (old) {
			const room = angular.copy(old);
			const modal = ModalService.openModal('createRoom', {
				id: function () { return self.propertyId },
				room: function () { return room },
				updating: true,
				maxOrder: function () { return self.rooms.length - 1 }
			}, 'RoomController', 'roomController');

			modal.result.then((res) => {
				if (res.id) {
					self.rooms[old.order - 1] = res;
					if (old.order < res.order) {
						self.doReorder(old.order - 1, res.order); // Will add copy then remove from below -> adjust index by 1 (plus)
					}
					else if (old.order > res.order) {
						self.doReorder(old.order - 1, res.order - 1); // Will add copy below when remove from above -> use index adjustment from doReorder function
					}
					else {
						saveRooms(self.rooms);
					}
				}
				else {  // Room was deleted
					const i = self.rooms.findIndex((item) => {
						return item.id === room.id;
					})
					if (i >= 0) {
						self.rooms.splice(i, 1);
					}
					saveRooms(self.rooms);
				}
			})
			.catch((err) => {});
		}

		self.deleteRoom = function (room) {
			PropertyService.deleteRoom(self.propertyId, room.id)
			.then((res) => {
				if (res) {
					const i = self.rooms.findIndex((item) => {
						return item.id === room.id;
					})
					if (i >= 0) {
						self.rooms.splice(i, 1);
					}
					saveRooms(self.rooms);
				}
			})
			.catch((err) => { })
		}

		// PEOPLE ---------------------------------

		self.openSellerModal = function () {
			SellerService.getSellers().then((res) => {
				const modal = openModal(res, 'Seller');

				modal.result.then((res) => {
					if (res.closed) return;
					else {
						AlertService.saved();
						getSellers();
					}
				})
				.catch((err) => {});
			})
			.catch((err) => {});
		}

		self.openBuyerModal = function() {
			BuyerService.getBuyers().then((res) => {
				const modal = openModal(res, 'Buyer');

				modal.result.then((res) => {
					if (res.closed) return;
					else {
						AlertService.saved();
						getBuyers();
					}
				})
				.catch((err) => {});
			})
		}

		self.openSupplierModal = function() {
			SupplierService.getSuppliers(null, null, null).then((res) => {
				const modal = openModal(res, 'Supplier');
				modal.result.then((res) => {
					if(res.closed) return;
					else {
						AlertService.saved();
						getSuppliers();
					}
				})
				.catch((err) => {});
			})
			.catch((err) => {});
		}

		self.openBrokerModal = function () {
			BrokerService.getBrokers().then((res) => {
				const modal = openModal(res, 'Broker');

				modal.result.then((res) => {
					if (res.closed) return;

					else {
						AlertService.saved();
						getBrokers();
					}
				})
				.catch((err) => {});
			})
			.catch((err) => {});
		}

		const getPeople = function (type) {
			const get = {
				Notary: UserService.getNotaries
			};
			const getType = {
				Notary: getNotaries
			}
			get[type]()
			.then((res) => {
				const modal = openModal(res, type);
				modal.result.then((res) => {
					if (res.closed) {
						return
					}
					else {
						AlertService.saved();
						getType[type]();
					}
				})
				.catch((err) => {
				});
			})
			.catch((err) => {
			});
		}

		self.openNotaryModal = function () {
			getPeople('Notary');
		}

		self.sellerSecret = function(seller) {
			seller = seller || {};
			const sellerId = seller.id;

			const controller = function($uibModalInstance, $log, $scope, $state, $translate, AlertService, ModalService, ListingService) {

				ListingService.getSellerAccess($stateParams.id, sellerId).then((res) => {
					$scope.accessCode = res.secret;
				})
				.catch((err) => {
					$log.error(err);
					$scope.accessCode = 'ERR';
				})

				const url = $state.href('main.owner.login', {}, { absolute: true });
				$translate('PERSON.SELLER.SELLER_SECRET.LOGIN_URL', { loginUrl: url }).then((res) => {
					$scope.loginUrlMsg = ('' + res).replace(/\//g, '<wbr>\/');
				})

				const emailEnabled = (seller.user || {}).emailEnabled;
				const hasEmail = !!(seller.user || {}).email;

				$scope.close = function() {
					$uibModalInstance.dismiss('cancel');
				}

				$scope.email = function() {
					if (emailEnabled && hasEmail) {
						ListingService.emailSellerPassword($stateParams.id, sellerId).then((res) => {
							AlertService.saved();
							$scope.isEmailSent = true;
						});
					}
					else {
						ModalService.prompt('ALERT_MESSAGES.ALERT.CANT_EMAIL_SELLER_SECRET', null, 'Ok');
					}
				}
			}

			ModalService.openModal('sellerSecret', {}, controller, 'controller');
		}

		self.unlinkNotary = function (fk) {
			ListingService.unlinkNotary($stateParams.id, fk)
			.then((res) => {
				getNotaries();
			})
			.catch((err) => {
				console.error(err);
			})
		}

		self.unlinkSeller = function (fk) {
			ListingService.unlinkSeller($stateParams.id, fk)
			.then((res) => {
				getSellers();
			})
			.catch((err) => {
				console.error(err);
			})
		}

		self.unlinkBroker = function (fk) {
			ListingService.unlinkBroker($stateParams.id, fk)
			.then((res) => {
				getBrokers();
			})
			.catch((err) => { })
		}

		self.unlinkSupplier = function (fk) {
			ListingService.unlinkSupplier($stateParams.id, fk)
			.then((res) => {
				getSuppliers();
			})
			.catch((err) => {});
		}

		self.openShareModal = function() {
			BrokerService.getBrokers().then((res) => {
				const modal = ModalService.openModal('shareListing', {
					selectedListing: function() { return true },
					listingId: function() { return self.listing.id },
					brokers: function() { return res },
					showBrokers: function() { return true },
					selectedRecipient: function () { return null },
					sharedBrokers: function() { return self.listing.sharedBrokers }
				}, 'ShareListingModalController', 'shareListingModalController');
				modal.result.then((res) => {
					self.isSharing = true;
					self.canCancel = true;

					if (res.closed) return;
					else {
						AlertService.saved();
						getBrokers();
					}
				})
				.catch((err) => {});
			})
			.catch((err) => {});
		}

		self.cancelShared = function(ev) {
			if(ev && ev.preventDefault) ev.preventDefault();
			return ModalService.openModalWithFunction('manageSharedListingStatus', {
				listingId: function() { return self.listing.id },
				status: function() { return 'cancelled' },
				brokerName: function() { return '' },
				agencyName: function () { return '' }
			}, 'UpdateSharedListingModalController', 'shareListingModalController', () => {
				self.sharedStatus = 'cancelled';
				self.canCancel = true;
			});
		}

		self.rejectShared = function(ev) {
			if(ev && ev.preventDefault) ev.preventDefault();
			return ModalService.openModalWithFunction('manageSharedListingStatus', {
				listingId: function() { return self.listing.id },
				status: function() { return 'rejected' },
				brokerName: function() { return '' },
				agencyName: function () { return '' }
			}, 'UpdateSharedListingModalController', 'shareListingModalController', () => {
				self.sharedStatus = 'rejected';
			});
		}

		self.approveShared = function(ev) {
			if(ev && ev.preventDefault) ev.preventDefault();
			return ModalService.openModalWithFunction('manageSharedListingStatus', {
				listingId: function() { return self.listing.id },
				status: function() { return 'approved' },
				brokerName: function() { return self.listing.brokers[0].broker.user.fullName },
				agencyName: function () { return self.listing.brokers[0].broker.agency.legalName }
			}, 'UpdateSharedListingModalController', 'shareListingModalController', () => {
				self.sharedStatus = 'approved';
			});
		}

		const openModal = function (people, peopleType) {
			const linkType = 'listing';
			return ModalService.openModal('addPersonToListing', {
				id: function () { return self.listing.id },
				people: function () { return people },
				peopleType: function () { return peopleType },
				linkType: function() { return linkType }
			}, 'AddPersonController', 'addPersonController');
		}

		self.goToVisits = function () {
			MenuService.listingMenuClick(MenuService.listingMenu[8]);
		}

		self.switch = function (tab, index, event) {
			if (self.index !== index) {
				$state.go('main.listings.detail.submenu', { tab: index }, { reload: true });
			}
		}

		self.unarchive = function(ev) {
			if(ev && ev.preventDefault) ev.preventDefault();

			const message = $filter('translate')('LISTING.MODAL.CONFIRM_UNARCHIVE');

			const m = ModalService.openModal('confirm', {
				message: () => message
			}, 'ConfirmController', 'confirmController', null);

			m.result.then((res) => {
				ListingService.unarchive($stateParams.id).then((res) => {
					if(self.isSmart) {
						NavService.navigate('main.listings.detail.submenu', { submenu: 'inventory', id: self.listingId, tab: self.index });
					} else {
						NavService.navigate('main.listings.detail.submenu', { submenu: 'smart', id: self.listingId, tab: self.index });
					}
				});
			});
		}

		self.archive = function(ev) {
			if(ev && ev.preventDefault) ev.preventDefault();

			const message = $filter('translate')('LISTING.MODAL.CONFIRM_ARCHIVE');

			const m = ModalService.openModal('confirm', {
				message: () => message
			}, 'ConfirmController', 'confirmController', null);

			m.result.then((res) => {
				ListingService.archive($stateParams.id).then((res) => {
					NavService.navigate('main.listings.detail.submenu', { submenu: 'archive', id: self.listingId, tab: self.index });
				});
			});
		}

		self.deleteAdditionalBuilding = function(ev, additionalBuilding, index) {
			if(ev) {
				if(ev.preventDefault) {
					ev.preventDefault();
				}
				if(ev.stopPropagation) {
					ev.stopPropagation();
				}
			}

			PropertyService.deleteAdditionalBuilding(self.propertyId, additionalBuilding.id).then((res) => {
				getAdditionalBuildings();
				AlertService.saved();
			})
		}

		self.addAdditionalBuilding = function(ev) {
			if(ev) {
				if(ev.preventDefault) {
					ev.preventDefault();
				}
				if(ev.stopPropagation) {
					ev.stopPropagation();
				}
			}
			const newAdditionalBuilding = {
				buildingTypeId: '',
				numBedrooms: 0,
				numBathrooms: 0,
				numFloors: 0,
				buildingLivingArea: 0,
				numPowderRooms: 0,
				characteristics: [],
				styleIds: []
			};

			let i;
			const length = self.supportedLanguages.length;
			let objectChars = {};
			for (i = 0; i < length; i++) {
				const lang = self.supportedLanguages[i].id;
				const newChar = {
					languageId: lang,
					inclusions: '',
					exclusions: '',
					description: '',
					addenda: ''
				};
				newAdditionalBuilding.characteristics.push(newChar);
				objectChars[lang] = newAdditionalBuilding.characteristics[newAdditionalBuilding.characteristics.length - 1];
			}

			newAdditionalBuilding.characteristics = objectChars;

			self.additionalBuildings.push(newAdditionalBuilding);
		}

		$scope.registerForm = function(i) {
			const name = 'additionalBuildingForm_' + i;
			$scope.forms[name] = {};
			return 'forms.' + name;
		}

		function doCalendars() {
			// Moved calendar here so we can use listing.created as minDate
			self.calendar = {
				create: GlobalVars.createCalendarConfig(new Date(1990, 1, 1),new Date()),
				expiry: GlobalVars.createCalendarConfig(self.listing.created),
				sold: GlobalVars.createCalendarConfig(self.listing.created),
				closing: GlobalVars.createCalendarConfig(self.listing.created)
			};

			const w1 = $scope.$watch('listingsDetailController.listing.created', (created) => {
				let minDate = null;

				if (!!created) {
					const utc = moment.utc(created);
					minDate = new Date(utc.year(), utc.month(), utc.date(), 0, 0, 0);
				}

				self.calendar.expiry.options.minDate  = minDate;
				self.calendar.closing.options.minDate = minDate;
				self.calendar.sold.options.minDate    = minDate;
			})

			$scope.$on('$destroy', w1);
		}

		function getListing() {
			self.enabled = false;
			LanguageService.getUserLanguages().then((res) => {
				self.supportedLanguages = res;
				self.totalSections = [];
				for (let i = 0; i < self.sections.length; i++) {
					for (let j = 0; j < self.supportedLanguages.length; j++) {
						const section = {
							title: self.sections[i].title + ' ' + self.supportedLanguages[j],
							data: ''
						};
						self.totalSections.push(section);
					}
				}

				// getSharedBrokers();

				if ($state.params.id) {
					switch ($state.params.tab) {
					case 0: case '0': getCosts(); break;
					case 1: case '1': getLot(); break;
					case 2: case '2': getEvaluation(); break;
					case 3: case '3': getBuilding(); break;
					case 4: case '4': getDescription(); break;
					case 5: case '5': getAddenda(); break;
					case 6: case '6': getFeatures(); break;
					case 7: case '7': getRooms(); break;
					}
				}
				else {
					$timeout(() => {
						// Creates new Characteristic objects for new listing - One for each language
						self.listing.property.characteristics = [];
						for (let i = 0; i < self.supportedLanguages.length; i++) {
							const lang = self.supportedLanguages[i].id;

							const newChar = {
								languageId: lang,
								inclusions: '',
								exclusions: '',
								description: '',
								addenda: ''
							};
							self.listing.property.characteristics.push(newChar);
							self.characteristics[lang] = self.listing.property.characteristics[self.listing.property.characteristics.length - 1];
						}
					})
				}
			})
			.catch((error) => {
				console.error('Failed to retrieve user languages', error);
			})
		}

		self.saveAdditionalBuilding = function(additionalBuilding, index) {
			if(!additionalBuilding.id) {
				PropertyService.createAdditionalBuilding(self.propertyId, additionalBuilding).then((res) => {
					AlertService.saved();
					getAdditionalBuildings();
				});
			} else {
				PropertyService.updateAdditionalBuilding(self.propertyId, additionalBuilding.id, additionalBuilding).then((res) => {
					AlertService.saved();
					getAdditionalBuildings();
				});
			}
		}

		self.doReorderSuppliers = function(index, targetIndex) {
			console.log('index', index, 'targetIndex', targetIndex);

			const listingSupplier = self.listing.suppliers.splice(index, 1);
			self.listing.suppliers.splice(targetIndex, null, listingSupplier[0]);

			console.log('suppliers', self.listing.suppliers);

			ListingService.reorderSuppliers($stateParams.id, self.listing.suppliers)
			.then(() => {
				console.log('Successfully reordered suppliers');
			})
			.catch((error) => {
				console.error('Failed to reorder listing suppliers', error);
			});
		}

		function saveAddenda() {
			check.isSaved = true;
			const chars = self.characteristics;
			let done = 0;
			const length = self.supportedLanguages.length;

			const allDone = function () {
				if (done == length || done == Object.keys(chars).length) {
					AlertService.doneLoading();
					AlertService.saved();
					getListing();
				}
			}

			const save = function (item) {
				ListingService.updateCharacteristic(self.propertyId, item)
				.then((res) => {
					done += 1;
				})
				.catch((err) => {
					console.log('err: ', err);
				})
				.finally(() => {
					allDone();
				})
			}

			if(!chars || Object.keys(chars).length == 0) {
				done = length;
				allDone();
			} else {
				for (const i in chars) {
					save(chars[i]);
				}
			}
		}

		self.duplicate = function(ev) {
			if(ev && ev.preventDefault) ev.preventDefault();

			const listing = self.listing;

			const canBeDuplicated = !listing.isSoldDuplicate && !listing.soldDuplicate && !listing.soldDuplicatedFrom;

			if(canBeDuplicated) {
				const apiCall = ListingService.duplicateListing;
				const modal = ModalService.openModal('duplicateListing', {
					id: function () { return self.listing.id },
					listing: function() { return self.listing },
					apiCall: function () { return apiCall },
				}, 'DuplicateListingModalController', 'DuplicateListingModalController');

				modal.result.then(() => {
					return;
				}, () => {
					return;
				});
			}
		}

		function saveDescription() {
			const listing = self.listing;
			check.isSaved = true;

			if (!$stateParams.new) {
				if(listing.sold && listing.status != 'Sold' && !listing.isSoldDuplicate && !listing.soldDuplicatedFrom && !listing.soldDuplicateId) {
					const apiCall = ListingService.updateDescription;
					const modal = ModalService.openModal('sellListing', {
						id: function () { return self.listing.id },
						listing: function() { return self.listing },
						apiCall: function () { return apiCall },
					}, 'SellListingModalController', 'sellListingModalController');

					modal.result.then(() => {
						saveAddenda();
					}, () => {
						self.listing.sold = null;
					})
				} else {
					AlertService.loading('LISTING.SAVING_LISTING', true);
					const apiCall = listing.id ? ListingService.updateDescription(listing.id, listing) : ListingService.createDescription(listing);
					apiCall
					.then((res) => {
						saveAddenda(); // Saved description, will now save texts (Inclusions, Exclusions, Remarks, Virtual Tour URL)
					})
					.catch((err) => {
						console.log('err: ', err);
					})
				}
			}
		}

		function saveLot() {
			check.isSaved = true;
			//add flag to update or save as new

			const address = {
				coordinates: {
					lat: self.lot._address.coordinates.lat,
					lng: self.lot._address.coordinates.lng
				}
			}
			const property = {
				lotFrontage: self.lot.lotFrontage,
				waterAreaName: self.lot.waterAreaName,
				lotArea: self.lot.lotArea,
				lotAreaMUId: self.lot.lotAreaMUId
			}

			ListingService.saveLot(self.listing.id, property, address)
			.then((res) => {
				AlertService.saved();
				getListing();
			})
			.catch((err) => { })
		}

		function saveEvaluation() {
			check.isSaved = true;

			//add flag to update or save as new
			ListingService.saveEvaluation(self.listing.id, self.evaluation.id, self.evaluation)
			.then((res) => {
				AlertService.saved();
				getListing();
			})
			.catch((err) => { })
		}

		function saveBuilding() {
			check.isSaved = true;
			ListingService.saveBuilding(self.listing.id, self.building.id, self.building)
			.then((res) => {
				AlertService.saved();
				getListing();
			})
			.catch((err) => { })
		}

		function getBrokers() {
			ListingService.getBrokers($stateParams.id)
			.then((res) => {
				for (let i = 0; i < res.length; i++) {
					if(res[i].broker) {
						res[i].broker.primary = res[i].primary;
					}
				}
				self.listing.brokers = res;
				setCurrentStatus(self.listing.shared);
			})
			.catch((err) => {
				console.error(err);
			});
		}

		function getCosts() {
			self.enabled = false;
			ListingService.getCosts($stateParams.id, AccountService.getBrokerId(), self.isShared, self.isSharing)
			.then((res) => {
				self.costs = res.property.costs;
				self.listingId = res.id;
				self.propertyId = res.property.id;
				for (let i = 0; i < res.listingBrokers.length; i++) {
					res.listingBrokers[i].broker.primary = res.listingBrokers[i].primary;
				}
				self.listing.brokers = res.listingBrokers;
				setCurrentStatus(res.shared);
			})
			.catch((err) => {

			})
			.finally(() => {
				self.enabled = true;
				AlertService.doneLoading();
			})
		}

		function getLot() {
			self.enabled = false;
			ListingService.getLot($stateParams.id, AccountService.getBrokerId(), self.isShared, self.isSharing)
			.then((res) => {
				self.lot = res.property;
				self.listingId = res.id;
				self.propertyId = res.property.id;
				for (let i = 0; i < res.listingBrokers.length; i++) {
					res.listingBrokers[i].broker.primary = res.listingBrokers[i].primary;
				}
				self.listing.brokers = res.listingBrokers;
				setCurrentStatus(res.shared);
				check.original = angular.copy(self.lot);
				check.clone = self.lot;
			})
			.catch((err) => {

			})
			.finally(() => {
				self.enabled = true;
				AlertService.doneLoading();
			});
		}

		function getEvaluation() {
			self.enabled = false;
			ListingService.getEvaluation($stateParams.id, AccountService.getBrokerId(), self.isShared, self.isSharing)
			.then((res) => {
				self.evaluation = res.property;
				self.listingId = res.id;
				self.propertyId = res.property.id;
				for (let i = 0; i < res.listingBrokers.length; i++) {
					res.listingBrokers[i].broker.primary = res.listingBrokers[i].primary;
				}
				self.listing.brokers = res.listingBrokers;
				setCurrentStatus(res.shared);
				check.original = angular.copy(self.evaluation);
				check.clone = self.evaluation;
			})
			.catch((err) => {

			})
			.finally(() => {
				self.enabled = true;
				AlertService.doneLoading();
			});
		}

		function getBuilding() {
			self.enabled = false;
			ListingService.getBuilding($stateParams.id, AccountService.getBrokerId(), self.isShared, self.isSharing)
			.then((res) => {
				self.building = res.property;
				self.listingId = res.id;
				self.propertyId = res.property.id;
				self.additionalBuildings = res.property.additionalBuildings;
				for (let i = 0; i < res.listingBrokers.length; i++) {
					res.listingBrokers[i].broker.primary = res.listingBrokers[i].primary;
				}
				self.listing.brokers = res.listingBrokers;
				setCurrentStatus(res.shared);
				check.original = angular.copy(self.building);
				check.clone = self.building;

				getCharacteristicsFromAdditionalBuildings();
			})
			.catch((err) => {

			})
			.finally(() => {
				self.enabled = true;
				AlertService.doneLoading();
			});
		}

		function getFeatures() {
			self.enabled = false;
			ListingService.getFeatures($stateParams.id, AccountService.getBrokerId(), self.isShared, self.isSharing)
			.then((res) => {
				self.features = res.property.features;
				self.listingId = res.id;
				self.propertyId = res.property.id;
				for (let i = 0; i < res.listingBrokers.length; i++) {
					res.listingBrokers[i].broker.primary = res.listingBrokers[i].primary;
				}
				self.listing.brokers = res.listingBrokers;
				setCurrentStatus(res.shared);
			})
			.catch((err) => {

			})
			.finally(() => {
				self.enabled = true;
				AlertService.doneLoading();
			});
		}

		function getAddenda() {
			self.enabled = false;
			ListingService.getAddenda($stateParams.id, AccountService.getBrokerId(), self.isShared, self.isSharing)
			.then((res) => {
				self.listingId = res.id;
				self.propertyId = res.property.id;
				self.characteristics = {};
				let found = false;
				if (res.property.characteristics) {
					for (let i = 0; i < self.supportedLanguages.length; i++) {
						const lang = self.supportedLanguages[i].id;
						for (let j = 0; j < res.property.characteristics.length; j++) {
							const char = res.property.characteristics[j] || {};

							if ((char.languageId || null) === lang) {
								self.characteristics[lang] = char;
								found = true;
								break;
							}
						}

						if (!found) {
							const newChar = {
								languageId: lang,
								inclusions: '',
								exclusions: '',
								description: '',
								addenda: ''
							};

							res.property.characteristics.push(newChar);
							self.characteristics[lang] = res.property.characteristics[res.property.characteristics.length - 1];
						}
					}
				}
				for (let i = 0; i < res.listingBrokers.length; i++) {
					res.listingBrokers[i].broker.primary = res.listingBrokers[i].primary;
				}
				self.listing.brokers = res.listingBrokers;
				setCurrentStatus(res);
				check.original = angular.copy(self.characteristics);
				check.clone = self.characteristics;
			})
			.catch((err) => {
			})
			.finally(() => {
				self.enabled = true;
				AlertService.doneLoading();
			});
		}

		function getRooms() {
			self.enabled = false;
			ListingService.getRooms($stateParams.id, AccountService.getBrokerId(), self.isShared, self.isSharing)
			.then((res) => {
				self.rooms = res.property.rooms;
				self.listingId = res.id;
				self.propertyId = res.property.id;
				for (let i = 0; i < res.listingBrokers.length; i++) {
					res.listingBrokers[i].broker.primary = res.listingBrokers[i].primary;
				}
				self.listing.brokers = res.listingBrokers;
				setCurrentStatus(res);
			})
			.catch((err) => { })
			.finally(() => {
				self.enabled = true;
				AlertService.doneLoading();
			});
		}

		function getDescription() {
			self.enabled = false;
			ListingService.getDescription(self.listing.id, AccountService.getBrokerId(), self.isShared, self.isSharing)
			.then((res) => {
				self.listing = res;
				self.listingId = res.id;
				self.propertyId = (res.property || {}).id;

				for (let i = 0; i < res.listingBrokers.length; i++) {
					res.listingBrokers[i].broker.primary = res.listingBrokers[i].primary;
				}
				self.listing.brokers = res.listingBrokers;


				const primaryBroker = self.listing.brokers.find((value) => {
					if(value.primary) return value;
				});

				if(!primaryBroker.realtor && !self.isSmart) {
					self.showCentrisToggle = true;
				}

				if (!!self.listing.created) {
					self.listing.created = new Date(self.listing.created);
				}

				if (!!self.listing.sold) {
					self.listing.sold = new Date(self.listing.sold);
				}

				if (!!self.listing.expiry) {
					self.listing.expiry = new Date(self.listing.expiry);
				}

				if (!!self.listing.closing) {
					self.listing.closing = new Date(self.listing.closing);
				}

				if (self.listing.status === 'Expired' || self.listing.isDraft) {
					self.disableToggle = true;
				}
				else if (self.listing.status === 'Sold') {
					const soldDate = moment(self.listing.sold);
					self.disableToggle = moment().diff(soldDate, 'days') > 90;
				}
				else {
					self.disableToggle = false;
				}

				setCurrentStatus(res.shared);

				doCalendars();

				//SortingService.sortByLabel(self.features, ['type', 'value']);
				if (!self.listing.property) {
					self.listing.property = {};
				}
				if (!self.listing.property.characteristics) {
					self.listing.property.characteristics = [];
				}

				if (!self.listing.property._address.country) {
					self.listing.property._address.country = country;
				}

				if (!self.listing.property._address.province) {
					self.listing.property._address.province = province;
				}

				if(self.listing.property.codes && self.listing.property.codes.length > 0) {
					self.listing.property.propertyTypeIds = self.listing.property.codes.map((value) => {
						return value.id;
					});
				}

				self.listing.suppliers = self.listing.suppliers.sort((a, b) => {
					if(a.listingSuppliers[0].seq > b.listingSuppliers[0].seq) return 1;
					if(a.listingSuppliers[0].seq < b.listingSuppliers[0].seq) return -1;
					return 0;
				});

				delete self.listing.property.codes;

				let i;
				const length = self.supportedLanguages.length;

				for (i = 0; i < length; i++) {

					const lang = self.supportedLanguages[i].id;
					let found = false;

					if (self.listing.property.characteristics) {
						let j;
						const lengthJ = self.listing.property.characteristics.length;
						for (j = 0; j < lengthJ; j++) {
							const char = self.listing.property.characteristics[j] || {};

							if ((char.languageId || null) === lang) {
								self.characteristics[lang] = char;
								found = true;
								break;
							}
						}

						if (!found) {
							const newChar = {
								languageId: lang,
								inclusions: '',
								exclusions: '',
								description: '',
								addenda: ''
							};

							self.listing.property.characteristics.push(newChar);
							self.characteristics[lang] = self.listing.property.characteristics[self.listing.property.characteristics.length - 1];
						}
					}
				}
			})
			.catch((err) => { })
			.finally(() => {
				self.enabled = true;
				check.ignore = [ { listing: 'brokers' }];
				check.clone = {
					listing: self.listing,
					chars: self.characteristics
				};
				check.original = angular.copy(check.clone);

				const listener = $rootScope.$on('doneAddress', () => {
					const addr = self.listing.property._address;
					check.original.listing.property._address = angular.copy(addr);
					check.clone.listing.property._address = addr;
				})

				$scope.$on('$destroy', () => {
					listener();
				})

				AlertService.doneLoading();
			})
		}

		function getNotaries() {
			ListingService.getNotaries($stateParams.id)
			.then((res) => {
				self.listing.notaries = res;
			})
			.catch((err) => {
				console.error(err);
			})
		}

		function getSellers() {
			ListingService.getSellers($stateParams.id)
			.then((res) => {
				self.listing.sellers = res;

				if (self.listing.isDraft) {
					self.disableToggle = res.length === 0;
				}
			})
			.catch((err) => {
				console.error(err)
			})
		}

		function getBuyers() {
			ListingService.getBuyers($stateParams.id)
			.then((res) => {
				self.listing.buyers = res;
			})
		}

		function getSuppliers() {
			ListingService.getSuppliers($stateParams.id)
			.then((res) => {
				self.listing.suppliers = res;

				if(self.listing.isDraft) {
					self.disableToggle = res.length === 0;
				}
			})
			.catch((err) => {
				console.error(err);
			});
		}

		function getCharacteristicsFromAdditionalBuildings() {
			self.characteristics = {};
			let i;
			let h;
			const length = self.supportedLanguages.length;

			for(h = 0; h < self.additionalBuildings.length; h++) {

				let objectChars = {};

				for (i = 0; i < length; i++) {

					const lang = self.supportedLanguages[i].id;
					let found = false;

					if (self.additionalBuildings[h].characteristics) {
						let j;
						const lengthJ = self.additionalBuildings[h].characteristics.length;
						for (j = 0; j < lengthJ; j++) {
							const char = self.additionalBuildings[h].characteristics[j] || {};

							if ((char.languageId || null) === lang) {
								objectChars[lang] = char;
								found = true;
								break;
							}
						}

						if (!found) {
							const newChar = {
								languageId: lang,
								additionalPropertyId: self.additionalBuildings[h].id,
								inclusions: '',
								exclusions: '',
								description: '',
								addenda: ''
							};

							self.additionalBuildings[h].characteristics.push(newChar);
							objectChars[lang] = self.additionalBuildings[h].characteristics[self.additionalBuildings[h].characteristics.length - 1];
						}
					}
				}

				self.additionalBuildings[h].characteristics = objectChars;
			}
		}

		function getAdditionalBuildings() {
			PropertyService.getAdditionalBuildings(self.propertyId).then((res) => {
				self.additionalBuildings = res;
				getCharacteristicsFromAdditionalBuildings();
			})
			.catch((err) => {
				console.error(err);
			})
		}

		function getCountryAndProvince() {
			AccountService.getLocation()
			.then((res) => {
				country = res.country;
				province = res.province;
			})
			.catch((err) => { });
		}

		function setCurrentStatus(shared) {
			if(shared && shared.length > 0) {
				if(!self.isShared) {
					self.canCancel = true;
				}
				self.sharedStatus = shared[0].status;
			} else if(self.isSmart && !self.isArchived) {
				NavService.popStack();
				NavService.navigate('main.listings.detail.submenu', { submenu: 'smart', id: self.listingId, tab: self.index });
			}
		}

		$scope.getBuildingIndex = function(index) {
			return index + 1;
		}
	});
})(); // End of function()
