/* eslint-disable no-undef */
(function () {

  	'use strict';

  	angular.module('smartbrokr.file', [])

  	.service('FileService', function ($filter, $rootScope, $window, $http, LoopBackAuth, FileSaver, AlertService) {

		const self = this;

		const _FIELDS = {
			address:        'ADDRESS.ADDRESS',
			'address-listing': 'LISTING.LISTING',
			city:           'ADDRESS.CITY',
			'city-listing': 'LISTING.LISTING',
			company:        'PERSON.SUPPLIER.COMPANY',
			country:        'ADDRESS.COUNTRY',
			'country-listing': 'LISTING.LISTING',
			courses:        'PERSON.BROKER.COURSES',
			email:          'EMAIL.EMAIL',
			firstName:      'PERSON.FIRST_NAME',
			fullName:       'PERSON.NAME',
			industry:       'PERSON.SUPPLIER.INDUSTRY',
			info:           'COMMON.INFO',
			language:       'PERSON.LANGUAGE',
			lastName:       'PERSON.LAST_NAME',
			listingCoOwners: 'PERSON.SELLER.CO_OWNER',
			listingId:      'LISTING.LISTING_NO',
			listingType:    'LISTING.LISTING_TYPE',
			maxPrice:       'COMMON.MAX',
			minPrice:       'COMMON.MIN',
			office:         'ADDRESS.OFFICE',
			rate:           'PERSON.SUPPLIER.RATE',
			phones:         'PERSON.PHONE',
			'phones-fax':   'PERSON.PHONE_TYPES.FAX',
			'phones-home':  'PERSON.PHONE_TYPES.HOME',
			'phones-mobile':'PERSON.PHONE_TYPES.MOBILE',
			'phones-work':  'PERSON.PHONE_TYPES.WORK',
			photoUrl:       'PERSON.PHOTO_URL',
			postal:         'ADDRESS.POSTAL_CODE',
			'postal-listing': 'LISTING.LISTING',
			preApproved:    'PERSON.BUYER.PRE_APPROVED',
			preferredCities: 'PERSON.BUYER.PREFERRED_CITIES',
			propertyTypes:  'PROPERTY.PROPERTY_TYPE',
			province:       'ADDRESS.PROVINCE',
			'province-listing': 'LISTING.LISTING',
			regionsCovered: 'PERSON.BROKER.REGIONS',
			role:           'ROLES.ROLE',
			salutation:     'PERSON.SALUTATION',
			seekRental:     'PERSON.BUYER.SEEK_RENTAL',
			status:         'COMMON.STATUS',
			'status-listing': 'LISTING.LISTING'
		};

		const _FIELD_GETTERS = {
			address: function(entry) {
				const address = entry._address || (entry.user || {})._address || (entry.property || {})._address || (entry.owner || {})._address ||{};
				return '\"' + (address.addressString || '' )+ '\"';
			},
			'address-listing': function(entry) {
				const listing = (entry.listings || [])[0] || entry.listing || {};
				return _FIELD_GETTERS.address(listing);
			},
			city: function(entry) {
				const address = entry._address || (entry.user || {})._address || (entry.property || {})._address|| (entry.owner || {})._address || {};
				return (address.city || {}).name || '';
			},
			'city-listing': function(entry) {
				const listing = (entry.listings || [])[0] || entry.listing || {};
				return _FIELD_GETTERS.city(listing);
			},
			company: function(entry) {
				return entry.company || '';
			},
			country: function(entry) {
				const address = entry._address || (entry.user || {})._address || (entry.property || {})._address|| (entry.owner || {})._address || {};
				if ((address.country || {}).id === 'OTHER') {
					return address.country.name || '';
				}

				if (address.country) {
					return $filter('translate')('COUNTRIES.' + address.country.id);
				}
				return '';
			},
			'country-listing': function(entry) {
				const listing = (entry.listings || [])[0] || entry.listing || {};
				return _FIELD_GETTERS.country(listing);
			},
			courses: function(entry) {
				return _getListField(entry.courses || [], null);
			},
			email: function(entry) {
				return entry.email || (entry.user || {}).email || (entry.owner || {}).email || '';
			},
			firstName: function(entry) {
				return entry.firstName || (entry.user || {}).firstName || (entry.owner || {}).firstName || '';
			},
			fullName: function(entry) {
				return entry.fullName || (entry.user || {}).fullName || (entry.owner || {}).fullName || '';
			},
			industry: function(entry) {
				return ((entry.industry || {}).labels || {})[$rootScope.language || 'EN'] || ((entry.industry || {}).labels || {})['EN'] || '';
			},
			info: function(entry) {
				return entry.info || '';
			},
			language: function(entry) {
				const langs = entry.languages || (entry.user || {}).languages || (entry.owner || {}).languages || [];
				const langArr = langs.reduce((arr, lang) => {
					arr.push('' + $filter('translate')('LANGUAGES.' + lang.id));
					return arr;
				}, []);
				return '\"' + langArr.join(', ') + '\"';
			},
			lastName: function(entry) {
				return entry.lastName || (entry.user || {}).lastName || (entry.owner || {}).lastName || '';
			},
			listingCoOwners: function(entry) {
				const listing = (entry.listings || [])[0] || entry.listing || {};
				const sellers = (listing.sellers || []).reduce((arr, seller) => {
					if (!!seller.sbUserId && seller.sbUserId !== entry.sbUserId) {
						arr.push(seller.user.fullName);
					}
					return arr;
				}, []);
				return '"' + sellers.join(', ') + '"';
			},
			listingId: function(entry) {
				if (entry.property)
					return entry.id || '';
				const listing = (entry.listings || [])[0] || entry.listing || {};
				return listing.id || '';
			},
			listingType: function(entry) {
				let id;
				if (entry.property) {
					id = entry.id;
				}
				else {
					const listing = (entry.listings || [])[0] || entry.listing || {};
					id = listing.id;
				}
				if (!id)
					return '';

				return (id.includes('M') ? 'MLS' : 'Smart');
			},
			maxPrice: function(entry) {
				return entry.maxPrice || 0;
			},
			minPrice: function(entry) {
				return entry.minPrice || 0;
			},
			office: function(entry) {
				const office = entry.office || {};
				if (!office.code) return office.legalName || '';
				return (office.legalName || '') + ' (' + office.code + ')';
			},
			'phones-fax': function(entry) {
				const phones = entry.phones || (entry.user || {}).phones || (entry.owner || {}).phones || {};
				return phones.fax || '';
			},
			'phones-home': function(entry) {
				const phones = entry.phones || (entry.user || {}).phones || (entry.owner || {}).phones || {};
				return phones.home || '';
			},
			'phones-mobile': function(entry) {
				const phones = entry.phones || (entry.user || {}).phones || (entry.owner || {}).phones || {};
				return phones.mobile || '';
			},
			'phones-work': function(entry) {
				const phones = entry.phones || (entry.user || {}).phones || (entry.owner || {}).phones || {};
				return phones.work || '';
			},
			photoUrl: function(entry) {
				const photoUrl = entry.photoUrl || (entry.user || {}).photoUrl || '';
				return photoUrl;
			},
			postal: function(entry) {
				const address = entry._address || (entry.user || {})._address || (entry.property || {})._address || (entry.owner || {})._address || {};
				return address.postalCode || '';
			},
			'postal-listing': function(entry) {
				const listing = (entry.listings || [])[0] || entry.listing || {};
				return _FIELD_GETTERS.postal(listing);
			},
			preApproved: function(entry) {
				return entry.preApproved ? $filter('translate')('COMMON.YES') : $filter('translate')('COMMON.NO');
			},
			preferredCities: function(entry) {
				return _getListField(entry.cities || [], 'name');
			},
			propertyTypes: function(entry) {
				const _field = function(p) {
					return ((p || {}).labels || {})[$rootScope.language || 'EN'] || ((p || {}).labels || {}).EN || '';
				}
				return _getListField(entry.propertyTypes || [], _field);
			},
			province: function(entry) {
				const address = entry._address || (entry.user || {})._address || (entry.property || {})._address || (entry.owner || {})._address || {};
				if ((address.province || {}).id === 'CUSTOM') {
					return address.province.name || '';
				}
				return (address.province || {}).id || '';
			},
			'province-listing': function(entry) {
				const listing = (entry.listings || [])[0] || entry.listing || {};
				return _FIELD_GETTERS.province(listing);
			},
			rate: function(entry) {
				return entry.rate || '';
			},
			regionsCovered: function(entry) {
				return _getListField(entry.regionsCovered || [], 'name');
			},
			role: function(entry) {
				return ((entry.agencyRoleCode || {}).labels || {})[$rootScope.language || 'EN'] || entry.agencyRoleId || '';
			},
			salutation: function(entry) {
				return entry.salutation || (entry.user || {}).salutation || (entry.owner || {}).salutation || '';
			},
			seekRental: function(entry) {
				return entry.seekRental ? $filter('translate')('COMMON.YES') : $filter('translate')('COMMON.NO');
			},
			status: function(entry) {
				if (entry.user) {
					return '' + $filter('translate')('COMMON.' + entry.status);
				}
				if (entry.property) {
					return '' + $filter('translate')('LISTING.STATUS.' + (entry.status || '').toUpperCase());
				}
				return entry.status || '';
			},
			'status-listing': function(entry) {
				const listing = (entry.listings || [])[0] || entry.listing || {};
				return _FIELD_GETTERS.status(listing);
			},
		}

		const _CSV_TYPES = {
			agency: {
				title: 'ROLES.AGENCY',
				fields: [
					'fullName',
					'email',
					'phones-home',
					'phones-work',
					'phones-mobile',
					'phones-fax',
					'address',
					'city',
					'province',
					'country',
					'postal',
					'language',
					'role',
					'office'
				]
			},
			broker: {
				title: 'ROLES.BROKER',
				fields: [
					'firstName',
					'lastName',
					'status',
					'email',
					'phones-home',
					'phones-work',
					'phones-mobile',
					'phones-fax',
					'address',
					'city',
					'province',
					'country',
					'postal',
					'language',
					'courses',
					'regionsCovered',
				]
			},
			buyer: {
				title: 'ROLES.BUYER',
				fields: [
					'salutation',
					'firstName',
					'lastName',
					'email',
					'phones-home',
					'phones-work',
					'phones-mobile',
					'phones-fax',
					'language',
					'address',
					'city',
					'province',
					'country',
					'postal',
					'preApproved',
					'seekRental',
					'minPrice',
					'maxPrice',
					'propertyTypes',
					'preferredCities',
					'photoUrl'
				]
			},
			seller: {
				title: 'ROLES.SELLER',
				fields: [
					'salutation',
					'firstName',
					'lastName',
					'email',
					'phones-home',
					'phones-work',
					'phones-mobile',
					'phones-fax',
					'language',
					'address',
					'city',
					'province',
					'country',
					'postal',
					'listingId',
					'listingType',
					'status-listing',
					'address-listing',
					'city-listing',
					'province-listing',
					'country-listing',
					'postal-listing',
					'listingCoOwners',
					'photoUrl'
				]
			},
			supplier: {
				title: 'ROLES.SUPPLIER',
				fields: [
					'company',
					'industry',
					'rate',
					'info',
					'firstName',
					'lastName',
					'email',
					'phones-home',
					'phones-work',
					'phones-mobile',
					'phones-fax',
					'language',
					'address',
					'city',
					'province',
					'country',
					'postal',
					'photoUrl'
				]
			}
		}

		/**
		 *  Downloads a file related to a type of model.
		 *  @param  {string}    modelName     The name of the model, as used in its request URLs (usually plural form)
		 *  @param  {string}    mainId        The ID of the model that "owns" the file that will be downloaded
		 *  @param  {string}    fileId        The ID of the file to be downloaded
		 */
		self.download = function(modelName, mainId, fileId) {
			const baseUrl = $rootScope.baseURL;
			const params = {
				access_token: self.getAccessToken()
			};

			const apiURL = baseUrl + '/' + modelName + '/' + mainId + '/' + fileId + '/download';
			const url = [apiURL, $.param(params)].join('?');
			$window.open(url);
		}

		/**
		 *  Downloads files related to a type of model as a zip file.
		 *  @param  {string}    modelName     The name of the model, as used in its request URLs (usually plural form)
		 *  @param  {string}    mainId        The ID of the model that "owns" the file that will be downloaded
		 *  @param  {string}    fileIds        The IDs of the file to be downloaded
		 */
		 self.downloadMultiple = function(modelName, mainId, fileIds) {
			const baseUrl = $rootScope.baseURL;
			const params = {
				fileIds: fileIds,
				access_token: self.getAccessToken()
			};

			const apiURL = baseUrl + '/' + modelName + '/' + mainId + '/download-files';
			const url = [apiURL, $.param(params)].join('?');
			$window.open(url);
		}

		/**
		 *  Downloads a file that represents a document attached to a listing
		 *  @param  {string}    listingId     The ID of the Listing
		 *  @param  {string}    docId         The ID of the ListingDocument relation that the file belongs to
		 *  @param  {string}    fileId        The ID of the file that we want to download
		 */
		self.downloadDocument = function(listingId, docId, fileId) {
			const baseUrl = $rootScope.baseURL;
			const params = {
				access_token: self.getAccessToken()
			};

			const apiURL = baseUrl + '/Listings/' + listingId + '/documents/' + docId + '/' + fileId + '/download';
			const url = [apiURL, $.param(params)].join('?');
			$window.open(url);
		}

		/**
		 *  Exports an array to txt
		 *  @param  {object[]}    data    Array with data to be exported
		 *	@param  {string}      title   Title of the txt file to be created (without extension)
		*/
		self.exportTxt = function (data, title) {
			title = title + '-' + moment().format('YYYY-MM-DD') + '.txt';
			const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
			FileSaver.saveAs(blob, title);
			AlertService.success('FILES.FILE', 'download');
		};

		/**
		 *  Exports an array to csv
		 *	@param  {object[]}    data    Array with data to be exported
		*	@param  {string}      title   Title of the csv file to be created (without extension)
		*/
		self.exportCsv = function (data, title) {
			title = '' + $filter('translate')(title) + '-' + moment().format('YYYY-MM-DD') + '.csv';
			const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
			FileSaver.saveAs(blob, title);
			AlertService.success('FILES.FILE', 'download');
		};

		self.exportPropertySearch = function (data) {
			const lang = $rootScope.language;
			let text = '';

			const headers = [
				'ADDRESS.ADDRESS',
				'ADDRESS.REGION',
				'ADDRESS.PROVINCE_STATE',
				'ADDRESS.COUNTRY',
				'PROPERTY.PROPERTY_TYPE',
				'COMMON.PRICE',
				'PROPERTY.BEDS',
				'PROPERTY.BATHS',
				'PROPERTY.SQUARE_FOOTAGE',
				'LISTING.LISTING_TYPE',
				'COMMON.DETAIL',
				'COMMON.STATUS'
			]

			headers.forEach((header, i) => {
				text += '"' + $filter('translate')(header) + '"';
				if (i < headers.length - 1) {
					text += ',';
				}
			})

			text += '\n';

			let i;
			const length = data.length;
			for (i = 0; i < length; i++) {

				if (data[i].checked) {
					data[i].property = data[i].property || {};
					data[i].property._address = data[i].property._address || {};

					text += '"' + data[i].property._address.addressString + '",';
					text += '"' + (data[i].property._address.city || {}).name + '",';
					text += '"' + (data[i].property._address.province || {}).id + '",';
					text += '"' + (data[i].property._address.country || {}).name + '",';
					text += '"' + ((data[i].property.type || {}).labels || {})[lang || 'EN'] + '",';
					text += '"' + data[i].askingPrice + '",';
					text += '"' + data[i].property.numBedrooms + '",';
					text += '"' + data[i].property.numBathrooms + '",';
					text += '"' + data[i].property.buildingLivingArea + '",';
					text += '"' + (data[i].id.includes('S') ? 'Smart' : 'MLS') + '",';
					text += '"' + data[i].id + '",';

					if (data[i].status) {
						text += $filter('translate')('LISTING.STATUS.' + data[i].status.toUpperCase());
					}

					text += '\n';
				}
			}
			self.exportCsv(text, 'Property-Search');
		}

		self.exportConversation = function (contact) {
			const title = 'Conversation-' + contact.name;
			let text = '';
			const data = contact.messages;
			const userId = contact.users[1];

			for (let i = 0; i < data.length; i++) {
				text += (data[i].senderId == userId ? 'Me' : contact.name) + ' ';
				text += '(' + moment(data[i].created).format('dddd MMMM DD, YYYY, hh:mm a') + '): \n';
				text += data[i].text;

				if (data[i].files != undefined) {
					text += '\nFiles sent: ';

					for (const j in data[i].files) {
						text += data[i].files[j].originalFilename || data[i].files[j].name;

						if (j < data[i].files.length - 1) {
							text += ' | ';
						}
					}
				}

				text += '\n\n';
			}

			self.exportTxt(text, title);
		}

		self.exportListingsCsv = function(data, title) {
			const lang = $rootScope.language;
			let text = '';

			const headers = [
				'COMMON.DETAIL',
				'ADDRESS.ADDRESS',
				'ADDRESS.CITY',
				'ADDRESS.PROVINCE_STATE',
				'ADDRESS.COUNTRY',
				'DATES.EXPIRY_DATE',
				'PERSON.SELLER.SELLER_NAME',
				'PERSON.SELLER.SELLER_EMAIL',
				'PROPERTY.PROPERTY_TYPE',
				'PROPERTY.BEDS',
				'PROPERTY.BATHS',
				'PROPERTY.SQUARE_FOOTAGE',
				'COMMON.STATUS'
			]

			headers.forEach((header, i) => {
				text += '"' + $filter('translate')(header) + '"';
				if (i < headers.length - 1) {
					text += ',';
				}
			})

			text += '\n';

			let i;
			const length = data.length;

			for (i = 0; i < length; i++) {
				if (data[i].checked) {
					text += (data[i].id || '') + ',';
					text += '"' + (((data[i].property || {})._address || {}).addressString || '') + '",';
					text += ((((data[i].property || {})._address || {}).city || {}).name || '') + ',';
					text += ((((data[i].property || {})._address || {}).province || {}).id || '') + ',';
					text += ((((data[i].property || {})._address || {}).country || {}).name || '') + ',';
					text += '"' + $filter('dateFormat3')(data[i].expiry || '') + '",';

					if ((data[i].sellers || []).length > 0) {
						text += '"';
						for (let j = 0; j < data[i].sellers.length; j++) {
							text += ((data[i].sellers[j].user || {}).fullName || '');
							if (j == data[i].sellers.length - 1) {
								text += '",';
							}
							else {
								text += '\n';
							}
						}

						text += '"';
						for (let j = 0; j < data[i].sellers.length; j++) {
							text += ((data[i].sellers[j].user || {}).email || '');
							if (j == data[i].sellers.length - 1) {
								text += '",';
							}
							else {
								text += '\n';
							}
						}
					}
					else {
						text += ',,';
					}

					text += ((((data[i].property || {}).type || {}).labels || {})[lang || 'EN'] || '') + ',';
					text += ((data[i].property || {}).numBedrooms || '') + ',';
					text += ((data[i].property || {}).numBathrooms || '') + ',';
					text += ((data[i].property || {}).buildingLivingArea || '') + ',';

					if (data[i].status) {
						text += $filter('translate')('LISTING.STATUS.' + data[i].status.toUpperCase());
					}

					text += '\r\n';
				}
			}

			self.exportCsv(text, title);
		}

		self.exportManagersCsv = function(data) {
			let text = '';
			text += '"Name","Properties","Clients","Phone","Email"\n';

			data.forEach((item) => {
				if (item.checked) {
					item.manager = item.manager || {};
					text += item.manager.fullName + ',';

					if (item.allListings) {
						text += 'All listings,';
					}
					else {
						item.listings = item.listings || [];
						text += '"';

						item.listings.forEach((listing, i) => {
							text += ((listing.property || {})._address || {}).addressString || '';
							if (i < item.listings.length - 1) {
								text += '\n';
							}
						})

						text += '",';
					}

					if (item.allClients) {
						text += 'All clients,';
					}
					else {
						item.clients = item.clients || [];
						text += '"';

						item.clients.forEach((client, i) => {
							text += (client.fullName || '').trim();

							if (i < item.clients.length - 1) {
								text += '\n';
							}
						})

						text += '",';
					}

					text += $filter('getOnePhone')(item.manager.phones) + ',';
					text += item.manager.email + '\n';
				}
			})

			self.exportCsv(text,'Managers');
		}

		function _exportCsv(data, fields, title, type) {
			data    = data || [];
			fields  = fields || [];
			title   = title || 'CSV';
			const text = _getCsv(data, fields, type);
			self.exportCsv(text, title);
		}

		self.exportComplete = function(data, type) {
			data = data || [];
			if (!_CSV_TYPES[type]) return;
			_exportCsv(data, _CSV_TYPES[type].fields, _CSV_TYPES[type].title);
		}

		self.exportSimple = function(data, title, isSupplier) {
			const fields = [
				'firstName',
				'lastName',
				'email'
			]

			if (isSupplier)
				fields.push('company');

			_exportCsv(data, fields, title);
		}

		function _getCsv(data, fields, type) {
			let text = '';
			const headers = _getFields(fields);
			text += headers.join(',') + '\n';

			const entries = data.reduce((arr, entry) => {
				if (!entry.checked) return arr;

				arr.push(_createRow(entry, fields, type));
				return arr;
			}, []);

			text += entries.join('\n');
			return text;
		}

		function _createRow(entry, fields, type) {
			const ret = fields.reduce((arr, attr) => {
				if (_FIELD_GETTERS[attr]) {
					if(attr === 'photoUrl') {
						const value = _FIELD_GETTERS[attr](entry);
						if(value.includes('/files/') && ['buyer', 'seller', 'supplier'].includes(type)) {
							arr.push(self.getPublicImageUrl(entry.id, type));
						} else {
							arr.push(_FIELD_GETTERS[attr](entry));
						}
					} else {
						arr.push(_FIELD_GETTERS[attr](entry));
					}
				}
				return arr;
			}, []);
			return ret.join(',');
		}

		function _getListField(arr, field) {
			arr = arr || [];

			let ret = '"';

			ret += arr.reduce((str, curr, i) => {

				if (!field) {
					str += curr;
				}
				else if (typeof field === 'function') {
					str += field(curr);
				}
				else {
					str += curr[field];
				}

				if (i < arr.length - 1) {
					str += ', ';
				}
				return str;
			}, '');

			return ret + '"';
		}

		/**
		 *  Returns an array of translated column headers.
		 *  If we want a header with two translated values (such as Phones - Work),
		 *  the "parent" must be included in the fields object as well as the "child".
		 *  Example: "Phones (Work)" = translate(fields['phones']) + ' (' + translate(fields['phones-work']) + ')'
		 *  @param  {string[]}  attrs     An array of attribute names as saved in the _FIELDS constant
		 *  @return {string[]}            The translated column names from the equivalent labels saved in the _FIELDS constant
		 */
		function _getFields(attrs) {
			attrs = attrs || [];
			return attrs.reduce((arr, attr) => {
				arr.push(_getField(attr));
				return arr;
			}, []);

			function _getField(attr, isChild) {
				attr = attr || '';

				// Add parent as well as child
				if (attr.includes('-') && !isChild) {
					const parent = attr.split('-')[0];
					return _getField(parent) + ' (' + _getField(attr, true) + ')';
				}

				if (_FIELDS[attr]) {
					return '' + $filter('translate')(_FIELDS[attr]);
				}

				return '';
			}
		}

		self.getPublicImageUrl = function(id, type) {
			const baseUrl = $rootScope.baseURL;
			const firstLetter = type[0].toUpperCase();
			const things = type.slice(1);
			return baseUrl + '/' + firstLetter + things + 's/' + id + '/public/image';
		}

		self.getFolderName = function(folderName) {
			const folderNames = ['AGENCY_PAGE_LOGO', 'AGENCY_PAGE_HEADER', 'BROKER_PHOTO', 'COLLEAGUE_PHOTOS', 'CONTACT_PHOTO', 'FAVICON', 'HOME_PAGE_HEADER', 'HOME_PAGE_ABOUT_US', 'LOGO'];
			if(folderNames.includes(folderName)) return 'MARKETING.FOLDERS.' + folderName;

			const reportFolderNames = [
				'PAGES.REPORTS.SUB.LAST_MONTH_LISTINGS_BROKER',
				'PAGES.REPORTS.SUB.LAST_MONTH_SALES_BROKER',
				'PAGES.REPORTS.SUB.SALES_BY_REGION',
				'PAGES.REPORTS.SUB.LISTING_EXPIRY',
				'PAGES.REPORTS.SUB.YTD_LISTINGS',
				'PAGES.SETTINGS.SUB.SENT_EMAILS',
				'PAGES.REPORTS.SUB.INCOMPLETE_TASKS'
			];
			if(reportFolderNames.includes(folderName)) {
				const splitter = folderName.split('.');
				const name = splitter[splitter.length - 1];
				return 'PAGES.REPORTS.FOLDERS.' + name;
			}
			return folderName;
		}

		self.getAccessToken = function() {
			return LoopBackAuth.accessTokenId;
		}

		self.doImageUrlGrab = function(url) {
			const baseUrl = $rootScope.baseURL;
			return new Promise(
				(resolve, reject) => {
					if(url.includes('/files/')) {
						const splitter = url.split('/files/');
						const subUrl = splitter[1];
						// XMLHttpRequest and AJAX automatically follow redirects so fetch is the best option now
						// XHR is dead long live AJAX
						// AJAX is dead long live fetch
						const initUrl = baseUrl + '/Files/' + subUrl;
						const init = {
							method: 'GET',
							headers: {
								'X-Access-Token': self.getAccessToken()
							},
						};
						fetch(initUrl, init)
						.then(response => {
							if (!response.ok) {
								throw new Error('Network response was not ok');
							}
							return response.url;
						})
						.then(url => {
							resolve(url);
						})
						.catch((error) => {
							reject(error);
						});
					} else {
						return resolve(url);
					}
				}
			);
		}

		self.getImageUrl = function(url) {
			if(url.includes('/files/') && !url.includes('access_token')) {
				if(url.includes('http')) {
					return url + '?access_token=' + self.getAccessToken();
				} else {
					const baseUrl = $rootScope.baseURL;
					return baseUrl + url + '?access_token=' + self.getAccessToken();
				}
			}
			return url;
		}
	});
})(); // End of function()
