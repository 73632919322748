/* eslint-disable no-undef */
(function () {

	'use strict';

	/**
	 *  Originally created to upload assets in the Wordpress page.
	 *  Refactored and moved to modal folder so it can be used with other pages as well.
	 */
	angular.module('smartbrokr')
	.controller('WPImageUploadController', function ($uibModalInstance, $filter, $http, $location, $scope, $timeout, AlertService, FileService, ModalService, ResourceService, UploadService, dimensions, type, options) {

		const self = this;

		$scope.tooltip = $filter('translate')('RESOURCES.LANDSCAPE_TOGGLE');

		self.result             = null;
		$scope.landscape        = false;
		self.marketingImages    = [];
		self.marketingPopover   = '/js/src/modal/image-upload/image-popover.html';
		self.isPopoverOpen      = false;
		$scope.loadingText      = $filter('translate')('ALERT_MESSAGES.ALERT.LOADING_OVERLAY');
		$scope.type             = type;
		$scope.subtype          = options.subtype || null;
		self.folders = options.folders || [];
		self.bulkImageUpload = options.bulkImageUpload || false;
		self.fileResponseQueue = [];
		self.isMarketingDisabled = false;

		$scope.includeMarketingImages = typeof options.includeMarketingImages != 'undefined' ? options.includeMarketingImages : true;
		const isMediaManager      = type == 'media_manager';
		const isMarketingImage    = type == 'marketing-images';

		self.fileQueue = [];
		self.fileIdQueue = [];



		self.clearQueue = function() {
			self.isMarketingDisabled = false;
			self.fileQueue = [];
			self.fileIdQueue = [];
		}


		self.getFolderName = FileService.getFolderName;

		dimensions = dimensions || {};

		$scope.dimensions = {
			init: {
				w: dimensions.init_w || 60,
				h: dimensions.init_h || 60
			}
		};

		// Result was set in the dimensions
		if (dimensions.result_w || dimensions.result_h) {
			$scope.dimensions.result = {
				w: dimensions.result_w,
				h: dimensions.result_h
			}

			if (dimensions.result_w && dimensions.result_h) {
				if(['logo', 'secondary'].indexOf(type) >= 0) {
					dimensions.result_h++;
				}
				$scope.aspectRatio = dimensions.result_w / dimensions.result_h;
			}
		}
		// No set result size
		else {
			$scope.dimensions.result = 'max';
		}

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: {},
			clone: {}
		}

		if(isMarketingImage) {
			$scope.modalTitle = 'FILES.UPLOAD_PHOTO';
			self.uploader = UploadService.initUploadMarketingImage({ w: $scope.dimensions.result.w || 1900, h: $scope.dimensions.result.h || 600 }, options.currentFolder);
		} else if (!isMediaManager) {
			const sizeFilter = $scope.dimensions.result == 'max' ? null : $scope.dimensions.result;
			self.uploader = UploadService.initUserUploader(true, null, sizeFilter);
			$scope.modalTitle = 'WORDPRESS.ASSETS.MODAL_TITLE';
		} else {
			$scope.modalTitle = 'FILES.UPLOAD_PHOTO';
			self.uploader = UploadService.initMediaManagerUploader(false, options.currentFolder);
		}

		$scope.toggleAspectRatio = function() {
			$scope.landscape = !$scope.landscape;

			if($scope.landscape) {
				if($scope.dimensions.result === 'max' || !$scope.dimensions.result.h || !$scope.dimensions.result.w) {
					$scope.aspectRatio = $scope.dimensions.init.h / $scope.dimensions.init.w;
					$timeout(() => {
						$scope.dimensions.result = {
							w: $scope.dimensions.init.h,
							h: $scope.dimensions.init.w
						};
					}, 100);
				} else {
					$scope.aspectRatio = $scope.dimensions.result.h / $scope.dimensions.result.w;
					$timeout(() => {
						$scope.dimensions.result = {
							w: $scope.dimensions.result.h,
							h: $scope.dimensions.result.w
						};
					}, 100);
				}
			} else {
				if($scope.dimensions.result === 'max' || !$scope.dimensions.result.h || !$scope.dimensions.result.w) {
					$scope.aspectRatio = $scope.dimensions.init.w / $scope.dimensions.init.h;
					$timeout(() => {
						$scope.dimensions.result = {
							w: $scope.dimensions.init.w,
							h: $scope.dimensions.init.h
						};
					}, 100);
				} else {
					$timeout(() => {
						$scope.dimensions.result = {
							w: $scope.dimensions.init.h,
							h: $scope.dimensions.init.w
						};
					}, 100);
					$scope.aspectRatio = $scope.dimensions.init.h / $scope.dimensions.init.w;
				}
			}
		}

		_loadMarketing();

		self.uploader.onAfterAddingFile = function (item) {
			self.addError = false;
			// if (self.uploader.queue.length > 1) {
			// 	self.uploader.removeFromQueue(0);
			// }
			resizeImage(item);
			self.canConfirm = true;
		};

		self.uploader.onBeforeUploadItem = function (item) {
			let blob = self.croppedPhoto;
			blob = dataURItoBlob(blob);
			item._file = blob;
		}

		self.uploader.onWhenAddingFileFailed = function (item, filter) {
			if(self.fileQueue.length > 0 || self.fileIdQueue.length > 0) {
				if ((filter || {}).name == 'sizeFilter') {
					const h = dimensions.result_h || $scope.dimensions.init.h || '1';
					const w = dimensions.result_w || $scope.dimensions.init.w || '1';
					self.addError = $filter('translate')(self.fileQueue.length == 0 ? 'FILES.ALERT.TEMP_IMAGE_ENSURE_END' : 'FILES.ALERT.TEMP_IMAGE_ENSURE', { height: h, width: w, name: item.name });
				} else if ((filter || {}).name == 'sizeFilter') {
					self.addError = $filter('translate')('ALERT_MESSAGES.ALERT.FILE_FORMAT', { format: item.name.substring(item.name.indexOf('.')) });
				} else {
					self.addError = 'Previous image ' + item.name + ' cannot be added. Moving on to the next image';
				}
				$timeout(() => {
					self.addError = null;
					if(self.fileQueue.length > 0) {
						const currentFile = self.fileQueue.splice(0, 1);
						self.selectPhoto(null, currentFile[0]);
					} else {
						$uibModalInstance.close(self.fileResponseQueue);
					}
				}, 4000);
			} else if ((filter || {}).name == 'sizeFilter') {
				self.clearQueue();
				const h = dimensions.result_h || $scope.dimensions.init.h || '1';
				const w = dimensions.result_w || $scope.dimensions.init.w || '1';
				self.addError = $filter('translate')('FILES.ALERT.IMAGE_ENSURE', { height: h, width: w });
			} else {
				self.clearQueue();
				self.addError = $filter('translate')('ALERT_MESSAGES.ALERT.FILE_FORMAT', { format: item.name.substring(item.name.indexOf('.')) });
			}
		};

		self.selectPhoto = function(ev, photo) {
			if(ev) {
				if(ev.preventDefault) {
					ev.preventDefault();
				}
				if(ev.stopPropagation) {
					ev.stopPropagation();
				}
			}
			self.addError = false;

			photo.url = photo.url || '';

			if ($location.protocol() == 'http') {
				photo.url = photo.url.replace('https://', 'http://');
			}

			const req = {
				responseType: 'blob',
				cache: false
			}

			$http.get(photo.url, req).then((res) => {
				const data = res.data;
				let file = null;
				if (document.documentMode || /Edge/.test(navigator.userAgent)) {
					const blob = new Blob([data], { type: data.type });
					file = blobToFile(blob, photo.originalFilename);
				} else {
					file = new File([data], photo.originalFilename, { type: data.type });
				}
				self.isPopoverOpen = false;
				self.uploader.addToQueue(file, { data: [data], name: photo.originalFilename, type: data.type });
			});
		}

		self.selectPhotos = function(ev) {
			if(ev) {
				if(ev.preventDefault) {
					ev.preventDefault();
				}
				if(ev.stopPropagation) {
					ev.stopPropagation();
				}
			}

			if(self.fileQueue.length > 0) {
				const currentFile = self.fileQueue.splice(0, 1);
				self.isMarketingDisabled = true;
				self.isPopoverOpen = false;
				self.selectPhoto(ev, currentFile[0]);
			}
		}

		self.toggleFileQueue = function(ev, photo) {
			if(ev) {
				if(ev.preventDefault) {
					ev.preventDefault
				}
				if(ev.stopPropagation) {
					ev.stopPropagation();
				}
				if(ev.stopImmediatePropagation) {
					ev.stopImmediatePropagation();
				}
			}
			photo.queued = !photo.queued;

			if(self.fileIdQueue.indexOf(photo.id) > -1) {
				const fileIndex = self.fileIdQueue.indexOf(photo.id);
				self.fileIdQueue.splice(fileIndex, 1);
				self.fileQueue.splice(fileIndex, 1);
			} else {
				self.fileIdQueue.push(photo.id);
				self.fileQueue.push(photo);
			}
		}

		self.uploader.onSuccessItem = function (fileItem, response) {
			AlertService.doneLoading();
			if(!options.currentFolder && isMediaManager) {
				const modal = ModalService.openModal('createOrSelectFolder', { folder: function () { return { } }, type: function() { return 'media-manager' }, options: function() { return { fileId: response.id, folders: self.folders } } }, 'FolderController', 'folderController');
				modal.result.then(() => {
					$uibModalInstance.close(response);
				})
				.catch(() => {});
			} else {
				self.fileResponseQueue.push(response);
				if(self.fileQueue.length > 0) {
					const currentFile = self.fileQueue.splice(0, 1);
					self.selectPhoto(null, currentFile[0]);
				} else {
					if(self.bulkImageUpload) {
						$uibModalInstance.close(self.fileResponseQueue);
					} else {
						$uibModalInstance.close(response);
					}
				}
			}
		};

		self.confirmPhoto = function () {
			self.addError = false;
			AlertService.loading();
			self.uploader.uploadAll();
		}

		self.selectAll = function(folder) {
			for(let i = 0; i < folder.files.length; i++) {
				if(self.fileIdQueue.indexOf(folder.files[i].id) <= -1) {
					self.fileIdQueue.push(folder.files[i].id);
					self.fileQueue.push(folder.files[i]);
				}
			}
		}

		const resizeImage = function (item) {
			const reader = new FileReader();
			reader.onload = function (event) {
				$scope.$apply(() => {
					$scope.photo = event.target.result;
					$scope.bounds = {
						left: 100,
						top: 100
					};
					$uibModalInstance.check.original = $scope.photo;
				});
			};
			if(item._file instanceof Blob) {
				reader.readAsDataURL(item._file);
			} else {
				const blob = new Blob(item.data, { type: item.type });
				reader.readAsDataURL(blob);
			}
		}

		/**
		 * Upload Blob (cropped image) instead of file.
		 * @see
		 *   https://developer.mozilla.org/en-US/docs/Web/API/FormData
		 *   https://github.com/nervgh/angular-file-upload/issues/208
		 */

		/**
		 * Converts data uri to Blob. Necessary for uploading.
		 * @see
		 *   http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
		 * @param  {String} dataURI
		 * @return {Blob}
		 */
		const dataURItoBlob = function (dataURI) {
			const binary = atob(dataURI.split(',')[1]);
			const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
			const array = [];
			for (let i = 0; i < binary.length; i++) {
				array.push(binary.charCodeAt(i));
			}
			return new Blob([new Uint8Array(array)], { type: mimeString });
		};

		function _loadMarketing() {
			ResourceService.getMarketingImages().then((res) => {
				self.marketingImages = res || [];
			});
		}

		// to change Blob to File
		function blobToFile(theBlob, fileName) {
			const b = theBlob;
			b.lastModifiedDate = new Date();
			b.name = fileName;
			return b;
		}

	}) // End of controller
})(); // End of function()
