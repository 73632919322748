(function () {

  "use strict";

  angular.module("smartbrokr")

    .controller("EditPropertyController", function (check, $rootScope, $stateParams, AccountService, AlertService, GlobalVars, ListingService, ModalService, NavService, SellerService) {

      var self = this;

      // VARIABLES ============================================================================================================================

      self.person;      // Property owner
      self.property;    // Property that will be added

      self.propertyTypes; // Options for property type
      self.singleConfig;  // Dropdown config

      // SETUP VARIABLES ======================================================================================================================

      self.property = {};

      AlertService.loading();

      self.propertyTypes = GlobalVars.getCodes('propertyTypes');
      self.singleConfig = GlobalVars.singleConfig;

      SellerService.getUserAndSeller($stateParams.id).then(function (res) {
        self.seller = res;
        self.person = res.user;
      })
      .catch(function (err) {
        console.log("ERROR: ", err);
      })
      .finally(function () { AlertService.doneLoading();  })

      AccountService.getAccountDetails().then(function(res){
				self.property._address = {
					country: (((res.user || {})._address || {}).country || null),
					province: (((res.user || {})._address || {}).province || null)
        };
        
        check.original = angular.copy(self.property);
        check.clone = self.property;
			})

      // FUNCTIONS ============================================================================================================================

      self.existingListing = function() {
        var retFunction = function(result) {
          AlertService.loading();
          check.isSaved = true;

          var link = function(sellerId) {
            ListingService.linkSeller(result.listingId,sellerId).then(function(res){
              AlertService.success('ROLES.SELLER','addListing');
            })
            .catch(function (err) {})
            .finally(function () {
              AlertService.doneLoading();
              NavService.popStack();
              NavService.navigateStack(-1, null);
            })
          }

          if (!self.seller) {
            SellerService.saveSeller({sbUserId: self.person.user.id}).then(function(res){
              link(self.seller.id);
            })
            .catch(function (err) {})
          }
          else {
            link(self.seller.id);
          }
        }
        ModalService.openModalWithFunction('linkListing', {}, 'LinkListingController', 'linkListingController', retFunction);
      }

      // Saves/creates property and goes back to profile
      self.save = function () {

        var property = self.property;

        if (!(property._address || {}).addressString) {
          $rootScope.$broadcast('showWarnings', true);
        }
        else {
          check.isSaved = true;
          AlertService.loading();
        
          var goBack = function () {
            AlertService.doneLoading();
            AlertService.success('LISTING.LISTING','create');
            NavService.popStack();
            NavService.navigateStack(-1, null);
          }

          // New Listing
          var listing = {};
          listing.property = {};
          listing.status = 'Smart';
          listing.askingPrice = property.evalBuilding;
          listing.exclusive = true;

          ListingService.createListing(listing).then(function (list) {
            ListingService.addProperty(list.id, property).then(function (res) {

              var next = function () {
                ListingService.linkSeller(list.id, self.seller.id).then(function (linkSeller) {
                  goBack();
                })
                  .catch(function (err) {})
              }

              if (self.seller) {
                next();
              }
              else {

                var seller = {
                  person: angular.copy(self.person)
                }
                SellerService.saveSeller(seller).then(function (newSeller) {
                  self.seller = newSeller;
                  next();
                })
                .catch(function (err) {})
              }
            })
            .catch(function (err) {})
          })
          .catch(function (err) {})
        }

      };
    });

})(); // End of function()
