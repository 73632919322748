/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('PhotosController', function (AlertService, ListingService, $stateParams, UploadService, ModalService, $state, $q, $translate) {
		const self = this;

		// VARIABLES ============================================================================================================================

		self.id						= null;		// Listing ID
		self.photos 				= [];		// All photos

		self.options 				= null; 	// Specific options for each photo container bucket
		self.current 				= null;		// Current selected tab

		self.selected				= null;		// Object with selected photos
		self.selectedAll 			= false; 	// Whether all photos are currently selected
		self.rightSelected 			= false;	// At least one photo in the right column is selected
		self.leftSelected 			= false;	// At least one photo in the left column is selected
		self.currentlyDragging 		= false;

		self.transferring 			= false;	// If true, shows progress bar in the page
		self.uploader 				= null;		// Photo uploader object

		let photosCopy 				= [];		// Copy of current photos
		let del 					= null;		// Delete functions

		// SETUP VARIABLES ======================================================================================================================

		self.id 		= $state.params.id;
		self.uploader 	= UploadService.initListingUploader($stateParams.id);
		self.current 	= 'caravan';

		self.uploader.onCompleteAll = function () {
			loadPhotos();
		};

		self.selected = {
			left: {},
			right: {}
		};

		self.options = {
			caravan: {
				photos: [],
				min: 1,
				max: 5,
				link: ListingService.linkCaravanImage,
				unlink: ListingService.unlinkCaravanImage,
				load: ListingService.getCaravanImages,
				transferring: false,
				progress: 0
			},
			video: {
				photos: [],
				min: 1,
				max: -1,
				link: ListingService.linkVideoImage,
				unlink: ListingService.unlinkVideoImage,
				load: ListingService.getVideoImages,
				transferring: false,
				progress: 0
			},
			centris: {
				photos: [],
				min: 1,
				max: -1,
				load: ListingService.getMlsImages,
				transferring: false,
				progress: 0
			},
			website: {
				photos: [],
				min: 0,
				max: -1,
				link: ListingService.linkWebsiteImage,
				unlink: ListingService.unlinkWebsiteImage,
				load: ListingService.getWebsiteImages,
				transferring: false,
				progress: 0
			},
			feature: {
				photos: [],
				min: 1,
				max: 6,
				link: ListingService.linkFeatureImage,
				unlink: ListingService.unlinkFeatureImage,
				load: ListingService.getFeatureImages,
				transferring: false,
				progress: 0
			}
		}

		del = {
			file: ListingService.deletePhoto,
			caravan: ListingService.unlinkCaravanImage,
			feature: ListingService.unlinkFeatureImage,
			centris: ListingService.unlinkCentrisImage,
			website: ListingService.unlinkWebsiteImage,
			video: ListingService.unlinkVideoImage
		};

		loadPhotos();
		loadListingPhotos();

		// FUNCTIONS ============================================================================================================================

		function loadPhotos() {
			AlertService.loading();
			ListingService.getPhotos($stateParams.id)
			.then((res) => {
				self.photos = res;
				photosCopy = angular.copy(self.photos);
			})
			.catch((err) => {
				console.log('err: ', err);
			})
			.finally(() => {
				self.selected.left 	= {};
				self.leftSelected 	= false;
				AlertService.doneLoading();
			})
		}

		function loadListingPhotos() {
			self.selected.right = {};
			self.rightSelected 	= false;

			if (self.options[self.current].load) {
				AlertService.loading();
				self.options[self.current].load($stateParams.id)
				.then((res) => {
					self.options[self.current].photos = res;
					self.options[self.current].transferring = false;
				})
				.catch((err) => {})
				.finally(() => {
					AlertService.doneLoading();
				})
			}
		}

		const handleModalResponse = function (res) {
			switch(res.type) {
			case 'cb':
				return self.finishCaravanBulletin(res.data);
			case 'fs':
				return self.finishFeatureSheet(res.data);
			default:
				loadPhotos();
				loadListingPhotos();
			}

			return
		}

		function openModal(modalId, parameters, controller, controllerAs, windowClass) {
			const modal = ModalService.openModal(modalId, parameters, controller || 'PhotosModalsController', controllerAs || 'photosModalsController', null, windowClass);
			modal.result.then((res) => {
				handleModalResponse(res);
			})
			.catch((err) => {});
		}

		self.help = function() {
			ModalService.prompt('HELP.LISTING_PHOTOS', 'LISTING.LISTING_PHOTOS', null, 'COMMON.CLOSE', null, false, 'modal-header');
		}

		self.deletePhoto = function (photo, type, noModal) {
			const fileId = photo.fileId ? photo.fileId : photo.id;
			const delFunction = function() {
				AlertService.loading();
				del[type]($stateParams.id, fileId).then((res) => {
					if (!noModal) {
						loadPhotos();
						loadListingPhotos();
					}
				})
				.catch((err) => {})
				.finally(() => {
					AlertService.doneLoading();
				})
			};

			if (!noModal) {
				ModalService.prompt(
					'ALERT_MESSAGES.ALERT.DELETE_PHOTO',
					'COMMON.DELETE',
					'FORMS.CANCEL',
					'FORMS.CONFIRM',
					delFunction, false, 'modal-header'
				)
			}
			else {
				delFunction();
			}
		}

		self.switch = function (tab) {
			self.current = tab;
			loadListingPhotos();
		}

		self.handleDrop = function (object, event, secondPhoto, type, callback) {
			let i;
			// Check if we're doing a swap of the same type. Link to specific bucket/relation.
			if (object.type != type && object.type !== self.current) {
				//Check if we're at our max of the current bucket
				if (self.options[self.current].max == -1 || self.options[self.current].max > self.options[self.current].photos.length) {
					if (self.options[self.current].link) {
						return self.options[self.current].link($stateParams.id, object.data.id, secondPhoto ? secondPhoto.id : '');
					}
					else {
						AlertService.errorMessage('cannotAddMorePhotos');
						return Promise.reject();
					}
				}
				else {
					AlertService.errorMessage('cannotAddMorePhotos');
					return Promise.reject();
				}
			}
			//Same type -> reorder
			else if (object.type == type && secondPhoto) {
				const firstIndex 	= angular.copy(object.data.seq);
				const secondIndex = angular.copy(secondPhoto.seq);

				let photos = [];

				if (object.type == 'file') {
					photos = self.photos;
				}
				else {
					photos = self.options[self.current].photos;
				}

				const photosCopy = JSON.parse(JSON.stringify(photos));
				const splicedPhoto = photosCopy.splice(firstIndex - 1, 1);
				if(firstIndex > secondIndex) {
					photosCopy.splice(secondIndex - 1, 0, splicedPhoto[0]);
				} else {
					photosCopy.splice(secondIndex - 2, 0, splicedPhoto[0]);
				}
				photos = photosCopy;
				for(let i = 0; i < photosCopy.length; i++) {
					photosCopy[i].seq = i + 1;
				}
				return ListingService.savePhotos($stateParams.id, photos, object.type);
			}
			else {
				return Promise.reject();
			}
		}

		self.handleDropOne = function (object, event, secondPhoto, type, callback) {
			const call = self.handleDrop(object, event, secondPhoto, type, callback);
			if (call) {
				call.then((res) => {
					loadPhotos();
					loadListingPhotos();
					AlertService.success('FILES.IMAGE','transfer');
				})
				.catch((err) => {});
			}
		}

		self.resetSelected = function (isLeftSide) {
			if (isLeftSide) {
				self.selectedAll = false;
				self.selected.left = {};
				self.leftSelected = false;
				for (let i = 0; i < self.photos.length; i++) {
					self.photos[i].selected = false;
				}
			}
			else {
				self.selected.right = {};
				self.rightSelected = false;
				for (let i = 0; i < self.options[self.current].photos.length; i++) {
					self.options[self.current].photos[i].selected = false;
				}
			}
		}

		self.transferSelected = function () {
			self.options[self.current].transferring = true;
			self.options[self.current].progress = 0;

			const promises = [];

			let num = 0;
			for (const i in self.selected.left) {
				setupTransfer(self.selected.left[i], num++, promises);
			}
			serial(promises)
			.then((res) => {
				loadListingPhotos();
				AlertService.success('FILES.IMAGE','transfer');
			})
			.catch((err) => {

			})
			.finally(() => {
				self.resetSelected(true);
			});

		}

		const setupTransfer = function (photo, i, promises) {
			self.transferring = true;
			photo.type = 'upload';
			const promise = function () {
				const deferred = $q.defer();
				self.handleDrop({ data: photo, index: i, type: 'file' }, null, null, self.current)
				.then((res) => {
					self.options[self.current].progress = Math.ceil(((i + 1) / promises.length) * 100);
					deferred.resolve(res);
				})
				.catch((err) => {
					deferred.resolve(null);
				})
				return deferred.promise;
			};
			promises.push(promise);
		}

		function serial(tasks) {
			let prevPromise;
			const length = tasks.length - 1;
			angular.forEach(tasks, (task, i) => {
				//First task
				if (!prevPromise) {
					prevPromise = task();
				} else {
					prevPromise = prevPromise.then(task);
				}
			});
			return prevPromise;
		}


		self.transferAll = function () {
			const promises = [];
			self.options[self.current].transferring = true;
			self.options[self.current].progress = 0;

			for (let i = 0; i < self.photos.length; i++) {
				setupTransfer(self.photos[i], i, promises);
			}
			serial(promises)
			.then((res) => {
				AlertService.success('FILES.IMAGE','transfer');
				loadListingPhotos();
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				self.resetSelected(true);
			})
		}

		self.deleteSelected = function (isLeftSide) {
			const photos = [];

			if (isLeftSide) {
				for (const i in self.selected.left) {
					photos.push({ photo: self.selected.left[i], type: 'file' });
				}
			}
			else {
				for (const i in self.selected.right) {
					photos.push({ photo: self.selected.right[i], type: self.current });
				}
			}

			$translate('ALERT_MESSAGES.ALERT.DELETE_PHOTO', { num: photos.length > 1 ? photos.length : false }).then((msg) => {
				const delFunction = function() {
					const promises = [];

					const createPromise = function(item) {
						const promise = function() {
							const deferred = $q.defer();
							const fileId = item.photo.fileId ? item.photo.fileId : item.photo.id;

							del[item.type]($stateParams.id, fileId).then((res) => {
								deferred.resolve(res);
							})
							.catch((err) => {
								deferred.resolve(null);
							})
							return deferred.promise;
						}
						return promise;
					}

					for (const i in photos) {
						promises.push(createPromise(photos[i]));
					}

					serial(promises)
					.then((res) => {})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => {
						self.resetSelected();
						loadListingPhotos();

						if (isLeftSide) {
							self.resetSelected(true);
							loadPhotos();
						}
					})
				}

				ModalService.prompt(
					msg,
					'COMMON.DELETE',
					'FORMS.CANCEL',
					'FORMS.CONFIRM',
					delFunction, false, 'modal-header'
				)
			})
		}

		self.emailSelected = function (isLeftSide) {
			const ids = [];
			const files = [];

			if (isLeftSide) {
				for (const i in self.selected.left) {
					const file = self.selected.left[i];
					files.push(file);
				}
			}
			else {
				for (const i in self.selected.right) {
					const file = self.selected.right[i].file;
					files.push(file);
				}
			}

			const resolve = {
				params: function() {
					return {
						files: files
					}
				}
			}

			self.emailModal = ModalService.openModal('sendEmail', resolve, 'SendEmailController', 'sendEmailController', null, 'email-modal');

			self.emailModal.result
			.then((res) => {})
			.catch((err) => {})
			.finally(() => {
				self.resetSelected(isLeftSide);
			});
		}


		self.downloadSelected = function (isLeftSide) {
			const photos = [];

			if (isLeftSide) {
				for (const i in self.selected.left) {
					const file = self.selected.left[i].file ? self.selected.left[i].file.id : self.selected.left[i].id;
					photos.push(file);
				}
			}
			else {
				for (const i in self.selected.right) {
					const file = self.selected.right[i].file ? self.selected.right[i].file.id : self.selected.right[i].id;
					photos.push(file);
				}
			}

			ListingService.downloadPhotos($stateParams.id, photos.toString());
			self.resetSelected(isLeftSide);
		}

		self.downloadSingle = function (photo) {
			ListingService.downloadPhotos($stateParams.id, photo.id);
		}

		self.selectPhoto = function (photo, i, isLeftSide, toggle) {
			photo.selected = toggle ? !photo.selected : true;//!photo.selected;
			const side = isLeftSide ? self.selected.left : self.selected.right;

			if (!side[photo.id]) {
				side[photo.id] = photo;

				if (isLeftSide) {
					self.leftSelected = true;
				}
				else {
					self.rightSelected = true;
				}
			}
			else {
				delete side[photo.id];
				if (Object.keys(side) === 0) {
					if (isLeftSide) {
						self.leftSelected = false;
					}
					else {
						self.rightSelected = false;
					}
				}
			}
		}

		self.viewPhoto = function (photo) {
			ModalService.openModal('viewImage', {
				media: function () { return photo; },
				mediaType: function () { return 'img'; },
				sbDownload: function() { return self.downloadSingle }
			}, 'MediaController', 'mediaController');
		}

		self.createVideo = function () {
			openModal('createVideo', {
				type: () => 'listing',
				data: () => null,
				options: () =>  { return { listingId: self.id, selectListing: false } }
			}, 'CreateVideoController', 'createVideoController', 'video-modal');
		}

		self.createCaravanBulletin = function () {
			openModal('createMarketingPdf', {
				type: function () { return 'RESOURCES.CARAVAN_BULLETIN'; },
				data: function () { return null }
			});
		}

		self.createFeatureSheet = function () {
			openModal('createMarketingPdf', {
				type: function () { return 'RESOURCES.FEATURE_SHEET'; },
				data: function () { return null },
			});
		}

		self.finishCaravanBulletin = function (cb) {
			openModal('finishedCreation', {
				type: function () { return 'RESOURCES.CARAVAN_BULLETIN' },
				data: function () { return cb },
			});
		}

		self.finishFeatureSheet = function (fs) {
			openModal('finishedCreation', {
				type: function () { return 'RESOURCES.FEATURE_SHEET' },
				data: function () { return fs },
			});
		}

		self.selectAll = function (bool) {
			self.selectedAll = bool;
			self.leftSelected = bool;
			self.photos.forEach((photo, i) => {
				self.selectPhoto(photo, i, true, !bool);
			})
		}
		self.savePhotos = function (photos, type) {
			ListingService.savePhotos($stateParams.id, photos, type)
			.then((res) => {
				AlertService.success('FILES.PHOTOS','save', true);
			})
			.catch((err) => {})
		}

		self.editSelected = function () {
			openModal('editPhoto', {
				files: function () { return self.selected.left }
			}, 'EditPhotoController', 'editPhotoController');
		}
	})
})();
