(function () {

    "use strict";

    angular.module("smartbrokr")

    .controller("ListingResourcesController", function (AlertService, DocusignService, FileService, ListingService, ModalService, UploadService, $log, $state, $stateParams) {

        var self = this;

        self.resources  = [];
        self.length     = 0;
        self.type       = $state.current.params.type;

        self.docusign = function(file) {
            AlertService.loading();
            DocusignService.sendDocument(file.id).then(function(res) {
                AlertService.doneLoading();
                AlertService.successMessage('envelope');
                return DocusignService.popup(res.data.url);
            })
            .catch(function(err) {
                AlertService.doneLoading();
                const message = err.data.error.message;
                if(message.indexOf('sent') > -1) {
                    AlertService.errorMessage('envelopeExists');
                } else if(message.indexOf('integrated') > -1) {
                    AlertService.errorMessage('docusignDisconnected');
                } else {
                    AlertService.errorMessage('envelopeCreate');
                }
                $log.error(err);
            });
        }

        self.options = {
            marketing: {
                load: ListingService.getMarketing,
                setupUploader: UploadService.initMarketingUploader,
                del: ListingService.deleteMarketing,
                uploader: null,
                title:"LISTING.DETAILS_MENU.MARKETING"
            },
            resources: {
                load: ListingService.getFolders,
                setupUploader: UploadService.initFileFolderUploader,
                del: ListingService.deleteFromFolder,
                uploader: null,
                deleteFunction: deleteFromFolder,
                title:"FILES.FILES",
                setUrl: function(folderId) {
                    if (this.uploader) {
                        this.uploader.url = UploadService.baseUrl + "/Folders/" + folderId + "/uploadFile";
                    }
                },
                editFunction: editFolder,
                download: function(file, folder) {
                    file = file || {};
                    folder = folder || {};
                    FileService.download('Folders', folder.id, file.id);
                },
                docusign: self.docusign,
                docusignConnected: false,
                deleteFolder: deleteFolder
            }
        }


        DocusignService.getStatus().then(function(res) {
            self.options.resources.docusignConnected = res.connected;
        })
        .catch(function(err) {
            $log.error(err);
        });


        self.current = self.options[$state.current.params.type];
        if (self.current) {
            self.current.uploader = self.current.setupUploader($stateParams.id);
            self.current.uploader.onSuccessItem = function (fileItem, response, status, headers) {
                load();
            };
            load();
        }

        self.saveFolder = function(folder) {
            var fields = [
                {
                    label: 'PERSON.NAME',
                    model: 'name',
                    required: true,
                    inputType: 'text'
                }
            ]

            folder = folder || {};

            var m = ModalService.openForm(fields, 'FILES.NEW_FOLDER', true, folder);
            m.then(function(res) {
                if (res) {
                    ListingService.saveFolder($stateParams.id, res).then(function(res) {
                        load();
                    })
                }
            })
            .catch(function(err) {})
        }

        self.deleteItem = function (fk, i) {
            self.current.del($stateParams.id, fk)
            .then(function (res) {
                if (res) {
                    load();
                }
            })
            .catch(function (err) {});
        }

        function editFolder(event, folder) {
            event.stopPropagation();
            event.preventDefault();
            self.saveFolder(folder);
        }

        function deleteFromFolder(folderId, fileId) {
            ListingService.deleteFromFolder(folderId, fileId).then(function(res) {
                if (res) {
                    load();
                }
            })
        }

        function deleteFolder(folderId) {
            ListingService.deleteFolder($stateParams.id, folderId).then(function(res) {
                if (res) {
                    load();
                }
            })
        }

        self.download = function(file) {
            file = file || {};
            FileService.download('Listings', $stateParams.id, file.id);
        }

        function load() {
            AlertService.loading();
            self.current.load($stateParams.id)
            .then(function (res) {
                self.resources = res;
                self.length = res.length;
            })
            .catch(function (err) {})
            .finally(AlertService.doneLoading)
        }
    });
})(); // End of function()
