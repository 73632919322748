/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(function () {

	'use strict';

	angular.module('smartbrokr')

	.controller('VisitController', function ($filter, $rootScope, $sce, $scope, $stateParams, $uibModalInstance, AlertService, AccountService, GlobalVars, ListingService, ModalService, VisitService, UserService, visit, isAppointment, isGoogle) {

		const self = this;

		// VARIABLES ============================================================================================================================

		$scope.loading      = true;   // Whether we are loading users (longest operation)

		self.hours          = null;   // Options with possible hours for the visit
		self.timeAux        = null;   // Selected time for the visit -> Will be added to the selected date to create one Date object
		self.people         = [];     // People who will be added to the visit

		self.calendar       = null;   // Object with all the options for uib-datepicker

		self.timeConfig     = null;   // Config for dropdown list
		self.multiConfig    = null;   // Config for dropdown list (multiple selections)

		self.visit          = null;   // Visit object

		self.listing        = null;   // Listing for which visit will be scheduled
		self.users          = [];     // All possible people to come to the visit
		self.listings       = [];     // All agency listings
		self.showListing    = null;   // Whether to show the option of adding a listing to the visit
		self.isGoogle 		= isGoogle;
		let role;                     // Current user's role

		// SETUP VARIABLES ======================================================================================================================

		role              = AccountService.getRole();
		self.showListing  = role === 'brokerProfile' || role === 'managerProfile' || role === 'adminProfile';
		self.hours        = angular.copy(GlobalVars.hours);
		self.timeConfig   = GlobalVars.createSingleConfig(false, 'value', 'name', 'name', false, null, 'order');
		self.multiConfig  = GlobalVars.createMultiConfig(false, 'index', 'fullName', true, 'hidden', 'search');
		self.calendar     = GlobalVars.createCalendarConfig(null);
		const NO_ADDRESS    = $filter('translate')('ADDRESS.NO_ADDRESS');

		self.timeConfig.onFocus = function() {    // So calendar closes when 'time' input is focused through tabbing
			self.calendar.opened = false;
			$scope.$apply();
		}

		if (visit != null) {
			self.visit = angular.copy(visit);

			if (!!visit.scheduled) {
				self.visit.scheduled = new Date(visit.scheduled);
			}

			self.listing = self.visit.location;

			const time = self.visit.scheduled;

			if (time != null && time != undefined) {

				let i, length = self.hours.length;

				for (i = 0; i < length; i++) {
					if (self.hours[i].value.getHours() == time.getHours() && self.hours[i].value.getMinutes() == time.getMinutes()) {
						self.timeAux = self.hours[i].value;
						break;
					}
				}
			}
		}
		else {
			self.visit = {
				listingId: $stateParams.id,
				type: 'V',
				scheduled: null
			};
		}

		$uibModalInstance.scope = $scope;
		$uibModalInstance.check = {
			original: angular.copy(self.visit),
			clone: self.visit
		}

		if (self.visit.listingId && !self.visit.location) {
			VisitService.getListing(self.visit.listingId)
			.then((res) => {
				self.listing = res;
			})
			.catch((err) => {
				console.error('Error getListing(): ', err);
			});
		}

		if (!self.visit.listingId || isAppointment) {
			$scope.loadListings = true;
			ListingService.getActiveAndSmart()
			.then((res) => {
				self.listings = res.reduce((arr,curr) => {

					let address = ((curr.property || {})._address || {}).addressString || '';

					if (address.toLowerCase().includes('no address')) {
						address = address.replace(/no address/gi, NO_ADDRESS);
					}

					if (address.toLowerCase().includes('no number')) {
						address = address.replace(/no number,/gi, '');
					}

					if (((curr.property || {})._address || {}).addressString) {
						curr.property._address.addressString = address;
					}

					arr.push(curr);

					return arr;
				}, []);


			})
			.catch((err) => {
				console.error('Error getActiveAndSmart(): ', err);
			})
			.finally(() => {
				AlertService.doneLoading();
				$scope.loadListings = false;
			})
		}

		UserService.getAllMine(false, false, true).then((res) => {
			let i, resLength = res.length;

			for (i = 0; i < resLength; i++) {
				let add = false;
				const toPush = {
					buyerId: res[i].coBuyer ? res[i].buyerId : null,
					coBuyer: res[i].coBuyer,
					fullName: res[i].fullName,
					id: res[i].id,
					email: res[i].email,
					search: res[i].fullName,
					emailEnabled: res[i].emailEnabled
				}
				if (res[i].buyerProfile) {
					toPush.role = 'Buyer';
					add = true;
				}
				else if (res[i].sellerProfile) {
					toPush.role = 'Seller';
					add = true;
				}
				else if (res[i].brokerProfile) {
					toPush.role = 'Broker';
					add = true;
				}
				else if (res[i].notaryProfile) {
					toPush.role = 'Notary';
					add = true;
				}
				else if (res[i].auditorProfile) {
					toPush.role = 'Auditor';
					add = true;
				}
				else if ((res[i].ownedSuppliers || []).length > 0) {
					toPush.fullName += ' (' + res[i].ownedSuppliers[0].company + ')';
					toPush.company = res[i].ownedSuppliers[0].company;
					toPush.supplierId = res[i].ownedSuppliers[0].id;
					toPush.role = 'Supplier';
					toPush.hidden = ((res[i].ownedSuppliers[0].industry || {}).labels || {})[$rootScope.language || 'EN'] || ((res[i].ownedSuppliers[0].industry || {}).labels || {})['EN'];
					toPush.search = toPush.fullName + '|' + toPush.hidden;
					add = true;
				}
				if (add) {
					self.users.push(toPush);
					self.users[self.users.length - 1].index = self.users.length - 1;
				}
			}

			const length = self.users.length;

			if (((visit || {}).users || []).length > 0) {
				for (let j = 0; j < visit.users.length; j++) {
					for (i = 0; i < length; i++) {
						if (visit.users[j].id === self.users[i].id) {
							self.people.push(i);
							break;
						}
					}

					// if (visit.users[j].id === self.users[i].id) {
					// 	break;
					// }
				}
			}

			if(((visit || {}).coBuyers || []).length > 0) {
				for(let j = 0; j < visit.coBuyers.length; j++) {
					for(i = 0; i < length; i++) {
						if(visit.coBuyers[j]._coBuyer.id === self.users[i].id) {
							self.people.push(i);
							break;
						}
					}
				}
			}
			$scope.loading = false;
		})
		.finally(AlertService.doneLoading)

		// FUNCTIONS ============================================================================================================================

		self.close = function () {
			$uibModalInstance.dismiss('cancel');
		};

		self.createVisit = function () {
			self.disableButton = true;
			AlertService.loading();
			const isUpdate = !!self.visit.id;
			let toUnlink = [];
			let toCoLink = [];
			let toLink = [];
			let toUnCoLink = [];
			const toSave = angular.copy(self.visit);
			let emailessUsers = [];

			const finish = function (res) {
				AlertService.doneLoading();
				$uibModalInstance.close(res);
			}

			console.log('users', self.visit.users);

			if(self.visit.users) {
				emailessUsers = self.visit.users.filter((user) => !user.email);
			}

			console.log('emailessUsers', emailessUsers);

			if (emailessUsers.length > 0) {
				let msg = $filter('translate')('ALERT_MESSAGES.ALERT.APPT_NO_EMAIL');
				msg = $sce.valueOf(msg);

				msg += '<ul style="list-style: disc outside; padding-left: 18px">';

				emailessUsers.forEach((user) => {
					msg += `<li>${user.fullName}</li>`;
				})

				msg += '</ul>';

				const mod = ModalService.prompt(msg, null, null, 'Ok', null, true);
				mod.result.then((res) => {})
				.catch((err) => {})
				.finally(() => {
					finish(visit);
				});

				AlertService.doneLoading();

				return;
			}

			const notifyRecipients = toSave.notifyRecipients;
			delete toSave.notifyRecipients;

			if(self.visit.scheduled && self.timeAux) {
				const date = moment(self.visit.scheduled);
				const time = moment(new Date(self.timeAux));
				date.hours(time.hours());
				date.minutes(time.minutes());

				toSave.scheduled = date.toDate();
				toSave.scheduledTZ = moment.tz.guess();
			}

			if(self.listing) {
				toSave.location = (((self.listing || {}).property || {})._address || {}).addressString || self.listing;

				if (!toSave.listingId && !!self.listing) {
					toSave.listingId = (self.listing || {}).id || null;
				}
			}

			if (toSave.people) {
				delete toSave.people;
			}

			const reduceCoLink = function(reduced, item, i) {
				let add = true;

				for(let j = 0; j < (toSave.coBuyers || []).length; j++) {
					if(!self.users[item].coBuyer || !self.users[item].buyerId || ((toSave.coBuyers[j] || {})._coBuyer || {}).id == self.users[item].id) {
						add = false;
						break;
					}
				}

				if(add) {
					reduced.push(item);
				}

				return reduced;
			}

			const reduceLink = function (reduced, item, i) {
				let add = true;
				for (let j = 0; j < (toSave.users || []).length; j++) {
					if (toSave.users[j].id == self.users[item].id || self.users[item].coBuyer) {
						add = false;
						break;
					}
				}

				if (add) {
					reduced.push(item);
				}

				return reduced;
			}

			const reduceUnlink = function (reduced, item, i) {

				let add = true;

				for (let j = 0; j < self.people.length; j++) {
					if (item.id == self.users[self.people[j]].id) {
						add = false;
						break;
					}
				}

				if (add) {
					reduced.push(item);
				}

				return reduced;
			}

			const reduceCoUnlink = function (reduced, item, i) {
				let add = true;

				for(let j = 0; j < (self.people || []).length; j++) {
					if((item._coBuyer || {}).id == self.users[self.people[j]].id) {
						add = false;
						break;
					}
				}

				if(add) {
					reduced.push(item);
				}

				return reduced;
			}

			if ((toSave.users || []).length > 0 && toSave.id) {
				toCoLink = self.people.reduce(reduceCoLink, []);
				toLink = self.people.reduce(reduceLink, []);
				toUnCoLink = toSave.coBuyers.reduce(reduceCoUnlink, []);
				toUnlink = toSave.users.reduce(reduceUnlink, []);
			}
			else {
				toLink = self.people.reduce((reduced, item, i) => {
					if(self.users && !self.users[item].coBuyer) {
						reduced.push(item);
					}
					return reduced;
				}, []);
				toCoLink = self.people.reduce((reduced, item, i) => {
					if(self.users && self.users[item].coBuyer) {
						reduced.push(item);
					}
					return reduced;
				}, []);
			}

			VisitService.saveVisit(toSave).then((res) => {
				if (!$scope.addNote) {
					self.newNote = null;
				}
				VisitService.linkUnlinkAndNote(res, self.users, toLink, toUnlink, toCoLink, toUnCoLink, self.newNote, notifyRecipients).then((visit) => {
					if((!toLink || toLink.length === 0) && (!toCoLink || toCoLink.length === 0)) return finish(visit);
					let emailNotSent = [];
					if(toLink && toLink.length > 0) {
						emailNotSent = toLink.reduce((arr,i) => {
							const currPerson = self.users[i];
							if (!currPerson.emailEnabled || !currPerson.email || currPerson.email === '') {
								arr.push(currPerson.fullName);
							}
							return arr;
						},[]);
					}

					if(toCoLink && toCoLink.length > 0) {
						const reduced = toCoLink.reduce((arr, i) => {
							const currPerson = self.users[i];
							if (!currPerson.emailEnabled || !currPerson.email || currPerson.email === '') {
								if(!Array.isArray(arr)) {
									arr = [i];
								} else {
									arr.push(currPerson.fullName);
								}
							}
							return arr;
						});
						emailNotSent.concat(reduced);
					}

					if(emailNotSent.length === 0) return finish(visit);

					AlertService.doneLoading();

					let msg = $filter('translate')('ALERT_MESSAGES.ALERT.APPT_NO_EMAIL');
					msg = $sce.valueOf(msg);

					msg += '<ul style="list-style: disc outside; padding-left: 18px">';

					emailNotSent.forEach((name) => {
						msg += '<li>' + name + '</li>';
					})

					msg += '</ul>';

					const mod = ModalService.prompt(msg, null, null, 'Ok', null, true);
					mod.result.then((res) => {})
					.catch((err) => {})
					.finally(() => {
						finish(visit);
					});
				})
			});
			// }
		}

		/** Searches for Listings within the Agency based on a provided address */
		self.searchListing = function (searchValue, searchId) {

			if (!searchValue)
				return [];

			const categories = { id: true };

			if (!searchId) {
				categories['property._address.addressString'] = true;
			}

			searchValue = searchValue.toLowerCase().replace(/\.|,|-/g,'');
			return $filter('searchSingleFilter')(self.listings, searchValue, categories);
		}

		/** Searches for a Person in the system based on a name */
		self.searchPerson = function (value) {

			$scope.loading = loading;
			value = value.toLowerCase();
			const ret = [];

			if (!loading) {
				return $filter('searchSingleFilter')(self.users, value, { fullName: true, hidden: true });
			}

			return ret;
		}


	}) // End of controller
})(); // End of function()
