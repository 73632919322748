/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/**
 *  Menu Service
 */

(function () {

	'use strict';

  	angular.module('smartbrokr.menu', [])

	.service('MenuService', function (BookmarkService, MessageService, $rootScope, AccountService, $sessionStorage, MenuFactory, socket) {
		const self = this;

		// VARIABLES ========================================================================================================================

		self.menu           = [];         // Main menu
		self.navStack       = null;       // Navigation stack -> Used for headers

		const SELLER_INDEX    = 'seller';   // Index of sellers item in the menu
		const BUYER_INDEX     = 'buyer';    // Index of buyers item in the menu

		// LOAD VARIABLES ===================================================================================================================

		self.navStack = $sessionStorage.navStack;

		$rootScope.BUYER_INDEX = BUYER_INDEX;
		$rootScope.SELLER_INDEX = SELLER_INDEX;

		socket.on('notification-new', _getNotifications);
		socket.on('notification-update', _getNotifications);
		socket.on('message-new', _getMessages);
		socket.on('message-update', _getMessages);

		// FUNCTIONS ========================================================================================================================
		/**
		 *  Initiates a new menu based on the user's current role
		 */
		self.initMenu = function () {
			const role = AccountService.getRole();

			self.menu = MenuFactory.getMenu(role);

			function resolve(i) {
				self.menu[i].submenu.content.then((res) => {
					self.menu[i].submenu.content = res;
				})
			}

			for (const i in self.menu) {
				if (((self.menu[i].submenu || {}).content || {}).then) {
					resolve(i);
				}
			}

			_getNotifications();
			_getMessages();

			return self.menu;
		}

		self.getAdminMenu = function() {
			return MenuFactory.getAdminMenu();
		}

		self.getSellerIndex = function () {
			return SELLER_INDEX;
		}

		self.getBuyerIndex = function () {
			return BUYER_INDEX;
		}

		function getMenuItemSubmenu(name) {
			return (self.menu[name] || {}).submenu || null;
		}

		self.getSubSub = function(type, profileType) {
			return MenuFactory.getSubSub(AccountService.getRole(), type, profileType);
		}

		$rootScope.$on('updateTasks', () => {
			self.menu.tasks.submenu.getContent().then((res) => {
				self.menu.tasks.submenu.content = res;
			})
		})

		function _getNotifications() {
			const role = AccountService.getRole();
			if (AccountService.loggedIn() && self.menu.notifications && role !== 'sellerProfile') {
				MessageService.getNotificationCount(true).then((res) => {
					self.menu.notifications.count = res.count;
				});
			}
		}

		function _getMessages() {
			const role = AccountService.getRole();
			if (AccountService.loggedIn() && self.menu.messages && role !== 'sellerProfile') {
				MessageService.getUnreadCount().then((res) => {
					self.menu.messages.count = res.count;
				})
				.catch((err) => {})
			}
		}

		// BOOKMARKS ========================================================================================================================

		self.addBookmark = function(bookmark, isNew) {
			const bookmarks = getMenuItemSubmenu('bookmarks');

			if (isNew) {
				bookmarks.content.unshift(bookmark);
			}

			self.editBookmarks();
		}

		self.deleteBookmark = function (i) {
			const bookmarks = getMenuItemSubmenu('bookmarks').content;
			BookmarkService.deleteBookmark(bookmarks[i].id)
			.then((res) => {
				bookmarks.splice(i, 1);
			})
			.catch((err) => { })
		}

		self.editBookmarks = function () {
			const bookmarks = getMenuItemSubmenu('bookmarks').content;

			bookmarks.forEach((bookmark, i) => {
				bookmark.seq = i;
				BookmarkService.saveBookmark(bookmark)
				.then(() => {})
				.catch((err) => {});
			})
		}
	}) // End of service

	.factory('MenuFactory', (AccountService, BookmarkService, TaskService, $filter, $log, $q, $sce) => {

		const ROLES = {
			admin: [ 'adminProfile' ],
			broker: [ 'brokerProfile' ],
			broker_manager: [ 'managerProfile', 'brokerProfile' ],
			owner: [ 'agencyOwnerProfile' ],
			agency_roles: [ 'agencyMemberProfile', 'agencyOwnerProfile' ],
			all: [ 'agencyMemberProfile', 'agencyOwnerProfile', 'managerProfile', 'brokerProfile', 'brokerProfile', 'notaryProfile', 'auditorProfile', 'adminProfile' ], //Anyone Authenticated
			agency_all: [ 'agencyMemberProfile', 'agencyOwnerProfile', 'managerProfile', 'brokerProfile' ],
			agency_no_manager: [ 'agencyMemberProfile', 'agencyOwnerProfile', 'brokerProfile' ],
			auditor_like: [ 'auditorProfile', 'notaryProfile' ],
			broker_owner: [ 'brokerProfile', 'managerProfile', 'agencyOwnerProfile' ],
			seller: [ 'sellerProfile' ]
		}

		const ICONS = {
			search: 'iconSearch.png',
			dashboard: 'iconDashboard.png',
			notifications: 'iconNotifications.svg',
			messages: 'iconMessages.png',
			calendar: 'iconCalendar.png',
			tasks: 'iconTasks.png',
			bookmarks: 'iconBookmarks.png',
			resources: 'iconResources.png',
			reports: 'iconReports.png',
			agency: 'iconAgency.png',
			broker: 'iconBroker.png',
			buyer: 'iconBuyer.png',
			seller: 'iconSeller.png',
			listings: 'iconListings.png',
			supplier: 'iconSupplier.png',
			help: 'iconHelp.png',
			settings: 'iconSettings.png',
			codes: 'iconResources.png',
			wordpress: 'iconWordpress.svg',
			email: 'sent-emails.svg'
		}

		function menuItem(order, title, link, icon, baseObj) {
			const ret = baseObj || {};
			ret.order = order || 1;
			ret.title = title || null;
			ret.link = link || null;
			ret.icon = icon || null;

			return ret;
		}

		function submenu(content, baseTitle, baseLink, baseObj, role) {
			content = content || [];
			const ret = baseObj || {};
			ret.content = {};

			if (content.forEach) {
				content.forEach((item, i) => {
					if (item.roles.indexOf(role) !== -1) {
						ret.content[item.name] = menuItem(i + 1, baseTitle + item.title, baseLink + item.link, null, item.base);
					}
				})
			}
			else {
				ret.content = content;
			}

			return ret;
		}

		const _resources = function(role) {
			const content = [
				{
					name: 'files',
					title: 'MY_FILES',
					link: 'my-files',
					roles: ROLES.agency_all,
					base: { hideMobile: false }
				},
				{
					name: 'marketing',
					title: 'MARKETING_IMAGES',
					link: 'marketing-images',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				},
				{
					name: 'video',
					title: 'IMAGES_VIDEO',
					link: 'images-for-video',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				},
				{
					name: 'forms',
					title: 'FORMS',
					link: 'forms',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				},
				{
					name: 'task-templates',
					title: 'TASK_TEMPLATES',
					link: 'task-templates',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				},
				{
					name: 'shared-task-templates',
					title: 'SHARED_TASK_TEMPLATES',
					link: 'shared-task-templates',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				},
				{
					name: 'email-templates',
					title: 'EMAIL_TEMPLATES',
					link: 'email-templates',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				},
				{
					name: 'shared-email-templates',
					title: 'SHARED_EMAIL_TEMPLATES',
					link: 'shared-email-templates',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				},
				{
					name: 'media-manager',
					title: 'MEDIA_MANAGER',
					link: 'media-manager',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				}
			]

			return submenu(content, 'PAGES.RESOURCES.SUB.', 'main.resources.', null, role);
		}

		const _reports = function(role) {
			const content = [
				{
					name: 'lmlbb',
					title: 'LISTINGS_BROKER',
					link: 'last-month-listings',
					roles: ROLES.agency_all
				},
				{
					name: 'lmsbb',
					title: 'SALES_BROKER',
					link: 'last-month-sales',
					roles: ROLES.agency_all
				},
				{
					name: 'sbr',
					title: 'SALES_BY_REGION',
					link: 'sales-by-region',
					roles: ROLES.agency_all
				},
				{
					name: 'ler',
					title: 'LISTING_EXPIRY',
					link: 'listing-expiry-report',
					roles: ROLES.agency_all
				},
				{
					name: 'ytd',
					title: 'YTD_LISTINGS',
					link: 'ytd-listings',
					roles: ROLES.agency_all
				},
				{
					name: 'no-seller',
					title: 'NO_SELLER_INFO',
					link: 'listings-with-no-seller-info',
					roles: ROLES.broker_manager
				},
				{
					name: 'visits-by-broker',
					title: 'VISITS_BY_BROKER',
					link: 'visits-by-broker',
					roles: ROLES.agency_all
				},
				{
					name: 'new-buyers-by-property-type',
					title: 'NEW_BUYERS_BY_PROPERTY_TYPE',
					link: 'new-buyers-by-property-type',
					roles: ROLES.agency_all
				},
				{
					name: 'missing-documents',
					title: 'MISSING_DOCUMENTS',
					link: 'missing-documents',
					roles: ROLES.broker_manager
				},
				{
					name: 'hits',
					title: 'HITS_TRACKER',
					link: 'hits-tracker',
					roles: ROLES.broker_manager
				}
			]

			return submenu(content, 'PAGES.REPORTS.SUB.', 'main.reports.', null, role);
		}

		const _listings = function(role) {
			const content = [
				{
					name: 'inventory',
					title: 'INVENTORY',
					link: '({"submenu": "inventory"})',
					roles: ROLES.agency_all
				},
				{
					name: 'mls',
					title: 'MLS',
					link: '({"submenu": "mls"})',
					roles: ROLES.broker_manager
				},
				{
					name: 'smart',
					title: 'SMART',
					link: '({"submenu": "smart"})',
					roles: ROLES.broker_manager
				},
				{
					name: 'shared',
					title: 'SHARED',
					link: '({"submenu": "shared"})',
					roles: ROLES.broker_manager
				},
				{
					name: 'converted',
					title: 'CONVERTED',
					link: '({"submenu": "converted"})',
					roles: ROLES.broker_manager
				},
				{
					name: 'archive',
					title: 'ARCHIVE',
					link: '({"submenu": "archive"})',
					roles: ROLES.broker_manager
				},
				{
					name: 'drafts',
					title: 'DRAFTS',
					link: '({"submenu": "drafts"})',
					roles: ROLES.broker_manager
				}
			]

			return submenu(content, 'LISTING.MAIN_MENU.', 'main.listings', null, role);
		}

		const _settings = function(role) {

			const content = [
				{
					name: 'account',
					title: 'ACCOUNT',
					link: 'account',
					roles: ROLES.all
				},
				{
					name: 'docusign',
					title: 'DOCUSIGN.TITLE',
					link: 'docusign',
					roles: ROLES.admin
				},
				{
					name: 'quickbooks',
					title: 'QUICKBOOKS.TITLE',
					link: 'quickbooks',
					roles: ROLES.broker
				},
				// {
				//   name: 'sent-emails',
				//   title: 'SENT_EMAILS',
				//   link: 'emails',
				//   roles: ROLES.agency_all
				// },
				{
					name: 'payment',
					title: 'PAYMENT',
					link: 'payment',
					roles: ROLES.agency_no_manager
				},
				{
					name: 'languages',
					title: 'LANGUAGE',
					link: 'language',
					roles: ROLES.all
				},
				{
					name: 'roles',
					title: 'ROLES',
					link: 'roles',
					roles: ROLES.owner
				},
				{
					name: 'managers',
					title: 'MANAGERS',
					link: 'managers',
					roles: ROLES.broker
				},
				{
					name: 'migrate',
					title: 'MIGRATE',
					link: 'migrate',
					roles: ROLES.agency_all,
					base: { hideMobile: true }
				},
				{
					name: 'agency',
					title: 'AGENCY_PROFILE',
					link: 'agency',
					roles: ROLES.owner
				}
			]

			let prefix = 'main.settings.';

			if (role === 'adminProfile') {
				prefix = 'main.admin.settings.';
			}

			return submenu(content, 'PAGES.SETTINGS.SUB.', prefix, null, role);
		}

		function genTasks(base, ret) {

			const PERSONAL = $sce.valueOf($filter('translate')('PERSON.PERSONAL'));
			for (const i in base) {
				if ([ PERSONAL, 'personal', 'search', 'incomplete' ].indexOf(i) < 0) {
					base[i].subsub = {};
					base[i].subsub.content = base[i].content.reduce((obj, item, j) => {
						obj[j] = item;
						return obj;
					}, {});
					ret[i] = base[i];
				}
			}

			return ret;
		}

		const _search = function(tasks, value) {

			function highlight(item) {
				item.title = $filter('highlightSearch')(item.title, value);
			}

			for (const i in tasks) {
				if ([ 'search', 'personal', 'incomplete' ].indexOf(i) < 0) {
					if (!tasks[i].originalContent) {
						tasks[i].originalContent = angular.copy(tasks[i].content);
					}
					tasks[i].content = angular.copy($filter('searchSingleFilter')(tasks[i].originalContent, value, { title: '' }));
					tasks[i].content.forEach(highlight);
				}
			}

			genTasks(angular.copy(tasks), tasks);
		}

		const _tasks = function(role) {

			const _content = function() {
				const def = $q.defer();

				TaskService.createLists().then((res) => {

					const ret = {
						search: menuItem( 0, null, null, null, { search: true, searchValue: '' }),
						personal: menuItem( 1, 'PERSON.PERSONAL', '/tasks#Personal-0')
					};

					genTasks(res, ret);

					ret.incomplete = menuItem( 6, 'TASK.INCOMPLETE_TASKS.TITLE', 'main.tasks.incomplete-tasks', null, {});

					def.resolve(ret);
				})
				.catch((err) => {
					$log.error(err);
					def.reject({});
				})

				return def.promise;
			}

			return submenu(_content(), '', '', { search: '', getContent: _content }, role);
		}

		const _sub_listings = function(role) {
			const content = [
				{
					name: 'detail',
					title: 'LISTING.DETAILS_MENU.LISTING_DETAILS',
					link: 'submenu',
					roles: ROLES.broker_manager
				},
				{
					name: 'tasks',
					title: 'TASK.TASKS',
					link: 'tasks',
					roles: ROLES.broker_manager
				},
				{
					name: 'photos',
					title: 'LISTING.DETAILS_MENU.PHOTOS',
					link: 'photos',
					roles: ROLES.broker_manager
				},
				{
					name: 'marketing',
					title: 'LISTING.DETAILS_MENU.MARKETING',
					link: 'marketing',
					roles: ROLES.broker_manager
				},
				{
					name: 'documents',
					title: 'FILES.DOCUMENTS',
					link: 'documents',
					roles: ROLES.broker_manager
				},
				{
					name: 'resources',
					title: 'FILES.FILES',
					link: 'resources',
					roles: ROLES.broker_manager
				},
				{
					name: 'notes',
					title: 'NOTES_LOG.NOTES',
					link: 'notes',
					roles: ROLES.broker_manager
				},
				{
					name: 'log',
					title: 'NOTES_LOG.ACTION_LOG',
					link: 'log',
					roles: ROLES.broker_manager
				},
				{
					name: 'visits',
					title: 'LISTING.DETAILS_MENU.VISITS',
					link: 'visits',
					roles: ROLES.broker_manager
				},
				{
					name: 'appointments',
					title: 'LISTING.DETAILS_MENU.APPOINTMENTS',
					link: 'appointments',
					roles: ROLES.broker_manager
				},
				{
					name: 'suppliers',
					title: 'ROLES.SUPPLIERS',
					link: 'suppliers',
					roles: ROLES.broker_manager
				}
			]

			return submenu(content, '', 'main.listings.detail.', null, role);
		}

		const _sub_profile = function(role, type) {

			let baseLink = 'main.profile';

			if (type !== 'buyer' && type !== 'seller') {
				baseLink = 'main.suppliers.profile';
			}

			const content = [
				{
					name: 'profile',
					title: 'COMMON.DETAILS',
					link: '',
					roles: ROLES.agency_all.concat(ROLES.seller)
				},
				{
					name: 'files',
					title: 'FILES.FILES',
					link: '.files',
					roles: ROLES.agency_all
				},
				{
					name: 'tasks',
					title: 'TASK.TASKS',
					link: '.tasks',
					roles: ROLES.agency_all
				},
				{
					name: 'visits',
					title: 'LISTING.DETAILS_MENU.VISITS',
					link: '.visits',
					roles: ROLES.agency_all
				},
				{
					name: 'appointments',
					title: 'LISTING.DETAILS_MENU.APPOINTMENTS_LABEL',
					link: '.appointments',
					roles: ROLES.agency_all
				},
				{
					name: 'notes',
					title: 'NOTES_LOG.NOTES',
					link: '.notes',
					roles: ROLES.agency_all
				},
				{
					name: 'log',
					title: 'NOTES_LOG.ACTION_LOG',
					link: '.action-log',
					roles: ROLES.agency_all
				}
			];

			if(type !== 'buyer' && type !== 'supplier') {
				content.splice(3, 1);
			}

			return submenu(content, '', baseLink, null, role);
		}

		const _bookmarks = function() {

			const content = $q.defer();

			if (AccountService.loggedIn()) {
				BookmarkService.getBookmarks()
				.then((res) => {
					content.resolve(res);
				})
				.catch((err) => {
					content.resolve(err);
				})
			}

			return submenu(content.promise, null, null, { newBookmark: true, addButton: true, popover: 'bookmarks' });
		}

		const menuItems = [
			{
				name: 'user',
				roles: ROLES.all.concat(ROLES.seller),
				item: menuItem( 1, null, null, null, { bottom: false, popover: '/js/src/templates/popover/userPop.html', hideMobile: true, addBorder: true })
			},
			{
				name: 'user-top',
				roles: ROLES.all.concat(ROLES.seller),
				item: menuItem( 1, null, null, null, { hideDesktop: true, popover: '/js/src/templates/popover/userPop.html', addBorder: true })
			},
			{
				name: 'search',
				roles: ROLES.agency_all,
				item: menuItem( 1, null, null, ICONS.search, { search: true } )
			},
			{
				name: 'dashboard',
				roles: ROLES.agency_all,
				item: menuItem( 2, 'PAGES.DASHBOARD.TITLE', 'main.dashboard', ICONS.dashboard ),
			},
			{
				name: 'notifications',
				roles: ROLES.agency_all,
				item: menuItem( 3, 'NOTIFICATIONS.NOTIFICATIONS', 'main.notifications', ICONS.notifications, { count: 0 } ),
			},
			{
				name: 'messages',
				roles: ROLES.agency_all,
				item: menuItem( 3, 'MESSAGES.TITLE', 'main.messages', ICONS.messages, { count: 0 } ),
			},
			{
				name: 'sent-emails',
				roles: ROLES.agency_all,
				item: menuItem( 4, 'PAGES.SETTINGS.SUB.SENT_EMAILS', 'main.emails', ICONS.email, { count: 0 })
			},
			{
				name: 'calendar',
				roles: ROLES.agency_all,
				item: menuItem( 5, 'PAGES.CALENDAR.TITLE_MOBILE', 'main.calendar', ICONS.calendar )
			},
			{
				name: 'tasks',
				roles: ROLES.agency_all,
				item: menuItem( 6, 'TASK.TASKS', 'main.tasks', ICONS.tasks, { getSubmenu: _tasks, doSearch: _search })
			},
			{
				name: 'bookmarks',
				roles: ROLES.agency_all,
				item: menuItem( 7, 'BOOKMARKS.BOOKMARKS', null, ICONS.bookmarks, { getSubmenu: _bookmarks })
			},
			{
				name: 'resources',
				roles: ROLES.agency_all,
				item: menuItem( 8, 'PAGES.RESOURCES.TITLE', null, ICONS.resources, { hideMobile: false, getSubmenu: _resources }),
			},
			{
				name: 'reports',
				roles: ROLES.agency_all,
				item: menuItem( 9, 'PAGES.REPORTS.TITLE', null, ICONS.reports, { addBorder: true, hideMobile: true, getSubmenu: _reports })
			},
			{
				name: 'agency',
				roles: ROLES.agency_all,
				item: menuItem( 10, 'ROLES.AGENCY', 'main.agency', ICONS.agency, { break: true }),
			},
			{
				name: 'broker',
				roles: ROLES.agency_all,
				item: menuItem( 11, 'ROLES.BROKER', 'main.broker', ICONS.broker ),
			},
			{
				name: 'buyer',
				roles: ROLES.broker_manager,
				item: menuItem( 12, 'ROLES.BUYER', 'main.buyer', ICONS.buyer ),
			},
			{
				name: 'seller',
				roles: ROLES.broker_manager,
				item: menuItem( 13, 'ROLES.SELLER', 'main.seller', ICONS.seller ),
			},
			{
				name: 'listings',
				roles: ROLES.agency_all,
				item: menuItem( 14, 'LISTING.LISTINGS', null, ICONS.listings, { getSubmenu: _listings }),
			},
			{
				name: 'role-listings',
				roles: ROLES.auditor_like,
				item: menuItem( 1, 'LISTING.LISTINGS', 'main.role-listings.listings', ICONS.listings ),
			},
			{
				name: 'seller-listing',
				roles: ROLES.seller,
				item: menuItem( 1, 'LISTING.LISTING', 'main.owner.listing', ICONS.listings )
			},
			{
				name: 'suppliers',
				roles: ROLES.agency_all.concat(ROLES.seller),
				item: menuItem( 15, 'ROLES.SUPPLIERS', 'main.suppliers', ICONS.supplier ),
			},
			{
				name: 'wordpress',
				roles: ROLES.broker_owner,
				item: menuItem( 16, 'WORDPRESS.WORDPRESS', 'main.wordpress', ICONS.wordpress, { hideMobile: true } ),
			},
			{
				name: 'help',
				roles: ROLES.all,
				item: menuItem( 17, 'HELP.HELP_CENTER', 'main.help', ICONS.help, { limited: true, bottom: true }),
			},
			{
				name: 'settings',
				roles: ROLES.all,
				item: menuItem( 18, 'PAGES.SETTINGS.TITLE', null, ICONS.settings, { limited: true, bottom: true, getSubmenu: _settings }),
			},
		]

		const subsubs = {
			tasks: _tasks,
			listing: _sub_listings,
			profile: _sub_profile
		}

		const _admin_listings = function() {
			const content = [
				{
					name: 'all',
					title: 'All',
					link: '({"type": "all"})',
					roles: ROLES.admin
				},
				{
					name: 'smart',
					title: 'Smart',
					link: '({"type": "smart"})',
					roles: ROLES.admin
				},
				{
					name: 'mls',
					title: 'MLS',
					link: '({"type": "mls"})',
					roles: ROLES.admin
				}
			]

			return submenu(content, '', 'main.admin.listings', null, 'adminProfile');
		}

		const adminMenu = [
			{
				name: 'search',
				item: menuItem( 1, null, null, ICONS.search, { search: true } )
			},
			{
				name: 'dashboard',
				item: menuItem( 2, 'Dashboard', 'main.admin.dashboard', ICONS.dashboard ),
			},
			{
				name: 'agencies',
				item: menuItem( 3, 'Agencies', 'main.admin.agencies', ICONS.agency ),
			},
			{
				name: 'brokers',
				item: menuItem( 4, 'Brokers', 'main.admin.brokers', ICONS.broker ),
			},
			{
				name: 'listings',
				item: menuItem( 5, 'Listings', 'main.admin.listings', ICONS.listings, { addBorder: true, getSubmenu: _admin_listings } ),
			},
			{
				name: 'codes',
				item: menuItem( 6, 'Codes', 'main.admin.codes', ICONS.codes ),
			},
			{
				name: 'help',
				item: menuItem( 7, 'Help', 'main.admin.help', ICONS.help ),
			},
			{
				name: 'settings',
				roles: ROLES.all,
				item: menuItem( 7, 'PAGES.SETTINGS.TITLE', null, ICONS.settings, { limited: true, bottom: true, getSubmenu: _settings }),
			},
			{
				name: 'user',
				item: menuItem( 8, null, null, null, { bottom: true, popover: '/js/src/templates/popover/userPop.html' })
			}
		]

		return {
			getMenu: function(role) {
				return menuItems.reduce((obj, menuItem) => {
					menuItem.roles = menuItem.roles || [];

					if (menuItem.roles.indexOf(role) !== -1) {
						const toAdd = angular.copy(menuItem.item);
						if (toAdd.getSubmenu) {
							toAdd.submenu = toAdd.getSubmenu(role) || {};
						}
						obj[menuItem.name] = toAdd;
					}
					return obj;
				}, {});
			},
			getSubSub: function(role, type, profileType) {
				return subsubs[type](role, profileType);
			},
			getAdminMenu: function() {
				return adminMenu.reduce((obj, menuItem) => {

					const toAdd = angular.copy(menuItem.item);
					if (toAdd.getSubmenu) {
						toAdd.submenu = toAdd.getSubmenu('adminProfile') || {};
					}
					obj[menuItem.name] = toAdd;

					return obj;
				}, {})
			}
		}
	})



})(); // End of function
