/* eslint-disable no-undef */
(function () {

	'use strict';

	// eslint-disable-next-line no-undef
	angular.module('smartbrokr')
	.controller('WordpressController', function (AccountService, AgencyService, AlertService, GlobalVars, ListingService, ModalService, UserService, WordpressService, $filter, $location, $log, $scope, $timeout, $window, check) {

		const self = this;

		// SETUP VARIABLES ======================================================================================================================

		const pagesArray = [ 'sb_pages_sb_page_home_', 'sb_pages_sb_page_agency_', 'sb_pages_sb_page_my_colleagues_', 'sb_pages_sb_page_marketing_', 'sb_pages_sb_page_resources_', 'sb_pages_sb_page_contact_', 'sb_pages_sb_page_brokers_', 'sb_pages_sb_page_agencies_' ];
		self.hasWebsite 		= false;
		self.pendingLogin 		= false;
		self.isSetup 			= true;
		let options 			= {};
		self.general 			= {};
		self.content 			= {};
		self.assets 			= {};
		self.header 			= {};
		self.footer 			= {};
		self.brokers 			= {};
		self.contact            = {};
		self.originalHeaderGallery = {};
		self.offices = [];
		self.brokerSearch       = '';


		const plugins = 'autolink code colorpicker contextmenu link paste searchreplace textcolor textpattern visualblocks visualchars nonbreaking';
		const toolbar = 'bold italic strikethrough | forecolor link | cut copy paste | code';
		self.editorConfig = GlobalVars.createEditorConfig(true, false, plugins, toolbar);

		self.setup = {
			step: 1,
			asset: 'TEMPLATE',
			type: null,
			domain: null,
			agencyType: null,
			office: null,
			config: GlobalVars.createSingleConfig(false, 'id', 'name'),
			typeOptions: [
				{ id: 'SB_AGENCY_OWNER', name: $filter('translate')('ROLES.AGENCY') },
				{ id: 'SB_BROKER', name: $filter('translate')('ROLES.BROKER') }
			],
			assetOptions: [
				{ id: 'PLUGIN', name: $filter('translate')('WORDPRESS.SETUP.PLUGIN') },
				{ id: 'TEMPLATE', name: $filter('translate')('WORDPRESS.SETUP.PLUGIN_TEMPLATE') }
			],
			agencyOptions: [
				{ id: 'franchise', name: $filter('translate')('WORDPRESS.SETUP.FRANCHISE') },
				{ id: 'office', name: $filter('translate')('WORDPRESS.SETUP.OFFICE ') },
			],
		};

		self.dropdowns = {
			singleConfig: GlobalVars.createSingleConfig(false, 'id', 'name'),
			multiConfig: GlobalVars.createMultiConfig(false, 'id', 'name', true),
			officeConfig: GlobalVars.createSingleConfig(false, 'id', 'legalName'),
			languages: [
				{ id: 'EN', name: $filter('translate')('LANGUAGES.EN') },
				{ id: 'FR', name: $filter('translate')('LANGUAGES.FR') },
			],
			theme: [
				{ id: 'light', name: $filter('translate')('WORDPRESS.THEME_LIGHT') },
				{ id: 'dark', name: $filter('translate')('WORDPRESS.THEME_DARK') },
			],
			officeConfig: GlobalVars.createSingleConfig(false, 'id', 'legalName'),
		}

		self.getOffices = function() {
			AgencyService.getOffices(true).then((offices) => {
				self.offices = offices;
			});
		}

		const auto_response_options = [];
		const auto_responses = ['sb_contact_auto_response_subject_', 'sb_contact_auto_response_message_'];
		for(let i = 0; i < self.dropdowns.languages.length; i++) {
			for(let j = 0; j < auto_responses.length; j++) {
				auto_response_options.push(auto_responses[j] + self.dropdowns.languages[i].id);
			}
		}

		switch(AccountService.getRole()) {
		case 'agencyOwnerProfile':
		case 'agencyMemberProfile':
			self.setup.type = 'SB_AGENCY_OWNER'; break;
		default:
			self.setup.type = 'SB_BROKER';
		}

		self.tabs = [
			{
				id: 'assets',
				plugin: false,
				options: [
					'custom_logo', 'secondary_logo', 'site_icon', 'sb_header_gallery'
				]
			},
			{
				id: 'contact',
				plugin: false,
				options: [
					'sb_contact_phone', 'sb_contact_cell', 'sb_contact_email',
					'sb_contact_address_1', 'sb_contact_address_2',
					'sb_contact_city', 'sb_contact_province', 'sb_contact_country',
					'sb_contact_postal_code', 'background_image'
				].concat(auto_response_options)
			},
			{
				id: 'header',
				plugin: false,
				options: [
					'sb_header_phone', 'sb_header_email', 'sb_carousel_text_colour',
					'sb_header_social_media_facebook_show', 'sb_header_social_media_facebook_url',
					'sb_header_social_media_instagram_show', 'sb_header_social_media_instagram_url',
					'sb_header_social_media_linkedin_show', 'sb_header_social_media_linkedin_url',
					'sb_header_social_media_twitter_show', 'sb_header_social_media_twitter_url',
					'sb_header_social_media_youtube_show', 'sb_header_social_media_youtube_url'
				]
			},
			{
				id: 'footer',
				plugin: false,
				options: [
					'sb_footer_email', 'sb_footer_phone', 'sb_footer_name', 'sb_footer_copyright',
					'sb_footer_address_1', 'sb_footer_address_2', 'sb_footer_address_3',
					'sb_footer_social_media_facebook_show', 'sb_footer_social_media_facebook_url',
					'sb_footer_social_media_instagram_show', 'sb_footer_social_media_instagram_url',
					'sb_footer_social_media_linkedin_show', 'sb_footer_social_media_linkedin_url',
					'sb_footer_social_media_twitter_show', 'sb_footer_social_media_twitter_url',
					'sb_footer_social_media_youtube_show', 'sb_footer_social_media_youtube_url'
				]
			},
			{
				id: 'brokers',
				plugin: true,
				options: [],
			},
			{
				id: 'general',
				plugin: true,
				options: [
					'sb_agency_site_type', 'sb_agency_office',
					'sb_page_langs', 'sb_theme_style',
					'sb_color_main', 'sb_color_buttons',
					'sb_hero_en', 'sb_hero_fr',
					'sb_agency_site_type', 'sb_agency_office'
				]
			},
			{
				id: 'content',
				plugin: false,
				options: pagesArray,
				scope: {
					pages: {
						home: {
							label: 'HOME',
							getContent: function(lang) {
								return ((self.content || {}).sb_pages_sb_page_home_ || {})['sb_pages_sb_page_home_' + lang] || {};
							}
						},
						agency: {
							label: 'AGENCY',
							getContent: function(lang) {
								return ((self.content || {}).sb_pages_sb_page_agency_ || {})['sb_pages_sb_page_agency_' + lang] || {};
							}
						},
						my_colleagues: {
							label: 'MY_COLLEAGUES',
							getContent: function(lang) {
								return ((self.content || {}).sb_pages_sb_page_my_colleagues_ || {})['sb_pages_sb_page_my_colleagues_' + lang] || {};
							}
						},
						marketing: {
							label: 'MARKETING',
							getContent: function(lang) {
								return ((self.content || {}).sb_pages_sb_page_marketing_ || {})['sb_pages_sb_page_marketing_' + lang] || {};
							}
						},
						resources: {
							label: 'SUPPLIERS',
							getContent: function(lang) {
								return ((self.content || {}).sb_pages_sb_page_resources_ || {})['sb_pages_sb_page_resources_' + lang] || {};
							}
						},
						contact: {
							label: 'CONTACT',
							getContent: function(lang) {
								return ((self.content || {}).sb_pages_sb_page_contact_ || {})['sb_pages_sb_page_contact_' + lang] || {};
							}
						},
					}
				}
			},
		];

		if(AccountService.getRole() === 'agencyOwnerProfile') {
			self.tabs[self.tabs.length - 1].options.splice(2, 1);
			delete self.tabs[self.tabs.length - 1 ].scope.pages.my_colleagues;

			const agenciesPage = {
				label: 'AGENCIES',
				getContent: function(lang) {
					return ((self.content || {}).sb_pages_sb_page_agencies_ || {})['sb_pages_sb_page_agencies_' + lang] || {};
				}
			};
			const brokersPage = {
				label: 'BROKERS',
				getContent: function(lang) {
					return ((self.content || {}).sb_pages_sb_page_brokers_ || {})['sb_pages_sb_page_brokers_' + lang] || {};
				}
			};

			self.tabs[self.tabs.length - 1].scope.pages.agencies = agenciesPage;
			self.tabs[self.tabs.length - 1].scope.pages.brokers = brokersPage;
		}



		getActiveTab();
		load();

		// FUNCTIONS ============================================================================================================================
		self.advanceSetup = function() {
			self.setup.step += 1;
		}

		self.addLang = function(code, lang) {
			if (!code) return;

			const pageCode = 'sb_pages_sb_page_' + code + '_';
			const optionCode = pageCode + lang;

			if (!(self.content[pageCode] || {})[optionCode]) {
				self.content[pageCode] = self.content[pageCode] || {};
				self.content[pageCode][optionCode] = {};
			}

			const post = self.content[pageCode][optionCode];

			if (!!post.post_content) return;

			post.hasContent = true;
			post.post_content = '';
			post.lang = lang.toLowerCase();
			post.post_title = code + '_' + lang;
			post.post_id = '';
			self.save();
		}

		self.getLanguageLabel = function(lang) {
			return { lang: '' + $filter('translate')('LANGUAGES.' + lang) };
		}

		self.openContentModal = function(page, pageLabel, lang, latest_revision) {
			page = page || {};
			if (!page.post_content)
				return;

			const m = ModalService.openModal('wpContentEdit', {
				html: () => page.post_content || '',
				title: () => page.post_title || '',
				pageLabel: () => pageLabel,
				lang: () => lang,
				latest_revision: () => latest_revision,
				offices: () => self.offices,
				wp: () => self.wordpress
			}, 'WPContentModalController', 'modalController', null, 'wp-content-modal');

			m.result.then((res) => {
				if(res.restore) {
					self.restoreDefault(pageLabel, lang);
				} else if(res.rollback) {
					self.rollbackLatestChange(pageLabel, lang);
				} else {
					page.post_title 	= res.title;
					page.post_content 	= res.content;
					self.save();
				}
			});
		}

		self.doSetup = function() {
			AlertService.loading();

			const wp = {
				url: self.setup.domain,
				role: self.setup.type,
				asset: self.setup.asset,
				agencyType: self.setup.agencyType,
				office: self.setup.office
			}

			WordpressService.createWordpress(wp).then((res) => {
				self.pendingLogin = true;
				self.setup.step = 0;
				self.hasWebsite = true;
				self.isSetup = true;
				self.wordpress.id = res.id;
				ModalService.prompt('WORDPRESS.SETUP.SUCCESS', 'COMMON.SUCCESS', null, 'COMMON.CLOSE', null, false, 'modal-title');
			})
			.catch($log.error)
			.finally(AlertService.doneLoading);
		}

		self.deleteWordpress = function() {
			AlertService.loading();
			WordpressService.deleteWordpress(self.wordpress.id).then(() => {
				self.setup.step = 1;
				load();
			})
			.catch($log.error)
			.finally(AlertService.doneLoading);
		}

		self.save = function() {
			AlertService.loading();
			const _ = $window._;
			$scope.loading = true;

			const isDiff = function(tab, key, subKey = null) {
				if(subKey) return !_.isEqual((check.original[tab] || {})[key][key + subKey], (check.clone[tab] || {})[key][key + subKey]);
				return !_.isEqual((check.original[tab] || {})[key], (check.clone[tab] || {})[key]);
			}

			const diffs = self.tabs.reduce((ret, tab) => {
				if (tab.id === 'assets') return ret;

				if (tab.id === 'brokers') {
					ret.brokers = self.brokers;
					return ret;
				}

				const langEN = 'EN';
				const langFR = 'FR';

				return tab.options.reduce((_ret, option) => {
					if (isDiff(tab.id, option)) {
						if(pagesArray.includes(option)) {
							const englishDiff = isDiff(tab.id, option, langEN);
							const frenchDiff = isDiff(tab.id, option, langFR);
							if((englishDiff && !frenchDiff) || (!englishDiff && frenchDiff)) {
								const optionContent = {};
								if(englishDiff) {
									optionContent[option + langEN] = self[tab.id][option][option + langEN];
								} else if(frenchDiff) {
									optionContent[option + langFR] = self[tab.id][option][option + langFR];
								}
								_ret[option] = optionContent;
							} else {
								_ret[option] = self[tab.id][option];
							}
						} else {
							_ret[option] = self[tab.id][option];
						}
					}
					return _ret;
				}, ret);
			}, {});

			WordpressService.sendUpdate(self.wordpress.id, 'update_options', diffs).then(() => {
				AlertService.saved();
			})
			.finally(getOptions);
		}

		self.restoreDefault = function(pageLabel, lang) {
			AlertService.loading();
			WordpressService.restoreDefault(self.wordpress.id, pageLabel, lang).then(() => {
				AlertService.saved();
			})
			.finally(getOptions);
		}

		self.deleteHeaderGallery = function(index) {
			AlertService.loading();
			const gallery = angular.copy(self.assets.sb_header_gallery || []);
			gallery.splice(index, 1);

			WordpressService.sendUpdate(self.wordpress.id, 'update_options', { sb_header_gallery: gallery }).then(() => {
				AlertService.saved();
			})
			.finally(getOptions);
		}

		self.updateCaptions = function() {
			AlertService.loading();
			const gallery = angular.copy(self.assets.sb_header_gallery || []);
			WordpressService.sendUpdate(self.wordpress.id, 'update_options', { sb_header_gallery: gallery, sb_carousel_text_colour: self.assets.sb_carousel_text_colour }).then(() => {
				AlertService.saved();
			})
			.finally(getOptions);
		}

		self.deselectTab = function(ev, tabId) {
			if ((self.wpForm || {}).$invalid) {
				const errors = self.wpForm.$error || {};

				for (const i in errors) {
					const hasError = errors[i] || [];

					const inTab = hasError.find((curr) => {
						return ((curr || {}).$name || '').includes(tabId);
					});

					if (inTab) {
						ev.preventDefault();
						self.showWarnings = true;
						break;
					}
				}
			}
		}

		self.selectTab = function(tabId) {
			self.showWarnings = false;
			$location.hash(tabId);
		}

		self.validate = function(form) {

			if (!form) return false;

			const element = form.$$element;
			const invalid = element.find('.ng-invalid').first();

			if (invalid[0]) {
				try {
					const parent 	= invalid.parents('.form-group')[0];
					const panel 	= invalid.parents('.panel-body');

					if ((panel || [])[0]) {
						const scope = panel.scope();

						if (scope) {
							scope.isOpen = true;
							$timeout(() => {
								panel[0].scrollIntoView();
							})
						}
					}
					parent.scrollIntoView();
					invalid.focus();
				}
				catch(err) {
					$log.error(err);
				}

				return false;
			}

			return true;
		}

		self.uploadImage = function(type, broker, subtype = null) {

			const imageInfo = {
				logo: {
					init_w: 300,
					init_h: 90,
					result_w: 300,
					result_h: 90
				},
				secondary: {
					init_w: 300,
					init_h: 90,
					result_w: 300,
					result_h: 90
				},
				background: {
					init_w: 1679,
					init_h: 1000,
					result_w: 1679,
					result_h: 1000
				},
				favicon: {
					init_w: 150,
					init_h: 150,
					result_w: 150,
					result_h: 150
				},
				header_gallery: {
					init_w: 1920,
					init_h: 600,
					result_w: 1920,
					result_h: 600
				},
				broker_profile: {
					init_w: 300,
					init_h: 348,
					result_w: 300,
					result_h: 348
				}
			}

			const dimensions = imageInfo[type];
			const options = {
				subtype: subtype
			};

			if(subtype == 'carousel') {
				options.bulkImageUpload = true;
			}

			const m = ModalService.openModal('wpImageUpload', {
				dimensions: () => dimensions,
				type: () => type,
				options: () => options
			}, 'WPImageUploadController', 'uploadController', null, 'wp-upload-modal wp-' + type);

			m.result.then((res) => {
				res = res || {};

				if (type !== 'broker_profile') {
					updateImage(type, res);
				}
				else {
					broker.photoUrl = res.url;
				}
			})
			.catch(() => {});
		}

		self.rollbackLatestChange = function(pageLabel, lang) {
			AlertService.loading();
			WordpressService.rollbackLatestChange(self.wordpress.id, pageLabel, lang).then(() => {
				AlertService.saved();
			})
			.finally(getOptions);
		}

		self.doSplicing = function(photos, firstIndex, secondIndex) {
			const spliced = photos.splice(firstIndex, 1);
			photos.splice(secondIndex, 0, spliced[0]);
			return photos;
		}

		self.handleDrop = function (object, secondPhoto) {
			const firstIndex = object.secondIndex;
			const secondIndex = secondPhoto.otherIndex;
			self.assets.sb_header_gallery = self.doSplicing(self.assets.sb_header_gallery, firstIndex, secondIndex);
			self.originalHeaderGallery = self.doSplicing(self.originalHeaderGallery, firstIndex, secondIndex);
			WordpressService.sendUpdate(self.wordpress.id, 'update_options', { sb_header_gallery: self.originalHeaderGallery, sb_carousel_text_colour: self.assets.sb_carousel_text_colour }).then(() => {}).finally(() => {
				AlertService.saved();
			});
		}

		self.handleSearch = function() {
			self.searchedBrokers = $filter('searchSingleFilter')(self.brokers, self.brokerSearch, ['fullName']);
		}

		function getActiveTab() {
			const hash = $location.hash();

			if (!hash) {
				self.activeTab = 4;
				return;
			}

			const i = self.tabs.findIndex((curr) => {
				return curr.id === hash;
			})

			self.activeTab = i >= 0 ? i : 4;
		}

		function getOptions() {
			const id = (self.wordpress || {}).id;
			if (!id) return;

			AlertService.loading();
			$scope.loading = true;

			WordpressService.getOptions(id).then((res) => {
				options = (res || {}).options || {};
				self.brokers = res.brokers || {};
				self.handleSearch();
				check.clone = {}
				self.tabs.forEach((tab) => {
					const opts = tab.options || [];
					const id = tab.id;
					opts.forEach((opt) => {
						if (id === 'assets') {
							options[opt] = options[opt] || '';
						}
						self[id][opt] = options[opt];
					})

					check.clone[id] = self[id];
				})

				for (const i in self.content) {
					for (const j in self.content[i]) {
						const post = self.content[i][j];
						post.hasContent = !!post.post_content;
						post.post_title = post.post_title || (post.post_shortcode.replace('sb_pages_sb_', '') + post.lang);
					}
				}
				self.originalHeaderGallery = JSON.parse(JSON.stringify(self.assets.sb_header_gallery));
			})
			.catch((err) => {
				$log.error(err);
				$scope.hasError = true;
			})
			.finally(() => {
				$scope.loading = false;
				check.original = angular.copy(check.clone);
				AlertService.doneLoading();
			})
		}

		function load() {
			$scope.loading = true;
			AlertService.loading();
			WordpressService.getWordpress().then((wp) => {

				wp = wp || {};

				if ((wp.url || '').endsWith('/')) {
					wp.url = wp.url.slice(0, wp.url.length -1);
				}

				self.wordpress 		= wp;
				self.hasWebsite 	= !!wp.id;
				self.pendingLogin 	= !!wp.pendingLogin;


				if (self.pendingLogin) {
					self.setup.step = 0;
				}

				if (wp.role === 'SB_BROKER') {
					const i = self.tabs.findIndex((tab) => { return tab.id === 'brokers' });
					self.tabs[i].disabled = true;
				}

				self.isSetup = !self.hasWebsite || self.pendingLogin;
				self.getOffices();

				if (!self.isSetup) {
					return getOptions();
				}

				$scope.loading = false;
				AlertService.doneLoading();
			})
			.catch((err) => {
				$log.error(err);
				$scope.hasError = true;
				$scope.loading = false;
				AlertService.doneLoading();
			})
		}

		function updateImage(type, data) {
			AlertService.loading();

			let uploadType = 'upload';

			switch(type) {
			case 'logo':
				uploadType = 'update_blog_logo'; break;
			case 'secondary':
				uploadType = 'update_secondary_logo'; break;
			case 'background':
				uploadType = 'update_background_image'; break;
			case 'favicon':
				uploadType = 'update_blog_icon'; break;
			case 'header_gallery':
				uploadType = 'update_header_gallery'; break;
			}

			if(uploadType === 'update_header_gallery' && Array.isArray(data)) {
				data = { header_gallery_images: data };
			}


			WordpressService.sendUpdate(self.wordpress.id, uploadType, data).then(() => {
				if(data.header_gallery_images) {
					// UserService.deleteFiles(data.header_gallery_images).then(() => {
					load();
					// });
				} else {
					UserService.deleteFile(data).then(() => {
						load();
					});
				}
			})
			.catch((err) => {
				$log.error(err);
				AlertService.doneLoading();
			})
		}
	})

	.filter('wordpressTabFilter', () => {
		return function (tabs, asset) {
			tabs = tabs || [];

			return tabs.reduce((arr, tab) => {
				if (asset !== 'PLUGIN' || tab.plugin) {
					tab.title = 'WORDPRESS.TABS.' + tab.id.toUpperCase();
					tab.template = '\'/js/src/wordpress/tabs/' + tab.id + '.html\'';
					tab.description = 'WORDPRESS.' + tab.id.toUpperCase() + '.DESCRIPTION';
					arr.push(tab);
				}
				return arr;
			}, []);
		};
	})

})(); // End of function()
