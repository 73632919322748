(function () {

	'use strict';

	angular.module('smartbrokr')
	.controller('SentEmailsController', function (AccountService, AdminService, AgencyService, AlertService, BrokerService, BuyerService, ModalService, SellerService, SupplierService, UserService, $filter, $log, $rootScope, $sce, $stateParams) {

		const self = this;

		// VARIABLES ============================================================================================================================

		self.emails     = [];               // Sent emails data
		self.sortInfo   = null;             // Sort information (needed for list-view directive)
		self.navParams  = null;             // Used to decide what happens when clicking on an item in the list
		self.columns    = null;             // Columns for list-view directive
		self.filters    = null;             // Filters for list-view directive
		self.isBuyer    = false;

		// SETUP VARIABLES ======================================================================================================================

		const services = {
			agency: AgencyService,
			broker: BrokerService,
			buyer: BuyerService,
			seller: SellerService,
			supplier: SupplierService
		}

		self.sortInfo = {
			page: 'sent-emails',
			sub: [ 'toUser' ]
		}

		self.filterCats = {
			email: true,
			recipient: true,
			fullName: true,
			subject: true,
			created: {
				date: true,
				dateFormat: 'dateFormatLong'
			}
		}

		self.navParams = {
			onClick: resendEmail
		}

		self.inject = {
			name: 'sentEmailsController',
			inject: self
		}

		self.columns = [
			{
				name: 'EMAIL.TO',
				filter: 'to',
				field: function(email) {
					if (email.toUser) {
						return email.toUser.email;
					}
					else {
						return email.recipient;
					}
				}
			},
			{
				name: 'PERSON.NAME',
				filter: 'fullName',
				field: function(email) {
					if (email.toUser) {
						return email.toUser.fullName;
					}
					else {
						return email.recipient;
					}
				}
			},
			{
				name: 'EMAIL.SUBJECT',
				filter: 'subject',
				field: function(email) {
					return email.subject;
				}
			},
			{
				name: 'EMAIL.CONTENT',
				filter: 'html',
				class: 'preview-email',
				field: function(email) {
					return $filter('ignoreTags')(email.html || '');
				}
			},
			{
				name: 'EMAIL.ATTACHMENTS',
				filter: 'attachments',
				noSearch: true,
				field: function(email) {
					return (email.attachments || []).length || 0;
				}
			},
			{
				name: 'DATES.DATE',
				filter: 'created',
				field: function(email) {
					if (email.created) {
						return $filter('dateFormatLong')(email.created);
					}
					else {
						return 'N/A';
					}

				}
			}
		]

		self.mobileColumns = [].concat(self.columns);
		self.mobileColumns.push({
			name: '',
			isHtml: true,
			field: function(email) {
				return $sce.trustAsHtml('<a tabindex="0" class="btn btn-green medium add-margin-top' + (!!email.html ? '' : ' like-disabled') + '" ' +
                    'uib-popover-template="smartbrokrController.popover(\'btnEmailNoContent\')" ' +
                    'popover-placement="auto top" ' +
                    'popover-trigger="\'focus\'" ' +
                    'popover-class="popover-warning"' +
                    'popover-enable="!entry.html"' +
                    'ng-click="sentEmailsController.resendEmail(entry)">' + $filter('translate')('COMMON.EDIT') + '</a>');
			}
		})

		self.filters = {
			to: { weight: 0, value: '', desc: 1 },
			fullName: { weight: 0, value: '', desc: 1 },
			subject: { weight: 0, value: '', desc: 1 },
			html: { weight: 0, value: '', desc: 1 },
			created: { weight: 0, value: '', desc: 1, date: true, dateFilter: 'dateFormatLong', top: true },
			attachments: { weight: 0, value: '', desc: 1, noSearch: true, sortFunction: item => (item.attachments || []).length || 0 },
		};

		self.resendEmail = resendEmail;

		getSentEmails();

		// FUNCTIONS ============================================================================================================================

		function resendEmail(email) {
			email = email || {};

			if (!AccountService.limitedAccess() && !!email.html) {
				const resolve = {
					params: function() {
						return { previousEmail: email }
					}
				}

				ModalService.openModalWithFunction('sendEmail', resolve, 'SendEmailController', 'sendEmailController', getSentEmails, null, 'email-modal');
			}
			else if (!email.html && ($rootScope.screenSize === 'lg' || $rootScope.screenSize === 'md')) {
				ModalService.prompt('EMAIL.NO_CONTENT_EDIT', null, 'Ok');
			}
		}

		function sentEmailsSuccess(res) {
			for (let i = 0; i < res.length; i++) {
				if(res[i].toUser){
					res[i].to = res[i].toUser.email;
					res[i].fullName = res[i].toUser.fullName;
				}
				else {
					res[i].to = res[i].recipient;
					res[i].fullName = 'Unknown';
				}

				if (!res[i].html && !!(res[i].params || {}).body) {
					res[i].html = res[i].params.body;
				}
			}
			self.emails = res;

		}

		function getSentEmails() {
			AlertService.loading();
			if($stateParams.menu && $stateParams.menu === 'agency') {
				AgencyService
				.getSentEmails($stateParams.id, $stateParams.role, $stateParams.fk)
				.then(sentEmailsSuccess)
				.catch($log.error)
				.finally(AlertService.doneLoading);
			} else {
				if($stateParams.id != null) {
					self.isBuyer = true;
				}

				if(self.isBuyer) {
					delete self.filters.to;
					self.columns.splice(0, 1);
					let service = services[$stateParams.role];
					if($stateParams.role === 'owner') {
						service = AdminService;
						service.getSentEmails($stateParams.id, $stateParams.role)
						.then(sentEmailsSuccess)
						.catch($log.error)
						.finally(AlertService.doneLoading);
					} else {
						service.getSentEmails($stateParams.id)
						.then(sentEmailsSuccess)
						.catch($log.error)
						.finally(AlertService.doneLoading);
					}

				} else {
					UserService.getSentEmail()
					.then(sentEmailsSuccess)
					.catch($log.error)
					.finally(AlertService.doneLoading);
				}
			}


		}
	});
})();
